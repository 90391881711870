/**
 * Styling of the page/object header when the page is embedded
 */

body.embedded > .content {
  > h1 {
    z-index: 9999999999;
    position: fixed;
    padding: 0;
    padding-right: 1.5rem;
    margin: 0;
    height: $topbar-height;
    min-height: 0;
    top: 0;
    right: 0;
    overflow: hidden;
    max-width: calc(100% - 14rem);

    .organization {
      display: none;
    }

    .actions {
      display: none;
    }

    .badges {
      display: none;
    }

    nav {
      display: none;
    }

    > svg,
    > img {
      padding: 0;
      margin: 0;
      width: 1.5rem;
      height: 1.5rem;
      left: 0;
      top: 0;
    }

    .type {
      left: 0;
      padding: 0;
      margin: 0;
      padding-left: 2rem;
      bottom: 0;
      height: 0.7rem;
      z-index: auto;
      overflow: visible;
      font: $header-type-font-embed;
      max-width: initial;

      ul.subtypes {
        bottom: 0.2rem;
        height: 1rem;
        max-width: initial;
      }

      a {
        z-index: auto;
        font: $header-type-font-embed;
        bottom: 0.2rem;
      }
    }

    .name {
      left: 0;
      padding: 0;
      margin: 0;
      font: $header-font-embed;
      z-index: auto;
      padding-left: 2rem;
      bottom: 0.2rem;
      position: relative;

      a {
        z-index: auto;
        font: $header-font-embed;
      }
    }

    + .bar {
      display: none;
    }
  }
}
