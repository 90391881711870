/**
 * Styling for forms and inputs throughout the site.
 */

a.edit,
button.edit {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  display: block;
  overflow: hidden;
  cursor: pointer;
  padding: 0;
  margin: 0;
  text-indent: -9999px;
  border: none;
  background-color: #96cfdb;
  position: relative;

  svg {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }

  // TODO: put this in the theme.
  &:hover {
    background-color: #2da0b7;
  }
}

form {
  // For form elements that are intended to contain inline elements.
  // For instance, a form for a dropdown list button.
  &.inline {
    display: inline-block;
  }

  .card.submitting {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    box-sizing: border-box;

    .progress-bar {
      margin-top: 0.25rem;
      height: 0.25rem;
      border-radius: 0.10rem;
      max-width: 80%;
      width: 10rem;
      background: white;
      border: 0.0625rem solid #888;
      position: relative;
      overflow: hidden;
      margin-left: auto;
      margin-right: auto;

      .progress-indicator {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 0%;
        background: red;
      }
    }

    & > * {
      width: 100%;
    }
  }

  button.delete {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    display: block;
    overflow: hidden;
    cursor: pointer;
    padding: 0;
    margin: 0;
    text-indent: -9999px;
    border: none;
    background-color: #ffc4c4;
    position: relative;

    svg {
      position: absolute;
      left: 10%;
      top: 10%;
      width: 80%;
      height: 80%;
      fill: white;
    }

    // TODO: put this in the theme.
    &:hover {
      background-color: #ffa1a1;
    }
  }

  // For form elements meant to be displayed as a group.
  &.modal {
    label {
      &:not(:first-child) {
        margin-top: 1rem;
      }

      &:not(.checkbox) {
        font: $form-label-font;
        margin-bottom: 0.5rem;
        display: block;
        color: hsl(190, 30%, 35%);

        &:first-child {
          padding-top: 0.5rem;
        }
      }
    }

    input[type=checkbox]:first-child + label {
      margin-top: 0;
    }

    input.button,
    a.button {
      &:last-child {
        margin-bottom: 1rem;
      }
    }

    select + h2,
    .selector + h2,
    a.button + h2,
    input.button + h2,
    input[type=password] + h2,
    input[type=file] + h2,
    .tagify.tags + h2,
    input[type=text]:not(.auto-complete) + h2 {
      margin-top: 1rem;
    }

    // Typical input fields
    select,
    .selector,
    input[type=password],
    input[type=file],
    .tagify.tags,
    input.auto-complete,
    input[type=text] {
      text-align: left;
      width: 32rem;
      max-width: calc(100% - 2rem);
      margin: 0 auto;
      font: $form-input-font;
      box-sizing: border-box;
      border: 1px solid #aaa;

      &:not(.auto-complete) {
        padding: 0;

        &:not(.tagify) {
          height: 2rem;
          padding: 0.25rem;
        }
      }

      // Shorter fields
      &.short {
        width: 16rem;
      }

      &:last-child {
        margin-bottom: 1rem;
      }

      &[readonly],
      &[disabled] {
        opacity: 0.5;
      }

      + a {
        display: block;
        margin-top: 0.25rem;
        font: $paragraph-font;
        font-size: 0.8rem;

        &:last-child {
          margin-bottom: 1rem;
        }
      }
    }

    // Text areas
    textarea {
      width: 32rem;
      height: 28rem;
      resize: none;
      max-width: calc(100% - 2rem);
      margin: 0 auto;

      &:last-child {
        margin-bottom: 1rem;
      }
    }
  }

  .card.errors {
    margin-top: 0.125rem;
  }
}

form:not(.inline) {
  input.button.next,
  input[type=submit] {
    min-width: 16rem;

    &.short {
      min-width: 6rem;
    }

    &:last-child {
      margin-bottom: 0.5rem;
    }
  }

  .submit {
    input[type=button]:last-child,
    input[type=submit]:last-child {
      margin-bottom: 0.5rem;
    }
  }
}

// A table for entering new / editing existing metadata fields
form .editing-table {
  display: table;
  width: 100%;
  outline: 0.0625rem solid #ddd;

  .editing-table-field {
    display: table-row;

    label:not(.checkbox):first-child {
      display: table-cell;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      text-align: right;
      padding-left: 2rem;
      border-right: 0.0625rem solid #aaa;
      padding-right: 1rem;
      border-bottom: 0.0625rem solid #ddd;
      vertical-align: top;
      background-color: white;
      min-width: 40%;
      position: relative;
      margin: 0;
      font: $form-label-font;
      color: hsl(190, 30%, 35%);
    }

    span.icon {
      display: table-cell;
      width: 1.5rem;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      text-align: right;
      padding-left: 1rem;
      border-right: 0.0625rem solid #aaa;
      padding-right: 1rem;
      border-bottom: 0.0625rem solid #ddd;
      vertical-align: top;
      background-color: white;
      position: relative;

      svg {
        width: 1.5rem;
        height: 1.5rem;

        fill: hsl(190, 30%, 35%);
      }
    }

    .editing-table-cell,
    select,
    button.selector,
    textarea:not([hidden]),
    input:not([hidden]):not([type="hidden"]) {
      display: table-cell;
      box-sizing: border-box;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      width: 100%;
      height: 2rem;
      max-width: none;
      margin-left: 1rem;
      margin-right: 1rem;
      width: calc(100% - 2rem);
      background: white;
      border: 0.0625rem solid #ddd;
      padding: 0 0.5rem;
    }

    tags.tagify.tags {
      box-sizing: border-box;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      width: 100%;
      min-height: 2rem;
      max-width: none;
      margin-left: 1rem;
      margin-right: 1rem;
      width: calc(100% - 2rem);

      tag, span.tagify__input {
        height: 1.25rem;
      }

      span.tagify__input {
        margin-top: 0.125rem;
        height: 1.125rem;
      }
    }

    & > :first-child {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: #d5131300;
        box-shadow: 0 0 3px 0px;
        clip-path: inset(0px -15px 0px 0px);
        height: calc(100% + 1px);
        width: 0.0625rem;
      }
    }

    &:last-child {
      span.icon,
      label:not(.checkbox):first-child {
        border-bottom: none;
      }
    }

    &.error {
      background: white;

      label:not(.checkbox):first-child {
        color: red;
      }

      .editing-table-cell,
      select,
      button.selector,
      textarea:not([hidden]),
      input:not([hidden]):not([type="hidden"]) {
        box-shadow: inset 0 0 0.125rem 0.0625rem red;
      }
    }
  }
}

.jscolor-picker-wrap {
  .jscolor-picker-shadow {
    box-shadow: 0 0 0.1875rem 0 #111 !important;
    border-radius: 0.3rem !important;
    border-top-left-radius: 0 !important;
  }

  .jscolor-picker-border {
    border-radius: 0.3rem !important;
    border-top-left-radius: 0 !important;
    border: none !important;
  }
}
