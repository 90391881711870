/**
 * Object Installation section.
 */

.metadata-installation {
  .metadata-installation-empty {
    td {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    &:not(:first-child) {
      display: none;
    }
  }

  .metadata-installation-links-container {
    margin: 0.5rem auto;
    overflow-x: auto;
  }

  table.metadata-installation-links {
    margin: 0.5rem auto;
    width: auto;

    thead {
      tr {
        th {
          padding: 0.5rem 1rem;
          border-bottom: 0.0625rem solid #ddd;
          font: bold 0.9rem 'Lato', sans-serif;

          &.empty {
            padding: 0;
          }
        }
      }
    }

    tbody {
      margin-bottom: 1rem;

      tr.metadata-installation-link {
        td {
          border: none;
          padding-top: 0.125rem;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          vertical-align: middle;

          &.metadata-installation-link-summary {
            font-style: italic;
          }

          &.metadata-installation-link-delete {
            padding-left: 0;
            padding-right: 0;

            button {
              margin-left: 0.5rem;
            }
          }
        }

        &:first-child {
          td {
            padding-top: 0.5rem;
          }
        }
      }
    }

    &:not(:last-child) {
      border-bottom: 0.0625rem solid #ddd;

      tbody {
        tr:nth-last-child(2) {
          td {
            padding-bottom: 0.5rem;
          }
        }
      }
    }
  }

  // The "add" input/output button
  a.add {
    display: inline-block;
    position: relative;
    padding: 0;
    height: 2.5rem;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 0.4rem;
    text-indent: -9999px;
    margin-left: 1rem;
    margin-right: 1rem;
    width: calc(100% - 2rem);
    max-width: 30rem;
    margin-bottom: 1rem;

    svg.icon,
    img.icon {
      width: 2rem;
      height: 2rem;
      position: absolute;
      top: calc(50% - 1rem);
      left: calc(50% - 0.75rem);
    }
  }
}
