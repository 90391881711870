/**
 * Styling for the left-hand directory listing.
 */

// This styles the droparea pop-up guide.
.file-upload-droparea-message {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;

  .overlay.help {
    opacity: 0.85;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    z-index: 0;

    &::before,
    &::after {
      display: none;
    }
  }

  .help-text {
    font: $help-font;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    margin: 1rem;
    border: 0.25rem solid magenta;
    border-style: double;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      flex: 0 0 auto;
    }
  }
}

// This is revealed only when something is dragged over the app.
body.dragover .file-upload-droparea-message {
  display: block;
}

// The file listing panel itself.
.file-listing-container {
  z-index: 9;
  position: relative;

  h2 {
    position: absolute;
    z-index: 99999999;
    width: 100%;
    text-align: left;

    // Cancel out the mismatched help margins, ugh
    & + .help + ul.tabs.tab-bar {
      margin-top: 0;
    }
  }

  .new-actions-bar {
    padding: 0;
    width: 100%;
    height: 2rem;

    .new-actions {
      display: flex;
      flex-direction: row;
      padding: 0;

      > button.new-action {
        margin: 0;
        min-width: 33%;
        flex: 1 0 auto;
        height: 2rem;
        text-align: center;
        overflow: hidden;
        display: flex;
        justify-content: center;

        > svg {
          width: 1.5rem;
        }
      }
    }
  }

  .error {
    padding: 0.25rem;
    position: relative;

    svg,
    img {
      width: 1.25rem;
      height: 1.25rem;
      position: absolute;
      left: 0.5rem;
      top: calc(50% - 0.625rem);
    }

    svg + p,
    img + p {
      padding-left: 2rem;
    }
  }

  .upload-bar {
    padding: 0;
    overflow: hidden;

    form {
      overflow: hidden;
      display: flex;
      flex-direction: row;

      input[name=fileToUpload] {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        flex: 0 1 auto;
        margin: 0;
        min-width: 0;
        width: auto;
        box-sizing: border-box;
      }

      input[name=submit] {
        padding-left: 0;
        padding-right: 0;
        flex: 1 1;
        margin: 0;
        min-width: 0;
        width: auto;
        box-sizing: border-box;
      }
    }
  }

  .file-listing-table {
    overflow: auto;

    table.file-listing {
      width: 100%;
      max-width: 100%;
      overflow: visible;
      text-align: left;
      box-sizing: border-box;

      td {
        position: relative;
        font: $directory-normal-font;
        padding: 0.35rem;
        border: none;
        text-align: left;

        a {
          text-decoration: none;
          font: $directory-normal-font;
          color: #103942;

          span {
            vertical-align: middle;
          }
        }

        .dropdown-menu-options {
          margin-top: 2.1rem;
        }
      }

      tr.group {
        font: $directory-group-font;
      }

      td.checkbox {
        width: 1%;
      }

      td.type {
        display: none;
      }

      td.name {
        .revision {
          margin-left: 0.125rem;
          color: #aaa;
          font: $directory-revision-font;

          &::before {
            content: '@';
            margin-right: 0.125rem;
          }
        }
      }

      td.truncate {
        > *:first-child {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 0.35rem;
          overflow: hidden;
          box-sizing: border-box;
          width: 100%;
        }

        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0;
      }

      tr {
        border-bottom: 1px solid #f3f3f3;

        &:last-child {
          border-bottom: 1px solid #ddd;
        }

        td.icon {
          width: 1.25rem;
          height: 1.25rem;

          svg,
          img {
            width: 1.25rem;
            height: 1.25rem;
            vertical-align: top;
          }
        }

        td.size {
          width: 1px;
          text-align: right;
          font: $directory-size-font;

          &.units {
            text-align: left;
            padding-left: 0;
          }
        }

        td.actions {
          width: 1.75rem;
          box-sizing: border-box;
          border-bottom: 1px solid #ddd;

          .dropdown-menu {
            border-left: 1px solid #ddd;
            box-sizing: border-box;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            width: 100%;

            > a {
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              width: 100%;
              padding: 0.35rem;
              text-align: center;
              box-sizing: border-box;
              background: #eee3f8 linear-gradient(to top, hsla(0, 0%, 95%, 1), #fff);
              font-weight: bold;
              color: #6aa3ae;

              &:hover {
                background: #fff;
                color: #4a838e;
              }
            }
          }
        }

        td.name:hover {
          background-color: #f8f8f8;
        }
      }

      tr.main {
        border-bottom-color: #ddd;

        td {
          position: relative;

          &::after {
            content: '';
            position: absolute;
            border-bottom: 1px solid #ddd;
            bottom: -3px;
            left: 0;
            right: 0;
            background: blue;
            z-index: 99;
          }
        }

        td:not(.actions) {
          background-color: hsla(298, 100%, 96%, 1);
        }

        td:not(.actions).name a {
          font-weight: bold;
        }

        + tr {
          td.name input {
            top: 2px;
          }
        }
      }

      tr.active {
        td:not(.actions) {
          background-color: #d1f0f6;
        }

        td:hover {
          background-color: #d1f0f6;
        }
      }

      tr.hover:not(.active) {
        td:not(.actions) {
          background-color: #f8f8f8;
        }
      }
    }
  }
}
