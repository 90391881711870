/**
 * Details page image gallery styling.
 */

.top-details {
  .card.gallery {
    flex: 0 1 auto;
    width: 50%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;

    @media #{$media-tablet} {
      width: 100%;
    }

    &:first-child {
      width: 100%;
    }

    > .flexbox {
      display: flex;
      flex-direction: column;
      min-height: 100%;
      max-height: 100%;
      overflow: hidden;
      height: 600px;

      @media #{$media-tablet} {
        height: 400px;
      }

      @media #{$media-4k} {
        height: 1000px;
      }

      > ul.preview {
        flex: 0 1 auto;
        overflow: hidden;
        height: 100%;
        width: 100%;
        padding: 0;
        margin: 0;
        position: relative;

        > li.preview {
          display: flex;
          opacity: 0;
          transition: opacity 0.5s;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
          z-index: 0;

          &:first-child,
          &:target,
          &.active {
            opacity: 1;
            z-index: 9;
          }

          a {
            width: 100%;
          }

          img {
            display: block;
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0 auto;
            object-fit: contain;
            object-position: center;
          }
        }

        a:target + li.preview {
          opacity: 1;
          z-index: 9;
        }
      }

      > ul.thumbs:not(.preview) {
        flex: 0 0 auto;
        padding: 0;
        margin: 0;
        overflow: auto;
        white-space: nowrap;
        position: relative;
        padding-top: 7px;
        margin-top: -7px;
        text-align: center;

        // Perfect Scrollbar z-index needs to be over the active thumb box.
        > .ps__rail-x {
          z-index: 9999;
        }

        > li.thumb {
          display: inline-block;
          position: relative;
          margin-left: -5px;

          a {
            display: inline-block;
            position: relative;
            z-index: 999;
            border-top: 1px solid transparent;

            img {
              display: inline-block;
              border: 5px solid #131212;
              padding: 0;
              margin: 0;
              height: 128px;

              @media #{$media-4k} {
                height: 192px;
              }
            }
          }

          &.active {
            a {
              border-color: #c49dc5;

              img {
                border-color: black;
              }
            }

            z-index: 9999;

            .before,
            &::before {
              content: '';
              display: block;
              width: 0;
              height: 0;
              border-bottom: 11px solid #c49dc5;
              border-left: 11px solid transparent;
              border-right: 11px solid transparent;
              position: absolute;
              left: calc(50% - 11px);
              top: -7px;
              z-index: 99;
            }

            .after,
            &::after {
              content: '';
              display: block;
              width: 0;
              height: 0;
              border-bottom: 10px solid white;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              position: absolute;
              left: calc(50% - 10px);
              top: -6px;
              z-index: 9999;
            }
          }

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}
