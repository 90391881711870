.jobs-viewer {
  table.jobs {
    tbody {
      tr {
        td {
          padding: 0.5rem;
        }

        td.status {
          &[data-status=failed] {
            color: red;
            font-weight: bold;
          }
        }

        td.target {
          span {
            white-space: nowrap;

            svg.icon {
              width: 1rem;
              height: 1rem;
              vertical-align: middle;
              margin-right: 0.375rem;
            }
          }
        }

        td.identity {
          a {
            white-space: nowrap;
          }
        }
      }
    }
  }
}
