/**
 * Styles the citation sections.
 */

.card.citation {
  > p {
    padding: 1rem;
  }

  pre {
    padding: 1rem;
    white-space: pre-wrap;
  }
}
