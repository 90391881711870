/* Normal button colors */
$start-color: hsl(190, 0%, 100%);
$end-color: hsl(190, 0%, 90%);

// Button
//
// This is a simple button.
//
// Markup
// <div><button class="button">Ok</button></div>
//
// Styleguide Components.button
.button {
  position: relative;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 0.125rem;
  color: #333;
  cursor: pointer;
  padding: 0.25rem 0.4rem;
  margin-top: 0.5rem;
  text-align: center;
  text-decoration: none;
  font: $form-button-font;
  background: linear-gradient(to bottom, $start-color 5%, $end-color 100%);
  box-shadow: inset 0 0 1px 1px hsl(190, 60%, 100%);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Safari */
  -khtml-user-select: none;    /* Konqueror HTML */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                        supported by Chrome and Opera */

  svg,
  img {
    display: inline-block;
    height: calc(100% - 0.25rem);

    &.hover {
      display: none;
    }
  }

  &.inline {
    margin-top: 0;
  }

  &:hover {
    text-decoration: none;
    box-shadow: inset 0 0 2px 1px hsl(190, 76%, 52%);

    img {
      display: none;
    }

    img.hover {
      display: inline-block;
    }
  }

  &:active {
    top: 1px;
    background: linear-gradient(to bottom, hsl(190, 0%, 95%) 5%, hsl(190, 0%, 90%) 100%);
    background-color: rgb(221, 221, 221);
  }

  &.disabled,
  &:disabled {
    color: #aaa;
    cursor: default;

    &:active {
      position: relative;
      top: 0;
      background: linear-gradient(to bottom, hsl(190, 0%, 100%) 5%, hsl(190, 0%, 90%) 100%);
      background-color: rgb(221, 221, 221);
    }

    &:hover {
      box-shadow: inset 0 0 1px 1px hsl(190, 60%, 100%);
    }
  }
}

/* "red" button colors */
.button.red {
  background: linear-gradient(to bottom, hsl(0, 0%, 100%) 5%, hsl(0, 0%, 90%) 100%);
  box-shadow: inset 0 0 1px 1px hsl(0, 60%, 100%);

  &:hover {
    box-shadow: inset 0 0 1px 1px hsl(0, 60%, 50%);
  }

  &:active {
    background: linear-gradient(to bottom, hsl(0, 0%, 95%) 5%, hsl(0, 0%, 90%) 100%);
  }

  &.disabled,
  &:disabled {
    &:active {
      background: linear-gradient(to bottom, hsl(0, 0%, 100%) 5%, hsl(0, 0%, 90%) 100%);
    }

    &:hover {
      box-shadow: inset 0 0 1px 1px hsl(0, 60%, 100%);
    }
  }
}
