
.search.sidebar {
  flex: 0 0 auto;
  width: 20rem;
  box-shadow: 2px 0 0.25rem 1px #aaa;
  border-left: none;
  border-right: 1px solid #eee;
  z-index: 999;
  -webkit-transition: width 0.6s, left 0.6s;
  transition: width 0.6s, left 0.6s;
  overflow: hidden;
  box-sizing: border-box;
  left: 0;
  position: relative;

  &.reveal {
    width: 0;
    left: -0.5rem;
  }

  form {
    > input,
    > ul.tab-panels > li > input {
      margin-bottom: 1rem;
      margin-left: 1rem;
      margin-top: 0;
      width: calc(100% - 40px);
    }

    h3 {
      margin-left: 1rem;
      text-align: left;
      margin-bottom: 0;
      margin-top: 1.5rem;
    }

    p.empty {
      display: none;
      color: #888;
      margin-left: 1.5rem;
      text-align: left;
    }

    ul.facets:empty + p.empty {
      display: block;
    }

    ul.facets:nth-last-child(2) {
      margin-bottom: 1rem;
    }

    label {
      text-align: left;
      display: block;
      margin-top: 0.5rem;
      margin-left: 1rem;
      font: $search-facets-label;
      cursor: pointer;

      input.facet,
      span.facet {
        vertical-align: middle;
      }
    }
  }
}
