/**
 * Styling for the page/object header at the top of each page.
 */

$topbar-height: 1.5rem;

// Each item on the topbar
@mixin topbar-item-style {
  display: inline-block;
  font: $nav-link-font;
  line-height: $topbar-height;
  vertical-align: top;

  // Any top links
  > a,
  > div > a,
  > div > button {
    position: relative;
    text-decoration: none;
    display: inline-block;
    border-left: 1px solid #d3d3d3;
    border-image: linear-gradient(to bottom, #e8e8e8, #bfbfbf) 1px 100%;
    height: $topbar-height;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font: $nav-link-font;
    line-height: $topbar-height;
  }

  // A simple button
  .dropdown-button,
  .topbar-button {
    display: inline-block;
    text-align: left;
    text-indent: -9999px;
    width: 1.5rem;
    height: $topbar-height;
    padding: 0;
    border: none;
    outline: none;
    background: transparent;
    border-left: 1px solid #d3d3d3;
    position: relative;
    vertical-align: top;
    cursor: pointer;

    svg,
    img {
      width: 1rem;
      height: 1rem;
      object-fit: contain;
      display: block;
      top: 0.25rem;
      left: calc(50% - 0.5rem);
      position: absolute;
    }
  }

  // Specifically: The logged in 'person' link
  .current-person {
    outline: none;
    border: none;
    background: transparent;
    height: $topbar-height;
    padding: 0 0.25rem;
    cursor: pointer;
    border-left: 1px solid #d3d3d3;
    vertical-align: top;

    span {
      display: inline-block;
      margin-right: 1.75rem;
      padding: 0 3px;
      border-radius: 0.5em;
    }

    img {
      width: 1.25rem;
      height: 1.25rem;
      object-fit: contain;
      top: 0.125rem;
      box-sizing: border-box;
      border-radius: 0.25rem;
      display: block;
      background-color: white;
      border: 1px solid #c5c5c5;
      position: absolute;
      right: 0.5rem;
    }

    @media #{$media-phone} {
      display: inline-block;
      text-align: left;
      text-indent: -9999px;
      width: $topbar-height;
      height: $topbar-height;
      padding: 0;
      border: none;
      border-left: 1px solid #d3d3d3;
      outline: none;
      background: transparent;
      position: relative;
      vertical-align: top;
      cursor: pointer;

      img {
        width: 1.25rem;
        height: 1.25rem;
        display: block;
        top: 0.125rem;
        left: 0.125rem;
        position: absolute;
      }
    }
  }

  .dropdown-button:hover,
  .topbar-button:hover {
    background-image: linear-gradient(to top, #ececec, #fff);
  }

  // Dropdown button
  .dropdown-menu {
    // Correct the adjustment of dropdowns for topbar-button's
    .dropdown-menu-options {
      margin-top: 0.05rem;
      right: -0.15rem;
    }

    // Correct the adjustment of the account dropdown
    button.current-person + .dropdown-menu-options {
      right: 0.2rem;

      @media #{$media-phone} {
        right: -0.15rem;
      }
    }
  }
}

// Topbar
//
// Markup
// <nav class="topbar"><a class="logo" href="#"></a><ul class="navbar right"><li><a href="#">Login</a></li><li><a href="#">Sign Up</a></li><li><button href="#" class="topbar-button"><img src="/images/dynamic/hex/6aa3ae/ui/configuration-panel.svg"><img class="active" src="/images/dynamic/hex/ffffff/ui/configuration-panel.svg"><img class="hover" src="/images/dynamic/hex/4a838e/ui/configuration-panel.svg"></a></li></ul><ul class="navbar search right"><li><form><input id="query"></input><div class="topbar-button"><img src="/images/dynamic/hex/6aa3ae/icons/ui/search.svg"><img class="active" src="/images/dynamic/hex/ffffff/icons/ui/search.svg"><img class="hover" src="/images/dynamic/hex/4a838e/icons/ui/search.svg"><input id="search" type="submit"></input></div></form></li></ul></nav>
//
// Markup
// <nav class="topbar"><a class="logo" href="#"></a><ul class="navbar right"><li><button class="current-person"><span id="username">wilkie</span><img id="avatar" src="/QmTjy8quh4Q5JxYkK43R1qj8UKRFUeN8knGtmG8UKmLdbj/5dsHHfnCVahQ6xwo7dSrKfKTtJ4Xjm/raw/avatar.jpg"></a></li><li><button href="#" class="topbar-button"><img src="/images/dynamic/hex/6aa3ae/ui/configuration-panel.svg"><img class="active" src="/images/dynamic/hex/ffffff/ui/configuration-panel.svg"><img class="hover" src="/images/dynamic/hex/4a838e/ui/configuration-panel.svg"></a></li></ul><ul class="navbar search right"><li><form><input id="query"></input><div class="topbar-button"><img src="/images/dynamic/hex/6aa3ae/icons/ui/search.svg"><img class="active" src="/images/dynamic/hex/ffffff/icons/ui/search.svg"><img class="hover" src="/images/dynamic/hex/4a838e/icons/ui/search.svg"><input id="search" type="submit"></input></div></form></li></ul></nav>
//
// Styleguide Structure.topbar
@mixin topbar-style {
  z-index: 9999999999;
  width: 100%;
  margin: 0 auto;
  text-align: right;
  height: $topbar-height;
  line-height: $topbar-height;
  position: sticky;
  top: 0;

  // The logo at the top-left
  .logo {
    color: hsl(190, 80%, 70%);
    display: inline-block;
    width: 7.5rem;
    letter-spacing: 0.03em;
    vertical-align: top;
    position: absolute;
    left: 0;
    height: $topbar-height;
    line-height: $topbar-height;
  }

  // Sections of the topbar
  span.navbar,
  ul.navbar {
    display: inline-block;
    margin: 0;
    padding: 0;
    vertical-align: top;
    list-style-type: none;
    height: $topbar-height;

    > li,
    > span.item {
      @include topbar-item-style;
    }
  }

  // Specifically: The search bar section
  ul.navbar.search {
    border-image: linear-gradient(to bottom, #e8e8e8, #bfbfbf) 1 100%;
    border-left: 1px solid #d3d3d3;

    li {
      padding-left: 0.5rem;

      @media #{$media-tablet} {
        padding-left: 0;
      }

      // The query textbox
      input#query {
        vertical-align: top;
        height: 1.5rem;
        box-sizing: border-box;
        padding: 0 0.25rem;
        border: none;
        border-left: 1px solid #ccc;

        @media #{$media-tablet} {
          display: none;
        }
      }

      // The search button
      input[type=submit] {
        display: block;
        text-indent: -9999px;
        width: 100%;
        min-width: 0;
        height: 100%;
        position: absolute;
        cursor: pointer;
        border: none;
        outline: none;
        background: none;
        padding: 0;
        margin: 0;
      }

      .topbar-button {
        @media #{$media-tablet} {
          border-left: none;
        }
      }
    }
  }

  &.documentation {
    border: 1px solid #d3d3d3;

    > span.navbar {
      > span.item {
        > a {
          border: none;
        }
      }
    }
  }
}

nav.topbar,
span.topbar.documentation {
  @include topbar-style;
}
