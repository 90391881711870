/**
 * Styling for the 'about' marketing page.
 */

$video-width: 50rem;

body .content .index {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 0;
  margin-bottom: 0;

  & > * {
    text-align: left;
    margin: 0 auto;
    max-width: 100rem;
  }

  a {
    text-decoration: none;
    color: white;
    font-weight: 400;
  }
}

.content .index .header {
  background-color: #103942;
  position: relative;
  background-image: url ('/images/static/noise-teal.png');
}

.content .index .header > img {
  width: 100%;
}

.content .index .video {
  position: absolute;
  z-index: 2;
  top: 75%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
}

.content .index .resizer {
  background-color: white;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
}

.content .index .resizer > .resizer-content,
.content .index .video > .screen {
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: $video-width;
}

.content .index .resizer > .resizer-content > img,
.content .index .resizer > .resizer-content {
  max-width: 39rem;
}

.content .index .video > .screen {
  border: 1.5rem solid #103942;
}

.content .index .resizer > .resizer-content > img,
.content .index .video > .screen > img {
  max-width: $video-width;
  border: 2px solid white;
  padding: 3px;
  box-sizing: border-box;
  width: 100%;
}

.content .index .video .screen .embed {
  position: absolute;
  background-color: #103942;
  border: 2px solid white;
  padding: 3px;
  box-sizing: border-box;
  background-size: 100% 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  max-width: $video-width;
  width: 100%;
  height: 100%;
}

.index .markdown .image.left {
  padding-left: 1.5rem;
}

.index .markdown .image.right {
  padding-right: 1.5rem;
}

.index .markdown .image img.diagram {
  width: 300px;
  margin: 0;
  background-color: #f5f5ff;
}

.index .markdown p {
  margin: 0;
  padding: 0 1.5rem;
}

.index .markdown h2 {
  font: $sub-header-font;
  clear: both;
  font-size: 26px;
  margin: 0;
  padding: 0 1.5rem;
  text-align: center;
}

.index .markdown:nth-of-type(2n) {
  > *:not(.top-gallery) {
    background-color: #103942;
    color: #ccc;
  }

  h2 {
    color: white;
  }

  p strong {
    color: white;
  }
}

.content .index .markdown {
  position: relative;
  width: 100%;
  margin-top: 5px;
  text-align: justify;
  clear: both;

  > *:not(.top-gallery) {
    background-color: #103942;
    color: #ccc;

    &:first-child {
      padding-top: 1.5rem;
    }

    &:last-child {
      padding-bottom: 1.5rem;
    }
  }
}

.content .index .markdown:nth-of-type(2n+1) {
  > *:not(.top-gallery) {
    background-color: white;
    color: #333;
  }

  h2 {
    color: #103942;
  }

  p strong {
    color: #103942;
  }
}

.content .index .markdown .clear {
  clear: both;
}

.content .index .markdown p {
  font-family: "Lato", sans-serif;
  font-weight: 200;
  font-size: 1.5rem;
}

.content .index .markdown h2 + p {
  padding-top: 1.5rem;
}

.content .index .markdown p + p {
  padding-top: 0.75rem;
}

.content .index .markdown ~ .markdown p {
  font-family: "Lato", sans-serif;
  font-weight: 200;
  font-size: 1.5rem;
}

.index .markdown.sponsors.section img {
  width: 8rem;
}

.content .index .markdown.pillars {
  background-color: white;
  text-align: center;

  > p {
    background: #103942;
    border: 5px solid white;
    outline: 5px solid #103942;
    box-sizing: border-box;
    position: absolute;
    top: 40px;
    left: 10%;
    width: 80%;
    margin: 0 auto;
    text-align: justify;
    padding: 1.5rem;

    @media (max-width: 1000px) {
      font-size: 1.3rem;
    }

    @media (max-width: 750px) {
      font-size: 1.1rem;
    }

    @media (max-width: 650px) {
      top: 1.5rem;
    }

    @media (max-width: 550px) {
      text-align: center;
    }
  }

  > ul {
    display: block;
    text-align: center;
    width: 100%;
    margin: 0;

    &:last-child,
    &:first-child {
      padding: 0;
    }

    li {
      background: url ('/images/dynamic/hex/ffffff/index/pillar.svg') no-repeat 50% 0;
      color: white;
      display: inline-block;
      text-align: center;
      font-family: "Lato", sans-serif;
      font-weight: 700;
      font-size: 1rem;
      padding-top: 372px;
      height: 27px;
      width: 31%;
      background-size: 220px;
      background-position-y: calc(100% + 4px);
      text-shadow: 0 0 1px black;

      @media (max-width: 800px) {
        background-size: 190px;
        padding-top: 320px;
        height: 23px;
        font-size: 16px;
      }

      @media (max-width: 650px) {
        background-size: 160px;
        padding-top: 269px;
        height: 1.5rem;
        font-size: 14px;
      }

      @media (max-width: 500px) {
        background-size: 140px;
        padding-top: 410px;
        height: 17px;
        font-size: 12px;
      }
    }
  }
}

$nav-width: 220px;
$nav-smaller-width: 160px;
$nav-height: 70px;

body .content .index .nav-section > nav.facets {
  display: block;
  height: 100vh;
  position: sticky;
  top: 1.5rem;
  width: $nav-width;
  z-index: 999999;
  margin-top: 5px;

  @media (max-width: 1000px) {
    width: $nav-smaller-width;
  }

  @media #{$media-tablet} {
    width: 100%;
    height: $nav-height;
    line-height: $nav-height;
  }

  > ul.facets.navigation {
    height: 100%;
    display: flex;
    flex-direction: column;

    @media #{$media-tablet} {
      flex-direction: row;
      height: $nav-height;
      line-height: $nav-height;
    }

    .rest {
      flex: 1 0 0;
      background: #0a2c33;

      @media #{$media-tablet} {
        display: none;
        flex: 0 0 0;
      }
    }

    li.facet.navigation {
      flex: 0 0 0;

      @media #{$media-tablet} {
        flex: 1 1 0;
      }

      a {
        display: block;
        height: 100px;
        line-height: 100px;
        width: 100%;
        background: #103942;
        margin-bottom: 5px;

        @media #{$media-tablet} {
          text-align: center;
          height: $nav-height;
          line-height: $nav-height;
        }

        img {
          height: 44px;
          width: 44px;
          padding: 0.75rem;
          padding-left: 1rem;
          vertical-align: middle;

          @media (max-width: 1000px) {
            height: 24px;
            width: 24px;
            padding: 0.5px;
            padding-left: 0.75rem;
          }

          @media #{$media-tablet} {
            width: 100%;
            height: 100%;
            padding: 12px;
            box-sizing: border-box;
            margin: 0 auto;
            object-fit: contain;
          }
        }

        img.hover,
        img.active {
          display: none;
        }

        span {
          color: #2da0b7;
          display: inline-block;
          font: 300 1.1rem "Lato", sans-serif;
          white-space: normal;
          width: calc(100% - 79px);
          text-align: left;
          vertical-align: middle;
          padding-left: 0.5rem;
          box-sizing: border-box;

          @media (max-width: 1000px) {
            width: calc(100% - 43px);
            font-size: 1rem;
          }

          @media #{$media-tablet} {
            display: none;
          }
        }

        &:hover {
          background: #488e9c;
          text-decoration: none;

          img {
            display: none;
          }

          img.active {
            display: none;
          }

          img.hover {
            display: inline-block;
          }

          span {
            color: #f5f5f5;

            @media #{$media-tablet} {
              display: block;
              background: #0a2c33;
              border-bottom: 1px solid #252021;
              font: 300 1.2rem "Lato", serif;
              color: white;
              position: absolute;
              left: 0;
              width: 100%;
              padding: 0.75rem;
              z-index: 99;
              top: $nav-height;
            }
          }
        }
      }

      &.active {
        a {
          background: white;

          img {
            display: none;
          }

          img.hover {
            display: none;
          }

          img.active {
            display: inline-block;
          }

          span {
            color: #d280e0;

            @media #{$media-tablet} {
              display: block;
              background: white;
              border-bottom: 1px solid #ddd;
              font: 300 1.2rem "Lato", serif;
              color: #103942;
              position: absolute;
              left: 0;
              width: 100%;
              padding: 0.75rem;
              top: $nav-height;
            }
          }
        }
      }
    }
  }

  & + .markdown {
    margin-top: -100vh;

    @media #{$media-tablet} {
      margin-top: 5px;
      padding-top: 0;
    }
  }

  & ~ .markdown {
    margin-left: calc(#{$nav-width} + 5px);
    width: calc(100% - #{$nav-width} - 5px);
    text-align: justify;

    @media (max-width: 1000px) {
      margin-left: calc(#{$nav-smaller-width} + 5px);
      width: calc(100% - #{$nav-smaller-width} - 5px);
    }

    @media #{$media-tablet} {
      margin-left: 0;
      width: 100%;
      padding-top: 80px;
      margin-top: -75px;
    }

    &:empty {
      padding: 0;
      margin: 0;
    }
  }

  & ~ .markdown:nth-of-type(2n) {
    > *:not(.top-gallery) {
      background-color: white;
      color: #333;

      a {
        text-decoration: none;
        color: #488e9c;
        font-weight: 400;
      }
    }

    h2 {
      color: #103942;
    }

    p strong {
      color: #103942;
    }
  }

  & ~ .markdown:nth-of-type(2n+1) {
    > *:not(.top-gallery) {
      background-color: #103942;
      color: #ccc;
    }

    h2 {
      color: white;
    }

    p strong {
      color: white;
    }
  }
}

body .content .index .top-details .top-gallery {
  display: flex;

  .card.gallery {
    border-left: 0.75rem solid #103942;
    border-right: 0.75rem solid #103942;
    background: transparent;

    .flexbox {
      background: #888 url ("/images/static/noise_med_gray.png");
      max-height: 100vh;
      margin-left: 5px;
      margin-right: 5px;

      ul.preview {
        overflow: visible;

        @media (max-width: 750px) {
          > a {
            top: -118px;
            position: absolute;
            width: 0;
            height: 0;
          }
        }

        li {
          > a {
            position: absolute;
            left: 0;
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            width: 100%;

            img {
              max-height: 100%;
              margin: auto;
              display: inline-block;
            }
          }

          .caption {
            position: absolute;
            box-sizing: border-box;
            right: 0;
            width: 300px;
            height: 100%;
            color: white;
            display: flex;
            justify-content: center;
            flex-direction: column;

            p {
              margin: auto;
              color: #ffd2ff;
            }
          }

          &.has-caption > a {
            width: calc(100% - 300px);
          }

          @media (max-width: 750px) {
            &.has-caption > a {
              width: calc(100% - 200px);
            }

            .caption {
              width: 200px;

              p {
                font-size: 1rem;
              }
            }
          }

          @media #{$media-phone} {
            &.has-caption > a {
              width: 100%;
              height: 50%;
            }

            .caption {
              width: 100%;
              height: 50%;
              top: 50%;
            }
          }
        }
      }

      ul.thumbs li.thumb a img {
        background-color: white;
        max-width: none;
      }
    }
  }
}

body .content .index .facet-section {
  ul.facets.grid {
    display: grid;
    padding: 1.5rem;
    grid-template-columns: 33% 33% 33%;
    grid-auto-flow: row;
    grid-row-gap: 1.5rem;

    @media (max-width: 750px) {
      grid-template-columns: 50% 50%;
    }

    @media #{$media-phone} {
      grid-template-columns: 100%;
    }

    li.facet {
      a {
        padding: 0.75rem;
        box-sizing: border-box;
        border: 1px solid #103942;
        border-radius: 0.75rem;
        width: 100%;
        height: 100%;
        display: block;
        position: relative;

        h3 {
          text-align: center;
          border-bottom: 1px solid #696969;
          padding-bottom: 0.75rem;

          svg {
            width: 4rem;
            height: 4rem;
            display: inline-block;
            vertical-align: middle;
            fill: #2da0b7;
          }

          span {
            font: 400 1.4rem "Lato", sans-serif;
            color: white;

            @media (max-width: 1000px) {
              font: 400 1.2rem "Lato", sans-serif;
            }
          }

          svg + span {
            vertical-align: middle;
            padding: 0 0.75rem;
            display: inline-block;
            box-sizing: border-box;
            text-align: center;
            max-width: calc(100% - 74px);
            width: calc(100% - 74px);
          }
        }

        h3 + p {
          display: block;
          color: #ffd2ff;
          width: 100%;
          box-sizing: border-box;
          padding-top: 0.75rem;
          text-align: center;

          @media #{$media-tablet} {
            font: 400 1rem "Lato", sans-serif;
          }
        }

        &:hover {
          background: #488e9c;
          border: 1px solid white;
          text-decoration: none;

          h3 {
            border-bottom: 1px solid white;

            svg {
              fill: #fff;
            }

            span {
              color: white;
            }
          }

          p {
            color: white;
          }
        }
      }
    }
  }
}

.content .index footer {
  margin-top: 0.25rem;
}
