/**
 * Styles the auto-complete input fields.
 */

input.auto-complete {
  text-align: left;
  border: 1px solid #ddd;
  padding: 0;
  padding-left: 2.25rem;
  vertical-align: middle;
  width: 16rem;
  font: $object-selector-font;
  color: black;
  height: 2rem;
  line-height: 2rem;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: 0.25rem;
  background-size: 1.5rem;

  // A button that exists after the auto-complete
  ~ button {
    height: 2rem;
    top: 0.0625rem;
  }
}
