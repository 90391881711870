/**
 * Styles the build description metadata.
 */

.card.build {
  .description {
    text-align: left;
    padding: 1rem;
    font: $object-description-font;
    min-height: 100%;
    max-height: 100%;
    flex: 1 0 auto;
    position: relative;
    overflow: auto;
    width: 100%;
    box-sizing: border-box;

    *:not(.tag) > a {
      font: $object-description-font;
      text-decoration: underline;
    }

    .information {
      padding: 0;
      display: table;
      text-align: left;

      > * {
        display: table-row;

        > * {
          display: table-cell;
          padding-bottom: 0;
          vertical-align: baseline;

          &:not(h2) {
            width: 100%;
            padding-left: 1rem;
          }

          font: 300 0.9rem "Lato", sans-serif;
        }
      }

      h2 {
        font: 400 0.9rem "Lato", sans-serif;
        vertical-align: baseline;
        padding-top: 3px;
        text-align: left;
        white-space: nowrap;

        svg,
        img {
          width: 1rem;
          height: 1rem;
          margin-right: 0.5rem;
          vertical-align: top;
        }

        span {
          padding-right: 0.5rem;
        }
      }
    }
  }
}
