/**
 * Styling for the Run/Build terminal.
 */

.terminal.card {
  overflow: hidden;
  background: #222;

  .xterm {
    padding-left: 0.25rem;
    overflow: hidden;
    background: #222;
  }
}
