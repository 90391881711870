/**
 * Base rules for the application.
 *
 * Basically, these are defaults for simple elements.
 */

body *:focus {
  outline: none;
}

body:not(.no-javascript) {
  *.no-javascript {
    display: none;
  }
}

code {
  font: $code-font;
}

strong code {
  font-weight: bold;
}

em code {
  font-style: italic;
}

pre {
  text-align: left;
  font: $code-font;
  white-space: pre-wrap;
  padding: 0.25rem;

  &.wrap {
    word-break: break-word;
  }
}

p {
  font: $paragraph-font;

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  &.none {
    font-style: italic;
  }
}

input.link,
a {
  cursor: pointer;
  text-decoration: underline;
  margin: 0;
  padding: 0;
  min-width: 0;
  width: auto;
  background: none;
  border: none;

  &[disabled] {
    cursor: default;
  }
}

ul,
ol {
  li {
    font: $paragraph-font;
  }
}

*[hidden] {
  display: none !important;
  opacity: 0 !important;
}

.sr-only {
  display: none !important;
}

template {
  display: none !important;
}

// By default, iframe (widgets) will have a white background
iframe:not(:root) {
  background-color: white;
  flex: 1 1 auto;

  // They should have a white background when fullscreened
  &:-ms-fullscreen,
  &:-webkit-full-screen,
  &:-moz-full-screen,
  &:fullscreen {
    background-color: white;

    &::-ms-backdrop,
    &::-webkit-backdrop,
    &::-moz-backdrop,
    &::backdrop {
      background-color: white;
    }
  }
}

// This is used to cover elements when they need to get pointer events but would
// otherwise consume them. For instance, iframes during drag-and-drop and modals.
.cover {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: transparent;
}

.cover.show,
body.await-blur .cover:not(.hide),
body.dragover .cover:not(.hide) {
  display: block;
}

// Fixes for perfect-scrollbar
.ps__rail-y {
  z-index: 99999;
}

table {
  width: 100%;

  thead {
    tr {
      th {
        padding: 0.5rem 1rem;
        border-bottom: 1px solid #ddd;
        font: bold 0.9rem 'Lato', sans-serif;
      }
    }
  }

  tbody {
    tr {
      td {
        font: 300 0.9rem 'Lato', sans-serif;
        padding: 0.5rem 1rem;
        margin: 0;
        text-align: center;
        vertical-align: middle;
        border-bottom: 1px solid #ddd;

        &:not(:first-child) {
          border-left: 1px solid #ddd;
        }

        &:not(:last-child) {
          border-right: 1px solid #ddd;
        }

        > * {
          vertical-align: middle;
        }

        img.avatar {
          width: 1rem;
          height: 1rem;
          object-fit: contain;
          border: 0.0625rem solid #ddd;
          border-radius: 25%;
          vertical-align: middle;
          margin-right: 0.375rem;
        }
      }
    }
  }
}
