/**
 * Styling for tabs within the site.
 */

ul.tabs {
  list-style: none;
  line-height: 1.5rem;
  white-space: nowrap;

  li.dropdown-menu {
    position: absolute;
    right: 2rem;
    left: auto;
    width: 1rem;
    overflow: visible;
    top: 0;
    z-index: 99999;

    &:last-of-type {
      right: 0;
    }

    button.dropdown {
      cursor: pointer;
      padding: 0;
      width: 1rem;
      margin: 0;
      top: 0;
      height: 1.6rem;
      border: none;
      border-left: 0.0625rem solid #867387;
      border-right: 0.0625rem solid #867387;

      svg,
      img {
        width: 1rem;
        height: 1rem;
        top: calc(50% - 0.5rem);
        left: 0;
        position: absolute;
      }

      img.hover {
        display: none;
      }

      &:hover img.hover {
        display: block;
      }
    }

    ul.dropdown-menu-options {
      z-index: 99999;

      li {
        display: block;

        button.delete {
          display: none;
        }

        img {
          display: none;
        }

        img.nav {
          display: block;
        }

        img.nav.hover {
          display: none;
        }

        &:hover img.nav.hover {
          display: block;
        }
      }
    }
  }
}

// Main content tabs
@mixin main-tab-style {
  margin: 0;
  text-align: left;
  display: block;
  position: relative;
  z-index: 99999;
  border-bottom: 0.125rem solid red;

  // Generic tab styling
  > span.tab,
  > li.tab {
    position: relative;
    cursor: pointer;
    display: inline-block;
    height: 1.5rem;
    margin: 0;
    padding: 0;
    vertical-align: top;
    border-bottom: 0.125rem solid blue;
    margin-bottom: -0.125rem;

    &:first-child {
      border-left: none;
    }

    > a {
      border-left: 0.0625rem solid transparent;
      border-right: 0.0625rem solid transparent;
      text-decoration: none;
      display: inline-block;
      height: 1.5rem;
      padding: 0.1rem 0.3rem;
      padding-top: 0.1875REM;
      font: $tab-font;
      text-align: center;
      line-height: 1.25rem;
      box-sizing: border-box;
    }

    > a:hover {
      text-decoration: none;
    }

    &.active {
      border-left: 0.0625rem solid #96659c;
      border-right: 0.0625rem solid #96659c;
      z-index: 999;
      border-bottom: 0.125rem solid blue;

      &:first-child {
        border-left: none;
      }

      > a {
        font: $tab-active-font;
        line-height: 1.25rem;
      }

      &:first-child,
      &:hover:first-child {
        margin-left: 0;
      }
    }
  }

  // Tabs within tabs (secondary tabs)
  ~ * ul.tabs.card-tabs {
    z-index: 99999;

    > li.tab {
      &.active {
        border-bottom: 0.125rem solid red;
      }
    }
  }

  // Documentation (dummy) tabs
  // These are embedded in the docs in tutorials/guides to show what a tab
  // would look like.
  &.documentation {
    display: inline-block;
    height: 1.4rem;
    line-height: 1.4rem;

    > .tab {
      height: 1.4rem;
      line-height: 1.4rem;

      > a {
        height: 1.4rem;
        line-height: 1.4rem;
        vertical-align: text-bottom;
      }
    }
  }

  // The sidebar "buttons"
  > span.tab.sidebar,
  > li.tab.sidebar {
    position: absolute;
    top: 0;
    right: 0;
    width: 2rem;
    height: 100%;
    border-left: 0.0625rem solid #867387;
    box-shadow: 0 0 0.5rem 0.125rem #ebcaec inset;
    box-shadow: 0 0 0.5rem 0.125rem #8c778e inset;
    background: #feefff;
    z-index: 999;

    &.disabled {
      opacity: 0.25;
    }

    > a,
    > button {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      text-indent: -9999px;
      text-align: left;
      background-repeat: no-repeat;
      background-position: 0.8rem 50%;
      background-size: 1.4rem;

      svg,
      img {
        height: 1.25rem;
        width: 1.25rem;
        top: 0.125rem;
        left: 0.4rem;
        position: absolute;

        &.active {
          display: block;
        }
      }

      .arrow {
        width: 0.6rem;
        height: 0.6rem;
        transform: rotate(45deg);
        position: absolute;
        left: -0.35rem;
        top: calc(50% - 0.3rem);
        background: transparent;
        box-sizing: border-box;
        border: 0.125rem solid #867387;
        border-right-color: transparent;
        border-top-color: transparent;

        & + .arrow {
          background: #ba99bb;
          border: none;
          left: calc(-0.35rem + 0.125rem);
          overflow: hidden;

          &::before,
          &::after {
            content: '';
            position: absolute;
            background: blue;
            top: 0;
            left: calc(-0.7rem + 0.125rem);
            width: calc(0.7rem - 0.125rem);
            height: calc(0.7rem - 0.125rem);
          }

          &::after {
            top: 0.6rem;
            left: 0;
          }
        }
      }
    }

    &.reveal {
      box-shadow: none;
      background: white;

      > a,
      > button {
        svg,
        img {
          display: block;

          &.active {
            display: none;
          }
        }
      }
    }

    & + li.tab {
      margin-left: 0;
    }

    &:first-child {
      position: relative;
      right: auto;
      left: 0;
      z-index: 9999;
      border-left: none;
      border-right: 0.0625rem solid #867387;

      > a,
      > button {
        .arrow {
          transform: rotate(-135deg);
          left: auto;
          right: -0.35rem;
        }
      }

      + li.tab {
        padding-left: 0.35rem;
        border-left: none;
      }
    }

    &.reveal > a .arrow,
    &.reveal > button .arrow {
      box-shadow: none;

      & + .arrow {
        box-shadow: none;
        background: white;
      }
    }

    &:first-child > button .arrow + .arrow,
    &:first-child > a .arrow + .arrow {
      left: auto;
      right: calc(-0.35rem + 0.125rem);

      &::before,
      &::after {
        right: calc(-0.7rem + 0.125rem);
        width: calc(0.7rem - 0.125rem);
        height: calc(0.7rem - 0.125rem);
      }

      &::after {
        left: auto;
        right: 0;
      }
    }
  }
}

ul.tabs.card-tabs,
span.tabs.card-tabs.documentation {
  @include main-tab-style;
}

div.tab-panels,
ul.tab-panels {
  &.card.filled.flex {
    overflow: hidden;
  }

  div.tab-panel,
  form.tab-panel,
  li.tab-panel {
    position: relative;
    display: none;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;

    &.flex {
      display: none;
    }

    &.filled {
      overflow: hidden;
    }

    &.horizontal {
      flex-direction: row;

      .card:not(.sidebar):not([hidden]) + .card {
        margin-top: 0;
        margin-left: 0.125rem;
      }

      &.responsive-fit {
        @media #{$media-fit} {
          flex-direction: column;

          .card:not(.sidebar):not([hidden]) + .card {
            margin-top: 0.125rem;
            margin-left: 0;
          }
        }
      }

      @media #{$media-tablet} {
        flex-direction: column;
      }
    }

    &.cards {
      margin-bottom: -0.125rem;
    }

    &.default,
    &.active,
    &.active.flex {
      display: flex;
    }

    &.active ~ .default {
      display: none;
    }
  }
}
