/**
 * The styling of the federation lookup page.
 */

.card.object-locator {
  padding: 0 2rem;

  .card.searching {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    width: 10rem;
    height: 10rem;
    border: 1px solid #ccc;
    box-shadow: 0 0 2px 1px black;
    background-size: 40%;
    border-radius: 1rem;

    h2 {
      background-color: transparent;
    }

    img.error {
      display: none;
    }

    &.error:not(.loading):not(.transparent) {
      h2 {
        background-color: transparent;
      }

      img.error {
        display: inline-block;
      }
    }
  }
}
