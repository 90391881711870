.object-list {
  display: table;
  margin: 10px 20px;
  margin-bottom: 0;
  text-align: left;

  > .object-list-header,
  > li.object {
    display: table-row;

    > * {
      vertical-align: top;
      display: table-cell;
      padding-left: 0.7rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      font: 300 0.9rem "Lato", sans-serif;
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:not(.icon) {
        width: 100%;
      }

      button:not(.delete):not(.edit) {
        cursor: pointer;
        border: 1px solid #c7c7c7;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        background-color: #96cfdb;
        overflow: hidden;
        text-indent: -9999px;
        padding: 0;
        margin: 0;
        vertical-align: middle;

        svg,
        img {
          width: 1rem;
          height: 1rem;
          position: absolute;
          left: 0.95rem;
          top: 0.5rem;
        }

        &:hover {
          background-color: #2da0b7;
        }
      }

      &:first-child {
        padding-left: 0;
      }
    }

    > .action {
      padding-left: 0.25rem;
    }

    > p.configuration-column,
    > p.name,
    > h2.name {
      line-height: 1.25rem;
      padding-left: 10px;
      width: 100%;

      span {
        position: relative;
        top: 0.125rem;
      }
    }

    > p.type {
      text-align: right;
      position: relative;
      top: 0.125rem;
    }

    &.supplemental {
      > * {
        padding-top: 0;
        padding-bottom: 0.125rem;
      }

      > p.name,
      > h2.name {
        font-size: 0.7rem;
      }

      > p.type {
        font-size: 0.7rem;
        font-family: inconsolata, monospace;
      }
    }

    &.object-list-header {
      & > * {
        font-weight: bold;

        &.icon {
          padding-top: 0.125rem;
        }

        & > svg.icon {
          fill: #666;
        }
      }
    }
  }

  svg.icon,
  img.icon {
    width: 1.25rem;
    height: 1.25rem;
    vertical-align: middle;
  }
}
