/**
 * Policy styling.
 *
 * Policies are agreements that users must consent to or acknowledge in order
 * to make use of the site.
 */

.card > .policy-box {
  margin: 0 auto;
  width: 90%;
  background-color: lightgrey;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 5px;
  border: solid;
  text-align: center;

  & {
    padding-top: 10px;
  }

  h2 {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  p {
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
  }

  // Add spacing between paragraphs.
  p ~ p {
    margin-top:  13px;
  }

  .checkbox {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
