.target-viewer {
  li.target.card {
    width: 100%;

    .target-information-container {
      max-width: 60rem;
      margin: 0 auto;

      .target-information {
        display: table;
        width: 100%;

        .target-information-row {
          display: table-row;

          .target-availability-figure {
            display: table-cell;
            padding: 0.5rem;
            vertical-align: top;

            figure {
              width: auto;
            }
          }

          .objects-container {
            display: table-cell;
            padding: 0.5rem;
            vertical-align: top;
            padding-top: 2rem;

            ul.objects {
              li.object {
                margin-bottom: 0;
              }
            }
          }

          .target-availability {
            display: table-cell;
            padding: 0.5rem;
            vertical-align: middle;
            width: 100%;

            p.status {
              padding-bottom: 0.5rem;
            }

            table {
              border: 0.0625rem solid #ddd;

              td {
                // Ensure node hostnames are not word wrapped
                white-space: nowrap;

                svg.icon {
                  width: 1rem;
                  height: 1rem;
                  margin-left: 0.25rem;

                  fill: #ffb0b0;
                }
              }

              td.status[data-status=down] {
                font-weight: bold;
                color: red;
              }
            }
          }
        }
      }
    }
  }
}
