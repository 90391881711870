/* Styling for the Donut visualizations.
 *
 */

figure.donut {
  display: flex;
  justify-content: space-around;
  // TODO: handle resize for small devices
  //flex-direction: column;
  flex-direction: row;
  width: 40rem;
  margin: 0 auto;

  .chart-number {
    font-size: 0.4em;
    line-height: 1;
    text-anchor: middle;
    text-align: center;
    transform: translateY(-0.25em);
  }

  .chart-label {
    font-size: 0.4em;
    line-height: 1;
    text-anchor: middle;
    text-align: center;
    transform: translateY(0.8em);
  }

  .chart {
    flex: 1;
    align-self: center;
  }

  figcaption {
    flex: 1;
    align-self: center;
    text-align: left;

    ul.donut-keys {
      li {
        white-space: nowrap;
      }

      li:not(:first-child) {
        margin-top: 10px;
      }

      span {
        display: inline-block;
        height: 1.5rem;
        line-height: 1.5rem;
        vertical-align: middle;
      }

      span:not(:last-child) {
        margin-right: 0.25rem;
      }

      span.circle {
        border-radius: 50%;
        width: 1.5rem;
        margin-right: 0.5rem;
      }
    }
  }
}
