/**
 * Styling for tabs within an embedded site.
 */

body.embedded .content.flex {
  > ul.tabs.card-tabs {
    position: fixed;
    top: 0;
    right: 0;
    width: 1rem;
    height: $topbar-height;
    box-sizing: border-box;
    z-index: 9999999999;
    box-shadow: none;
    border: none;
    background: none;

    > li.tab {
      display: none;
    }

    > li.dropdown-menu {
      position: static;
      right: 0;
      top: 0;
      height: $topbar-height;

      button {
        display: block;
        border-right: none;
        border-left: 1px solid #d3d3d3;
        height: $topbar-height;
      }
    }
  }
}
