/**
 * The styling for the run list panel.
 */

$run-list-item-transition-time: 0.3s;

.sidebar {
  @mixin run-list-style {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    vertical-align: top;
    position: relative;
    text-align: left;

    p,
    h3 {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #eaeaea;
    }

    h3 {
      padding-bottom: 3px;
      font: bold 0.9rem "Lato", sans-serif;
    }

    .loading {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    p {
      color: #888;
    }

    > li {
      position: relative;
      display: flex;
      flex-direction: row;

      /* Run list delete button */
      form .delete {
        display: none;
      }

      > a {
        position: relative;
        display: block;
        flex: 1 1 auto;
        padding-left: 3.5rem;
        padding-right: 0.5rem;
        border-bottom: 1px solid #eaeaea;
        font: $side-list-font;
        z-index: 99999;
        height: 3rem;
        line-height: 3rem;
        text-decoration: none;

        &.clicked {
          outline: none;
        }

        &:hover {
          text-decoration: none;
        }

        span.status {
          display: inline-block;
          position: absolute;
          width: 2rem;
          height: 2rem;
          top: calc(50% - 1rem);
          left: 10px;

          svg {
            width: 2rem;
            height: 2rem;
            padding-left: 4px;

            &.failed,
            &.done {
              display: none;
            }
          }

          .dot {
            display: none;
            position: absolute;
            width: 0.5rem;
            height: 0.5rem;
            animation: 0.9s infinite alternate pulse;
            animation-delay: 0s;
            margin-left: 1px;
            margin-top: 1px;
            left: 0;
            top: calc(50% - 4px);
            border-radius: 1rem;
            background-color: #b3b3b3;
          }

          img ~ .dot,
          svg ~ .dot {
            top: 23px;
          }

          .dot + .dot {
            animation-delay: -0.6s;
            left: 0.7rem;
          }
        }

        span.status + span:not(:last-child) {
          padding-top: 0.6rem;
          line-height: 1em;
          height: 1em;
          display: block;
          font-weight: normal;
          text-overflow: ellipsis;
          overflow: hidden;

          & + span {
            padding-top: 2px;
            line-height: 1em;
            height: 1em;
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        & + ul.actions {
          position: relative;
          display: none;
          height: 3rem;
          line-height: 3rem;
          z-index: 99999;
          padding-right: 0.125rem;
          right: 0;

          li.action {
            display: inline-block;
            width: 1rem;
            height: 1rem;
            padding-right: 5px;
            vertical-align: top;

            button {
              text-indent: -9999px;
              overflow: hidden;
              width: 1rem;
              height: 1rem;
              display: block;
              border: none;
              outline: none;
              cursor: pointer;
              position: relative;
              background: none;
              background-size: 100% 100%;
              background-position: 0 0;
              background-repeat: no-repeat;

              svg {
                width: 1rem;
                height: 1rem;
                position: absolute;
                left: 0;
                top: 0;
              }
            }
          }
        }
      }

      &[data-status=done] {
        > a span.status svg.done {
          height: 2rem;
          width: 2rem;
          display: inline-block;
        }

        > a span.status.job {
          height: 38px;
          background-position: 50% 17px;
          background-size: 20px 20px;
        }

        > a span.status img {
          top: -7px;
          position: relative;
          height: 22px;
          width: 22px;
        }
      }

      &[data-status=failed] {
        > a span.status svg.failed {
          height: 2rem;
          width: 2rem;
          display: inline-block;
        }

        > a span.status.job {
          height: 38px;
          background-position: 50% 17px;
          background-size: 20px 20px;
        }

        > a span.status img {
          top: -7px;
          position: relative;
          height: 22px;
          width: 22px;
        }
      }

      &[data-status=running],
      &[data-status=pending] {
        > a span.status .dot {
          display: inline-block;
        }

        > a span.status img {
          top: -7px;
          position: relative;
          height: 22px;
          width: 22px;
        }
      }

      &[data-status=pending] {
        > a span.status .dot {
          animation: none;
          animation-delay: 0s;
          width: 4px;
          height: 4px;
          margin-left: 3px;
          margin-top: 3px;

          & + .dot {
            animation-delay: 0s;
          }
        }
      }

      > a span.status .dot + .dot + .dot {
        animation-delay: -0.3s;
        left: 1.4rem;
      }

      &[data-status=pending] > a span.status .dot + .dot + .dot {
        animation-delay: 0s;
      }

      // Highlighted list item
      &.active {
        form .delete {
          display: block;
        }

        > a {
          font: $side-list-active-font;
          line-height: 3rem;
          margin-left: 1rem;
          -webkit-transition: margin-left $run-list-item-transition-time, margin-right $run-list-item-transition-time;
          transition: margin-left $run-list-item-transition-time, margin-right $run-list-item-transition-time;
        }

        > ul.actions {
          display: flex;
          flex-direction: row;
          align-items: center;
          right: 0;
        }

        .before {
          content: '';
          display: block;
          width: 1.4rem;
          height: 1.4rem;
          position: absolute;
          left: 0.3rem;
          border: 1px solid #eaeaea;
          top: calc(100% - 2.35rem);
          z-index: 99999;
          transform: rotate(45deg);
        }

        & + h3,
        & + p,
        & + li > a {
          border-top: 1px solid #eaeaea;
          margin-top: -1px;
        }

        > a:focus {
          outline: none;
        }

        > a span.status svg.failed,
        > a span.status svg.done {
          display: none;
        }

        &[data-status=done] {
          > a span.status svg.done {
            display: inline-block;
          }
        }

        &[data-status=failed] {
          > a span.status svg.failed {
            display: inline-block;
          }
        }
      }

      @keyframes pulse {
        0% {
          width: 0.5rem;
          height: 0.5rem;
          margin-left: 0.0625rem;
          margin-top: 0.0625rem;
        }

        50% {
          width: 0.25rem;
          height: 0.25rem;
          margin-left: 0.1875em;
          margin-top: 0.1875rem;
        }
      }

      @keyframes slow-pulse {
        0% {
          opacity: 1;
        }

        50% {
          opacity: 0.7;
        }
      }
    }

    &.left {
      > li {
        padding-right: 0;
        -webkit-transition: padding-right $run-list-item-transition-time;
        transition: padding-right $run-list-item-transition-time;

        .before {
          left: auto;
          right: 0.3rem;
        }

        // Give this more specificity than non-left lists
        &:not(.active) {
          a {
            margin-left: 0;
            margin-right: 0;
          }
        }

        &.active {
          padding-right: 1rem;

          a {
            margin-left: 0;
            margin-right: 0;

            + ul.actions {
              right: 0;
            }
          }
        }
      }
    }

    .ps__rail-y {
      // Make sure the styled scrollbar appears over the run list items
      z-index: 99999;
    }
  }

  ul.jobs,
  ul.runs,
  nav.navigation-list > ul {
    @include run-list-style;
  }

  nav.navigation-list > ul li a {
    padding-left: 1rem;
  }
}
