/**
 * Styling specific to the Details page.
 */

// Sections

.top-details.card:not(.loading) {
  background: transparent;

  @media (max-height: 800px) {
    height: 100%;
  }

  .top-gallery {
    &:not(form) {
      display: flex;
      flex-direction: row;
      overflow: hidden;

      &:not(.no-images):not(.build) {
        max-height: 600px;
        overflow: auto;

        @media #{$media-tablet} {
          max-height: none;
        }

        @media (min-height: 1280px) {
          max-height: 1000px;
        }

        @media (max-height: 800px) {
          max-height: 100%;
        }
      }

      &.no-images {
        max-height: 100%;
        overflow: auto;
      }

      @media #{$media-tablet} {
        display: block;
        max-height: none;
      }
    }
  }
}

// Description Area
@import "objects/details/description.scss";

// Image Gallery
@import "objects/details/gallery.scss";

// Run/Build Sections
@import "objects/details/run.scss";

// Citation Section
@import "objects/details/citation.scss";

// Inputs/Outputs Section
@import "objects/details/io.scss";

// Requirements Section and Modal
@import "objects/details/requirements.scss";

// Installation Section
@import "objects/details/installation.scss";

// Description Markdown Editor Modal
@import "objects/details/edit-description.scss";
