/**
 * Styling for each individual comment.
 */

.social.sidebar {
  ul.comments {
    /* An individual comment */
    li.comment {
      /* The highlighted comment */
      &.active {
        & > .comment-body {
          background-color: #eee;
        }
      }

      .header {
        margin-left: 40px;
      }

      .actions {
        margin-left: 40px;
        position: relative;
        top: -4px;

        a,
        input {
          font: $social-actions-font;
        }
      }

      span {
        display: inline-block;
        font: $social-time-font;
      }

      /* The avatar icon to accompany the post */
      span.avatar {
        margin-right: 5px;
        position: absolute;

        img {
          width: 30px;
          height: 30px;
          border-radius: 5px;
          border: 1px solid #e0e0e0;
        }
      }

      &:not(:first-child) {
        margin-top: 15px;
      }

      /* The name of the poster */
      span.name {
        font: $social-name-font;
        color: #585858;
      }

      /* The verb of the activity */
      span.verb {
        font: $social-verb-font;
      }

      /* The time or time ago the post was made */
      span.time {
        font: $social-time-font;
      }

      /* The actual content */
      .content {
        margin-top: 5px;
      }
    }
  }
}
