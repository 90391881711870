/**
 * Styles the run/build/etc sections of details page.
 */

.card.details {
  ul.command {
    font: inherit;

    li {
      font: inherit;
      display: inline-block;
      margin-left: 0.5rem;
      border-bottom: 1px solid #ddd;

      &:first-child {
        margin-left: 0;
      }

      code {
        font: $code-font;
      }
    }
  }
}
