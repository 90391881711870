/**
 * Styling for the page/object header at the top of each page.
 */

// Height of the header (generic view, not mobile etc)
$object-nav-height: 1.5rem;

// Sub-components of the header
@import "header/notice-bar.scss";
@import "header/roles.scss";

// Site Header
//
// Markup for icon with no type
// <h1 style="border: 1px solid transparent"><img class="large-icon" src="/images/icons/ui/documentation.svg"><div class="name">Section Title</div></h1>
//
// Markup for icon with no type but organization
// <h1 style="border: 1px solid transparent"><img class="large-icon" src="/images/icons/ui/documentation.svg"><div class="name">Section Title</div><div class="organization">This page does a thing.</div></h1>
//
// Markup for icon with type
// <h1 style="border: 1px solid transparent"><img class="large-icon" src="/images/icons/ui/documentation.svg"><div class="type"><a href="#">simulator</a></div><div class="name">Section Title</div></h1>
//
// Markup for icon with type and version
// <h1 style="border: 1px solid transparent"><img class="large-icon" src="/images/icons/ui/documentation.svg"><div class="type"><a href="#">simulator</a></div><div class="name"><span><a href="#">Section Title</a></span><span class="version">1.0.3b</span></div></h1>
//
// Markup for icon with type and version and organization
// <h1 style="border: 1px solid transparent"><img class="large-icon" src="/images/icons/ui/documentation.svg"><div class="type"><a href="#">simulator</a></div><div class="name"><span><a href="#">Section Title</a></span><span class="version">1.0.3b</span></div><div class="organization">Microsoft or Something</div></h1>
//
// Styleguide Structure.header
h1 {
  position: relative;
  overflow: visible;
  font: $header-font;
  padding-bottom: 0.25rem;
  min-height: 4.5rem;
  display: block;

  @media #{$not-media-tablet} {
    &[data-has-parent] {
      min-height: 6rem;
    }
  }

  /* Object Type */
  .type {
    white-space: nowrap;
    letter-spacing: 0.05em;
    display: flex;
    flex-direction: row;
    overflow: visible;
    text-overflow: ellipsis;
    padding-left: 6.5rem;
    font: $header-type-font;
    height: 1.5625rem;
    z-index: 999;
    max-width: calc(100% - 10rem);

    > a {
      position: relative;
      bottom: -0.4375rem;
      font: $header-type-font;
      text-decoration: none;
      flex: 0 0 auto;
    }

    > span,
    > ul {
      white-space: nowrap;
      position: relative;
      bottom: -0.4375rem;
      display: inline-block;
      color: #888;
      flex: 0 1 auto;
      overflow: hidden;
      padding-left: 0.5rem;
      text-overflow: ellipsis;

      li {
        display: inline-block;
        color: #888;
        font: $header-type-font;
        font-weight: 300;

        &:not(:last-child) {
          margin-right: 0.25rem;
        }
      }
    }

    img {
      float: right;
      margin-top: 0.6rem;
      margin-right: 0.6rem;
      height: 1.8rem;
      width: 1.8rem;
      vertical-align: middle;
    }

    &.no-organization {
      margin-top: 0.6rem;
    }
  }

  .name {
    display: block;
    padding-left: 6.5rem;
    padding-right: 6.5rem;
    padding-bottom: 2px;
    word-wrap: break-word;

    a {
      font: $header-font;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }

  /* We will make it always a dropdown! */
  .actions.dropdown-menu {
    z-index: 99999999;
    position: absolute;
    bottom: auto;
    right: 0;
    top: 0;
    width: 2rem;
    height: 100%;
    margin-left: 0.6rem;
    padding: 0;
    padding-left: 1px;
    border-left: 1px solid magenta;

    button.actions-dropdown {
      display: block;
      cursor: pointer;
      width: 2rem;
      height: calc(50% + 1px);
      background: none;
      background-color: magenta;
      border: 1px solid blue;
      padding: 0;

      svg,
      img {
        width: 1.5rem;
        height: 1.5rem;
        vertical-align: middle;

        &.hover {
          display: none;
        }
      }

      & + ul.dropdown-menu-options.options {
        right: 0;

        li {
          border: none;

          a,
          button,
          input {
            height: auto;
          }
        }
      }

      &[disabled] {
        cursor: default;
        pointer-events: none;
      }
    }

    button ~ button.actions-dropdown {
      top: -1px;
      position: relative;
    }
  }

  // Object navigation
  nav.dropdown-menu.media-tablet {
    position: absolute;
    right: 0;
    top: 0;
    height: calc(50% + 1px);
    width: 2rem;
    box-shadow: none;
    z-index: 999999999;
    padding: 0;

    button.nav-dropdown {
      width: 100%;
      height: 100%;
      border: 1px solid blue;
      background: red;
      padding: 0;
      margin: 0;
      cursor: pointer;
      vertical-align: top;

      svg,
      img {
        width: 1.5rem;
        height: 1.5rem;
        vertical-align: middle;

        &.hover {
          display: none;
        }
      }

      &:hover {
        img {
          display: none;
        }

        img.hover {
          display: inline-block;
        }
      }
    }

    @media #{$not-media-tablet} {
      position: relative;
      top: 0;
      left: 0;
      line-height: $object-nav-height;
      background-color: #416e77;
      width: 100%;
      border-bottom: 1px solid #092227;
      box-shadow: 0 -2px 5px 0 black;
      height: $object-nav-height;
      z-index: 99;

      > button {
        display: none;
      }

      > ul.dropdown-menu-options {
        height: $object-nav-height;
        line-height: $object-nav-height;
        display: block;

        li {
          position: relative;
          display: inline-block;
          vertical-align: top;
          overflow: hidden;
          font: $header-nav-font;
          height: $object-nav-height;
          line-height: $object-nav-height;

          .icon {
            padding-left: 1rem;
            font: $header-nav-font;
            min-height: $object-nav-height;
            line-height: $object-nav-height;
          }

          a.icon {
            vertical-align: top;
            display: inline-block;
            height: $object-nav-height;
            padding-right: 0.75rem;
            font: $header-nav-font;
            line-height: $object-nav-height;
            position: relative;
            z-index: 9;
            box-sizing: border-box;
            text-decoration: none;

            img.hover {
              display: none;
            }

            svg,
            img {
              vertical-align: middle;
              margin-right: 0.5rem;
              margin-bottom: 0;
              width: calc(#{$object-nav-height} - 0.5rem);
              height: calc(#{$object-nav-height} - 0.5rem);
            }
          }

          .after {
            vertical-align: top;
            height: $object-nav-height;
            width: $object-nav-height;
            line-height: $object-nav-height;
            content: '';
            display: inline-block;
            position: absolute;
            right: -0.25rem;
            z-index: 0;
            transform: scaleX(0.5) rotate(45deg);
          }

          &:last-child {
            padding-right: 0.5rem;

            .after {
              vertical-align: top;
              height: $object-nav-height;
              width: $object-nav-height;
              line-height: $object-nav-height;
              content: '';
              display: inline-block;
              position: absolute;
              right: -0.25rem;
              z-index: 0;
              transform: scaleX(0.5) rotate(45deg);
            }
          }

          .before {
            vertical-align: top;
            height: $object-nav-height;
            width: $object-nav-height;
            line-height: $object-nav-height;
            content: '';
            display: inline-block;
            position: absolute;
            left: -0.7rem;
            z-index: 99;
            transform: scaleX(0.5) rotate(45deg);
          }

          &:first-child {
            a.icon {
              padding-left: 0.25rem;
            }

            .before {
              display: none;
            }
          }

          &:hover {
            a {
              text-decoration: none;

              img {
                display: none;
              }

              img.hover {
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }

  div.actions.dropdown-menu button.actions-dropdown:hover {
    img {
      display: none;
    }

    img.hover {
      display: inline-block;
    }
  }

  /* Icon */
  .large-icon {
    width: 3.5rem;
    height: 3.5rem;
    margin: 0.625rem;
    position: absolute;
    top: 0;
    left: 1rem;

    &.avatar {
      background-color: white;
      width: 4.75rem;
      height: 4.75rem;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0;
      border-right: 0.25rem solid blue;
      object-fit: contain;
    }
  }

  @media #{$not-media-tablet} {
    nav ~ .large-icon {
      top: 1.6rem;
    }

    nav ~ .actions.dropdown-menu {
      top: $object-nav-height;
      height: calc(100% - #{$object-nav-height});
    }
  }

  /* Authorship */
  .parent,
  .organization {
    display: block;
    font: $object-sub-header-font;
    min-height: 1em;
    padding-left: 6.5rem;

    &:empty {
      height: 0;
      min-height: 0;
    }
  }

  &.person .actions {
    width: 168px;
  }

  &.person .name,
  &.person .type,
  &.person .organization {
    padding-left: 6rem;
  }

  /* Object Name */
  .large-icon + .name,
  .shadow + .name,
  .actions + .name {
    padding-top: 1rem;

    &:last-child {
      padding-top: 1.8rem;
    }
  }

  /* Version */
  .version {
    margin-left: 0.6em;
    font: $header-version-font;
    background-color: #2da1b9;
    padding: 0.1em 0.3em;
    border-radius: 0.3em;
    text-align: center;
    border: 1px solid #eaeaea;
    vertical-align: middle;
    display: inline-block;

    + a.published {
      text-indent: -9999px;
      display: inline-block;
      overflow: hidden;
      width: 1rem;
      height: 1rem;
      position: relative;
      margin-left: 0.5rem;
      font: $header-version-font;
      vertical-align: middle;

      svg {
        height: 1rem;
        width: 1rem;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  /* Header Badges */
  ul.badges {
    display: inline-block;

    li.badge {
      text-indent: -9999px;
      overflow: hidden;
      width: 20px;
      height: 20px;
      display: inline-block;
      background-size: auto 20px;
      background-repeat: no-repeat;
      vertical-align: middle;

      &.trusted {
        background-image: url ('/images/trusted.svg');
      }
    }
  }

  .staged-icon {
    cursor: help;
    width: 6.75rem;
    height: 6.75rem;
    position: absolute;
    left: -4.25rem;
    top: -1rem;
    opacity: 0.75;
    z-index: 99;

    & ~ .large-icon {
      left: 3rem;
    }

    & ~ .name,
    & ~ .type,
    & ~ .organization {
      padding-left: 8.5rem;
    }

    & + .help {
      position: absolute;
      z-index: 9;
      display: none;
      top: 0;
      left: 0;

      p {
        width: auto;
        margin-left: 3rem;
        margin-right: 3rem;
      }
    }

    &:hover + .help {
      display: block;
    }
  }
}

h1 + .bar {
  height: 2px;
  margin-top: 1px;
  margin-bottom: 1px;
  background-color: #53455f;
  padding: 0;
  z-index: 99;
}

@media #{$media-phone} {
  h1 {
    min-height: 3rem;
    padding: 0.1rem;

    svg.large-icon,
    img.large-icon {
      padding: 0;
      width: 2rem;
      height: 2rem;
      left: 0;
    }

    .type {
      padding: 0;
      height: 1rem;
      font: $header-type-font-mobile;
      padding-left: 3.5rem;
      padding-top: 0.1rem;

      > a {
        bottom: 0;
        font: $header-type-font-mobile;
      }

      > ul {
        bottom: 0;

        li {
          font: $header-type-font-mobile;
        }
      }

      &.no-organization {
        margin-top: 0.4rem;
      }
    }

    .name {
      font: $header-font-mobile;
      padding: 0;
      padding-left: 3.5rem;
      height: 1rem;
      position: relative;
      top: -0.15rem;

      a {
        font: $header-font-mobile;
      }
    }

    .large-icon + .name {
      padding-top: 0.75rem;
    }

    .organization {
      padding: 0;
      padding-left: 3.5rem;
      font: $object-sub-header-font-mobile;
    }

    &.person {
      .avatar {
        width: 3.25rem;
        height: 3.25rem;
      }

      .name,
      .type,
      .organization {
        padding-left: 4.25rem;
      }
    }

    svg.large-icon + .name:last-child,
    img.large-icon + .name:last-child {
      padding-top: 1.3rem;
    }

    .actions.dropdown-menu {
      button.actions-dropdown {
        img {
          width: 1rem;
          height: 1rem;
        }
      }
    }

    nav.dropdown-menu.media-tablet {
      button.nav-dropdown {
        svg,
        img {
          width: 1rem;
          height: 1rem;
        }
      }
    }

    .staged-icon {
      height: 5.75rem;

      &:hover {
        z-index: 99999999999;
      }

      & + .help {
        z-index: 99999999;
      }

      & ~ .name,
      & ~ .type,
      & ~ .organization {
        padding-left: 6.5rem;
      }
    }
  }
}
