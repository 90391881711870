@charset "UTF-8";

/**
 * The root stylesheet. Here, each other section of the stylesheet is loaded
 * from its own file. Each component is loaded in as it would be seen on the
 * page from top to bottom.
 */

@import "reset.scss";
@import "device.scss";
@import "font.scss";

// The application base styling.
@import "base.scss";

// Code syntax highlighting
@import "code.scss";

// The errors list.
@import "errors.scss";

// The flash list.
@import "flash.scss";

// Markdown generated content
@import "markdown.scss";

// Search sidebar
// TODO: move this to a more appropriate place.
@import "search.scss";

// The all-important Marketing "About" page
@import "about.scss";

// The top-bar navigation styling.
@import "topbar.scss";

// The footer styling.
@import "footer.scss";

// Help and Guidance styling
@import "help.scss";

// Form styling
@import "form.scss";

// The content structure styling.
@import "structure.scss";

// The content structure styling for the modal.
@import "modal.scss";

// The object header styling.
@import "header.scss";

// The tab styling.
@import "tabs.scss";

// The tab-bar styling.
@import "tab-bar.scss";

// The different cards.
@import "card.scss";

// The policies to which a user must consent.
@import "policy-box.scss";

// Configuration forms.
@import "configuration.scss";

// Checkbox sliders in modal forms
@import "subscription-list.scss";

// Sections

// Object Pages
@import "objects.scss";

// System Pages
@import "system.scss";

// Workflow Widget
@import "workflow.scss";

// Test Viewer
@import "tests.scss";

// Panels and Sidebars

// RunList
@import "run-list.scss";

// Social
@import "social.scss";

// Components

// Globe
@import "components/globe.scss";

// Buttons
@import "components/button.scss";
@import "components/button-selector.scss";

// The dropdown menu styling.
@import "components/dropdown.scss";

// Tooltips
@import "components/tooltip.scss";

// Selectors
@import "components/selector.scss";

// Auto-Complete Fields
@import "components/auto-complete.scss";

// Checkbox Sliders
@import "components/slider-checkbox.scss";

// Objects Container
@import "components/object-container.scss";

// Objects List
@import "components/object-list.scss";

// Objects Tree
@import "components/object-tree.scss";

// Objects Preview List
@import "components/object-preview-list.scss";

// Spinner (Loading Graphics)
@import "components/spinner.scss";

// Donut Visualization (Test Viewer)
@import "components/donut.scss";

// Calendar
@import "components/calendar.scss";

// The application embedded view styling overrides.
@import "embed.scss";

// Silly overrides for the styleguide page.
.kss-markdown h1,
.kss-header__title,
.kss-navigation__title,
.kss-section__item {
  background: transparent;
  height: auto;
  min-height: auto;
}

.kss-markdown code {
  padding: 0;
}

.kss-markdown pre {
  margin-bottom: 1.5rem;
}
