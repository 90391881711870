// Styles the object-tree widget

ol.object-tree {
  text-align: left;
  border: 1px solid #ccc;
  overflow: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  opacity: 1;
  transition: 0.3s opacity;
  box-sizing: border-box;

  &.full {
    height: 100%;
  }

  > li > ol.object-tree.object-sub-tree {
    border: none;
    max-height: none;
    height: auto;
    padding: 0;
    margin-left: 23px;
    overflow: visible;

    &.loading {
      height: 30px;
      background-position-x: 20px;
      background-size: 34px;
      transition: 0.3s opacity, 0.3s height;

      &[hidden] {
        height: 0;
      }
    }
  }

  li {
    line-height: 0;
    overflow: hidden;

    span {
      font: $object-tree-font;
      display: inline-block;
      height: 24px;
      line-height: 24px;
      max-height: 24px;
      vertical-align: middle;
      cursor: pointer;
      transition: 0.3s height, 0.3s max-height, 0.3s line-height;

      &.selected {
        background-color: red;
        color: blue;
      }
    }

    span.expand {
      position: relative;
      width: 24px;

      &.collapse {
        &::after {
          transform: rotate(90deg);
          border-left-color: #333;
          transition: 0.3s border-left-color, 0.3s transform;
        }
      }

      &::after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        left: 10px;
        top: 6px;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid #aaa;
        transform: rotate(0deg);
        transition: 0.3s border-left-color, 0.3s transform;
      }
    }

    span.icon {
      width: 24px;
      background-size: 20px;
      background-position: 4px 2px;
      margin-right: 5px;

      img {
        width: 24px;
        border-radius: 50%;
      }
    }

    span.name {
      padding: 0 5px;
      border-radius: 3px;
    }
  }

  &[hidden] {
    display: block !important;
    opacity: 0;
    transition: 0.3s opacity;

    li {
      span {
        height: 0 !important;
        max-height: 0 !important;
        line-height: 0 !important;
        transition: 0.3s height, 0.3s max-height, 0.3s line-height;
        overflow: hidden;
      }
    }

    ol.object-tree.loading,
    &.loading {
      height: 0;
      transition: 0.3s opacity, 0.3s height;
    }
  }
}

form > ol.object-tree,
form > .card > ol.object-tree {
  margin-left: 15px;
  box-sizing: border-box;
  width: 600px;
  max-width: calc(100% - 30px);
}
