/**
 * Styling for the configuration sidebar of the workflow editor and object runner.
 */

.sidebar.configuration {
  .configuration-panel {
    ul.panes {
      position: relative;
      flex: 1 1 auto;
      margin: 0;

      li.pane {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: none;
        overflow: visible;

        &.active {
          display: flex;
          flex-direction: column;
        }

        > ul {
          flex: 0 0 auto;
        }

        > .tab-panels {
          position: relative;
          flex: 1 1 auto;

          .tab-panel.active {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;

            > .configuration {
              z-index: 9;
              flex: 1 1 auto;
              overflow: hidden;
              position: relative;

              > .card {
                text-align: left;
                display: flex;
                flex-direction: column;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;

                > .configuration-nav {
                  flex: 0 0 auto;
                }

                > .configuration-search-options {
                  flex: 1 1 auto;
                  overflow: auto;
                }
              }
            }

            > .card:last-child {
              flex: 0 0 auto;
            }
          }
        }
      }
    }

    .help {
      overflow: auto;
      margin: 0;
      border-left: none;
      border-right: none;
    }

    .configuration-help {
      display: none;
      flex: 1 1 auto;
      position: relative;
      height: 100%;
      border-top: 1px solid white;
      font: $help-font;

      p {
        padding: 0 10px;
      }

      // Explicitly position the node/ports
      occam-workflow.mock {
        background: rgba(255, 255, 255, 0.75);

        ul.connections {
          li.connection {
            left: 30px;

            li.port.left:first-child {
              top: 30px;
            }

            li.port.left:last-child {
              top: 70px;
            }

            li.port.right {
              top: 50px;
            }

            .bottom-button {
              svg {
                fill: white;
              }
            }
          }

          .configure-button {
            @keyframes pulseHighlight {
              0% {
                background-color: white;
              }

              100% {
                background-color: #5999a6;
              }
            }

            animation: 0.8s infinite alternate pulseHighlight;

            &::before {
              filter: brightness(5);
            }
          }
        }
      }
    }

    .help.open ~ .configuration-help {
      border-top: none;
    }

    ul.panes:empty {
      display: none;

      & + .configuration-help {
        display: block;
      }
    }
  }
}
