/**
 * Styling for flash list (displays asynchronous messages.)
 */

body > ul.flash {
  position: absolute;
  top: 2rem;
  text-align: center;
  z-index: 999999999;
  width: 100%;
  box-sizing: border-box;
  height: 0;

  @media #{$media-phone} {
    top: 1.5rem;
  }

  li.flash {
    position: relative;
    text-align: center;
    margin: 0 auto;
    border-radius: 0.5rem;
    padding: 0.25rem;
    width: 20rem;
    max-width: 100%;
    box-shadow: 0 0 3px 1px black;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;

    @media #{$media-phone} {
      border-radius: 0;
      width: 100%;
    }

    &:not(:first-of-type) {
      margin-top: 0.5rem;

      @media #{$media-phone} {
        margin-top: 0;
      }
    }

    svg,
    img {
      width: 1.25rem;
      height: 1.25rem;
      position: absolute;
      left: 0.5rem;
      top: calc(50% - 0.625rem);
    }

    svg + p,
    img + p {
      padding-left: 2rem;
    }
  }
}
