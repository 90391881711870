$iframe-zoom: 0.5;

ul.object-preview-list {
  display: block;
  list-style: none;
  padding: 20px 20px;
  text-align: center;

  > li.object {
    border: none;
    display: inline-block;
    margin: 10px;
    padding: 0;
    position: relative;
    text-align: left;
    vertical-align: top;

    > a {
      display: block;
      text-decoration: none;
      padding: 0.5rem;
      border: 1px solid #ddd;
      border-radius: 0.5rem;

      .object-preview {
        width: 20rem;
        min-height: 4rem;
        max-width: 20rem;
        max-height: 20rem;
        position: relative;
        overflow: hidden;
        background-color: white;
        border: 1px solid #ddd;
        border-radius: 0.35rem;
        box-sizing: border-box;
        margin-top: 0.5rem;

        &.widget {
          height: 16rem;
        }

        img,
        iframe {
          display: block;
          width: 100%;
          box-sizing: border-box;
          pointer-events: none;
        }

        iframe {
          -moz-transform: scale($iframe-zoom);
          -moz-transform-origin: 0 0;
          -o-transform: scale($iframe-zoom);
          -o-transform-origin: 0 0;
          -webkit-transform: scale($iframe-zoom);
          -webkit-transform-origin: 0 0;
          transform: scale($iframe-zoom);
          transform-origin: 0 0;
          position: absolute;
          width: 600px;
          height: 400px;
        }
      }

      .object-header {
        width: 17.5rem;
        height: 2rem;
        position: relative;
        box-sizing: border-box;
        margin-right: 2.5rem;

        > svg,
        > img {
          position: absolute;
          width: 2rem;
          height: 2rem;
          left: 0;
          top: 0;
        }

        > h2 {
          position: absolute;
          top: 0;
          display: block;
          padding: 0;
          padding-left: 2.5rem;
          font: $workflow-label-type-font;
          white-space: nowrap;
          overflow: hidden;

          span.subtype {
            margin-left: 0.25rem;

            &::before {
              content: '[';
              color: #ccc;
            }

            &::after {
              content: ']';
              color: #ccc;
            }
          }
        }

        > p {
          display: block;
          position: absolute;
          bottom: 0;
          padding: 0;
          padding-left: 2.5rem;
          font: $workflow-label-name-font;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 0.5rem;
          width: calc(100% - 2.5rem);
        }

        form {
          display: inline-block;
          position: absolute;
          top: calc(50% - 0.75rem);
          right: 0.5rem;

          button {
            cursor: pointer;
            border: 1px solid #c7c7c7;
            border-radius: 50%;
            width: 1.5rem;
            height: 1.5rem;
            background-color: #96cfdb;

            svg,
            img {
              width: 1rem;
              height: 1rem;
              position: absolute;
              left: 0.25rem;
              top: 0.25rem;
            }

            &:hover {
              background-color: #2da0b7;
            }
          }
        }
      }

      &:hover,
      &:focus,
      &.focus {
        border: 1px solid #ccc;
      }
    }

    .dropdown-menu {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;

      button.dropdown {
        cursor: pointer;
        height: 2rem;
        margin: 0;
        width: 2rem;

        svg {
          height: 1rem;
          width: 1rem;
          vertical-align: middle;
        }

        & + ul.dropdown-menu-options.options {
          right: 0;

          li {
            border: none;

            a,
            button,
            input {
              height: auto;
            }
          }
        }

        &[disabled] {
          cursor: default;
          pointer-events: none;
        }
      }

      button ~ button.actions-dropdown {
        top: -1px;
        position: relative;
      }
    }

    &[data-has-preview] {
      a .object-header {
        border-bottom: 1px solid #ddd;
        height: 2.5rem;

        p {
          bottom: 0.5rem;
        }
      }
    }
  }
}
