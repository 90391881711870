/**
 * Tooltip styling.
 */

// Tooltip
//
// Markup
// <div class="tooltip reveal" style="display: inline-block; position: relative"><span>Tooltip Content</span><div class="before"></div><div class="after"></div></div>
//
// Styleguide Components.tooltip
.tooltip {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  font: $tooltip-font;
  border-radius: 5px;
  padding: 6px 10px;
  text-align: center;
  border: 1px solid blue;
  z-index: 9999999999;
  white-space: nowrap;

  &.reveal {
    @keyframes tooltip-reveal {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    animation: tooltip-reveal 0.3s;
    opacity: 1;
  }

  .before {
    content: '';
    width: 7px;
    height: 7px;
    position: absolute;
    left: calc(50% - 3px);
    bottom: -4px;
    transform: rotateZ(45deg);
    box-shadow: 0 0 3px 1px #111;
  }

  .after {
    content: '';
    width: 14px;
    height: 8px;
    position: absolute;
    left: calc(50% - 7px);
    bottom: -1px;
    border-bottom: 1px solid blue;
  }

  &.below {
    .before {
      bottom: auto;
      top: -4px;
    }

    .after {
      bottom: auto;
      top: -1px;
      height: 4px;
      border-bottom: none;
      border-top: 1px solid blue;
    }
  }
}
