/**
 * Card styling.
 *
 * Cards are sections of the site that are usually blocks of information.
 *
 * Sometimes, cards are the only section and sometimes that are grids of
 * information.
 *
 * It may make sense to place them in a list or grid or a smaller size or
 * scrollable when at a different device width (like a phone, etc) and
 * different classes can be applied to hint at which behavior is appropriate.
 */

// A basic card holds content
.card {
  text-align: center;
  position: relative;

  > .submit {
    overflow: hidden;
  }

  > p {
    padding-left: 1rem;
    padding-right: 1rem;
    box-sizing: border-box;

    + p {
      padding-top: 0.5rem;
    }

    &.warning {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  > ul:not(.tabs):not(.tab-panels):not(.card) {
    padding-left: 1rem;
    padding-right: 1rem;
    box-sizing: border-box;
  }

  > ul.facets.subscriptions {
    display:inline-block;
  }
  
  > input:last-child:not(.button),
  > ul:last-child:not(.tab-panels):not(.tabs):not(.card),
  > p:last-child {
    padding-bottom: 1rem;
  }

  > p + ul:not(.tabs):not(.tab-panels):not(.card) {
    padding-top: 1rem;
  }

  > ul:not(.tabs):not(.tab-panels):not(.card) + ul:not(.tabs):not(.tab-panels) {
    padding-top: 1rem;
  }

  > p:first-child {
    padding-top: 1rem;
  }

  // Cards that are in the process of loaded their content
  &.loading {
    min-height: 5rem;
  }

  &.full {
    height: 100%;
    margin: 0;
    padding-bottom: 0;
  }

  // When the card is empty
  &.none {
    padding: 1rem;

    svg.none-icon,
    img.none-icon {
      width: 20rem;
      aspect-ratio: 1 / 1;
      max-width: 80%;
      padding-bottom: 2rem;

      &.small {
        width: 10rem;
        padding-bottom: 1rem;
      }
    }

    > p {
      color: #888;
    }
  }

  > ul:not(.tabs):not(.tab-panels):not(.card) + p {
    padding-top: 1rem;
  }

  // All headers for the card are styled and divide content
  > h2 {
    font: $sub-header-font;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;

    // Offscreen headers are there for screen reader purposes, but are not
    // visible or affect positioning.
    &.offscreen {
      position: absolute;
      clip: rect(1px 1px 1px 1px); /* for Internet Explorer */ /* stylelint-disable-line */
      clip: rect(1px, 1px, 1px, 1px);
      padding: 0;
      border: 0;
      height: 1px;
      width: 1px;
      overflow: hidden;
    }

    &.has-help {
      padding-bottom: 0.25rem;
      overflow: visible;

      + .help + * {
        margin-top: 0.25rem;
      }
    }
  }

  > p + h2 {
    padding-top: 1.5rem;
  }

  > h2:first-child {
    padding-top: 1rem;
  }

  // A Flex card holds flexed content
  // A filled card expands within a flexed content
  // Generally, all flex cards are filled cards
  &.flex {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    overflow: hidden;

    &.filled {
      flex: 1 1 auto;
    }

    &.center-content {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    &.scrollable {
      overflow: auto;
    }

    &.horizontal {
      flex-direction: row;
      box-shadow: none;

      &.responsive-fit {
        @media #{$media-fit} {
          flex-direction: column;
        }
      }

      > .card:not(.horizontal) {
        margin-top: 0.125rem;
        box-shadow: none;

        &:not(:first-child) {
          margin-left: 0.125rem;
        }
      }

      > .card.horizontal:not(:first-child) {
        padding-left: 0.125rem;
      }

      @media #{$media-tablet} {
        flex-direction: column;

        > .card:not(.horizontal) {
          &:not(:first-child) {
            margin-left: 0;
          }
        }

        > .card.horizontal {
          padding-left: 0;
        }
      }
    }

    // Headers don't flex, thanks!
    > h2 {
      flex: 0 0 auto;

      &:not(.has-help) {
        overflow: hidden;
      }
    }

    // Tab panels will flex their contents as well
    > ul.tab-panels {
      flex: 1 1 auto;
      display: flex;

      &.card.filled {
        overflow: hidden;
      }

      > li.tab-panel {
        flex: 1 1 auto;
      }
    }

    // "Separator Resizable" cards grow
    > .card.separator-resize {
      flex: 1 1 auto;
    }

    > .card.filled {
      flex: 1 1 auto;
      overflow: auto;
      overflow-x: hidden;

      &.scrollable {
        overflow: auto;
      }
    }

    // All immediate content is flexed
    // It does not grow
    // It has scrollbars when it shrinks
    > *:not(h2):not(.filled):not(.separator-resize):not(.sidebar) {
      flex: 0 0 auto;
    }

    // The last item in the flex content will grow
    &:not(.center-content) > *:last-child:not(h2):not(.filled):not(.separator-resize):not(.sidebar):not(.submit) {
      flex: 1 1 auto;

      // Ensures that weird sizing rules don't leave a gap at the end of the scrollable section.
      // Apparently rem sizing sizes some things very strangely relative to scrollHeight.
      // (This is reset in the theme under base.general.content to color it the same as a card.)
      &:not(.horizontal) {
        box-shadow: 0 2px 0 2px magenta;
      }
    }
  }

  > ul:not(.tabs):not(.tab-panels):not(.card) + h2 {
    padding-top: 1.5rem;
  }

  // Cards that follow have a small gap (tab-panels are an exception)
  // The .content element concerns the background and thus color of this gap
  &:not(.sidebar):not([hidden]) ~ .card:not(.tab-panel):not(.transparent) {
    margin-top: 0.125rem;
  }

  > h3 {
    box-sizing: border-box;
    font: $sub-sub-header-font;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    z-index: 99999;
    margin-top: 0.5rem;
    position: relative;

    &:first-child {
      margin-top: 1rem;
    }

    &.has-help {
      padding-bottom: 0.25rem;

      + .help + * {
        margin-top: 0.25rem;
      }
    }
  }

  > form + h3,
  > ul:not(.tabs):not(.tab-panels):not(.card) + h3 {
    padding-top: 1rem;
  }

  &.file-listing-container,
  &.sidebar {
    // Sidebar/configuration headers
    > h2:first-child,
    > .card > h2:first-child {
      height: 2.5rem;
      padding-top: 0.625rem;
      overflow: visible;

      &.has-help {
        // TODO: rtl locales?
        padding-right: 2.125rem;

        > span.label {
          display: inline-block;
          box-sizing: border-box;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
