/**
 * Styling for any configuration form.
 */

// TODO: theme colors

occam-configuration {
  padding: 0;
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  & > ul.configuration-group {
      // Always show it
      display: block;
      padding: 1rem;
      flex: 1 1 100%;
      overflow: auto;
  }

  // A group of configuration items
  ul.configuration-group {
    width: auto;
    line-height: 1.25rem;

    .configuration-item-actions {
      max-width: 60rem;
      width: 100%;
      margin: 0 auto;
    }

    // Each configuration item
    // (.configuration-array-actions is a special panel for the add button
    // and description for a complex array)
    div.configuration-array-actions,
    li.configuration-item {
      position: relative;
      flex-direction: row;
      display: flex;
      max-width: 60rem;
      margin: 0 auto;

      h2 ~ ul.configuration-array-items,
      h2 ~ div.configuration-array-actions,
      h2 ~ ul.configuration-group {
        // We don't show things unless expanded
        display: none;
      }

      &.configuration-item-array {
        > .configuration-array {
          > .configuration-group {
          }

          > button.array-add {
          }
        }
      }

      &.configuration-item-group {
        display: block;
        margin-top: 1rem;

        h2 {
          cursor: pointer;
          user-select: none;

          &::before {
            content: '';
            border-left: 0.4rem solid #103942;
            border-top: 0.25rem solid transparent;
            border-bottom: 0.25rem solid transparent;
            height: 0;
            width: 0;
            display: inline-block;
            margin-right: 0.4rem;
            position: relative;
            top: -0.125rem;
            transition: 0.5s transform;
          }

          &:hover::before {
            border-left-color: magenta;
          }

          &::after {
            content: '...';
            padding: 0.125rem;
            border-radius: 0.5rem;
            background-color: white;
            margin-left: 0.5rem;
            color: #ddd;
            font-weight: bold;
            height: 0.5rem;
            line-height: 0;
            vertical-align: middle;
            display: inline-block;
            border: 0.0625rem solid #ddd;
            margin-bottom: 0.125rem;
          }

          &:hover::after {
            border-color: magenta;
            color: magenta;
          }
        }

        &.configuration-item-disabled {
          h2 ~ ul.configuration-array-items,
          h2 ~ div.configuration-array-actions,
          h2 ~ ul.configuration-group {
            // We also don't show things if the group is 'disabled'
            display: none;
          }

          h2 {
            opacity: 0.5;
          }
        }

        ul.configuration-group {
          margin-left: 0;
          padding-left: 0.25rem;
          border-left: 0.25rem solid #ddd;
          margin-top: 0.25rem;
        }

        & + div.configuration-item-editor + li.configuration-item,
        & + li.configuration-item {
          margin-top: 0.75rem;
        }

        // Certain expandable items have a header and blocks to hide/show
        // (Unless the group was disabled somehow... then it cannot be expanded)
        &.expanded:not(.configuration-item-disabled) {
          h2 {
            ~ div.configuration-array-actions {
              // Show the array buttons
              display: flex;
            }

            ~ ul.configuration-array-items,
            ~ ul.configuration-group {
              // Show the group or array elements
              display: block;
            }

            &::before {
              transform: rotate(90deg);
            }

            &::after {
              display: none;
            }
          }
        }
      }

      button.expand-editor,
      button.expand {
        border: none;
        outline: none;
        background-color: #fff;
        color: #aaa;
        cursor: pointer;
        position: relative;
        z-index: 1;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        font-size: 0.5rem;
        top: 0;

        svg {
          width: 0.75rem;
          height: 0.75rem;
          display: inline-block;
          fill: #d38ad3;
          opacity: 0.25;
          vertical-align: bottom;

          &:hover {
            opacity: 1;
          }
        }

        &.open svg {
          opacity: 1;
        }
      }

      > .label {
        flex: 1 0 auto;
        position: relative;
        max-width: calc(100% - 14rem);

        // The label for the item
        > label {
          color: hsl(190, 30%, 35%);
          font-family: "Open Sans", sans-serif;
          margin: 0;
          margin-left: 0.0625rem;
          padding-top: 0;
          position: relative;
          z-index: 1;
          font-size: 0.75rem;
          display: inline-block;
          padding-bottom: 0.25rem;
          background-color: #fff;

          &.no-description {
            padding-right: 0.25rem;
          }

          // When an input is being hovered over
          &.inspecting {
            color: hsl(190, 30%, 55%);

            &::before {
              content: '';
              width: 0.25rem;
              background: hsl(190, 30%, 55%);
              height: 1.0625rem;
              position: absolute;
              left: -0.5625rem;
              top: -0.0625rem;
              border-top: 0.125rem solid white;
              border-bottom: 0.125rem solid white;
            }
          }
        }

        // There are ... dots to help guide eyes between labels and values
        > span.configuration-dots {
          display: block;
          position: absolute;
          z-index: 0;
          top: 0.25rem;
          height: 0.5rem;
          width: calc(100% - 1rem);
          margin: 0;
          border-bottom: 0.0625rem dotted #ccf;
          border-left: 0.0625rem dotted #ccf;
          clear: both;
          border-bottom-left-radius: 0.5rem;
          margin-bottom: -0.5rem;
        }
      }

      > .value {
        flex: 0 0 14rem;
        width: 14rem;

        ul.configuration-tuple-items {
          width: 100%;
          position: relative;
          display: flex;
          flex-direction: row;

          > li.configuration-tuple-item {
            flex: 1 1 0%;
          }
        }

        // Possible configuration values
        ul.configuration-array-items,
        button.array-add,
        span.number {
          width: 100%;
          position: relative;
          display: inline-block;

          // Browsers do strange things when the array list is empty with
          // respect to the array add button, so we just take it out of the
          // browser flow in this case.
          &:empty {
            display: none;
          }

          > .configuration-array-item {
            > .value {
              position: relative;

              .configuration-input {
                padding-right: 1.5rem;
                text-align: left;
              }

              button.configuration-array-delete {
                width: 1rem;
                height: 1rem;
                position: absolute;
                right: 0.25rem;
                top: 0.25rem;
                border: none;
                cursor: pointer;

                svg {
                  width: 1rem;
                  height: 1rem;
                  fill: #ff9c9c;
                  display: inline-block;
                  position: absolute;
                  left: 0;
                  top: 0;
                }
              }
            }
          }
        }

        // Text inputs
        button,
        input {
          padding: 0 0.3125rem;
          margin: 0;
          position: relative;
          z-index: 1;
          height: 1.5rem;
          width: 100%;
          border: 1px solid #ccc;
          border-radius: 0.0625rem;
          background-color: #fff;
          outline: none;
          margin-bottom: 0.0625rem;
          font: $configuration-input-font;
          box-sizing: border-box;

          &:focus {
            outline: none;
          }

          &[disabled] {
            border-color: #eee;
          }
        }

        input[data-units] {
          + .units {
            position: absolute;
            pointer-events: none;
            right: 0.25rem;
            color: gray;
            z-index: 9;
            bottom: 0;
            height: calc(1.5rem - 2px);
            box-sizing: border-box;
            font: $configuration-input-font;
          }
        }

        // Boolean checkbox
        .slider-checkbox {
          position: relative;
          z-index: 1;
          width: 100%;
          display: inline-block;
          float: right;
          height: 1.5rem;
          display: flex;
          flex-direction: row;

          > .configuration-label-boolean {
            flex: 1 0 auto;
            font-family: "Open Sans", sans-serif;
            font-size: 0.75rem;
            color: #888;
            text-align: center;
          }

          > input[type=checkbox],
          > input.slider-input {
            position: absolute;
            left: 0;

            &:checked {
              ~ .configuration-label-boolean-on {
                color: #e987f7;
                font-weight: bold;
              }
            }
          }
        }

        // Dropdown (enum) types
        .select {
          display: inline-block;
          float: right;
          border-radius: 0.0625rem;
          position: relative;
          z-index: 1;
          width: 100%;
          height: 1.5rem;
          cursor: pointer;
          border: 1px solid #ccc;
          background: linear-gradient(to bottom, hsl(190, 0%, 95%) 5%, hsl(190, 0%, 90%) 100%);
          background-color: hsl(190, 0%, 40%);
          box-shadow: inset 0 0 1px 1px hsl(190, 60%, 100%);
          box-sizing: border-box;

          select {
            padding: 0;
            padding-left: 0.5rem;
            margin: 0;
            background: transparent;
            border: 0;
            border-radius: 0.125rem;
            -webkit-appearance: none;
            height: calc(1.5rem - 2px);
            width: 100%;
            box-sizing: border-box;
            cursor: pointer;
            outline: none;
            color: #333;

            &:active {
              outline: none;
            }

            &:focus {
              outline: none;
            }
          }

          &:hover {
            box-shadow: inset 0 0 2px 1px hsl(190, 76%, 52%);
          }

          &:active {
            background: linear-gradient(linear, left top, left bottom, color-stop(0.05, hsl(190, 0%, 95%)), color-stop(1, hsl(190, 0%, 90%)));
            background-color: hsl(190, 0%, 40%);
            outline: none;
          }

          &::before {
            pointer-events: none;
            background-image: url ('/images/ui/dropdown_arrow.png');
            background-repeat: no-repeat;
            background-position: 0 center;
            position: absolute;
            right: 0;
            display: block;
            content: '';
            width: 17px;
            top: 0;
            bottom: 0;
          }
        }
      }

      // When the item has a validation error
      &.configuration-error {
        > .label {
          label {
            color: red;
          }

          > span.configuration-dots {
            border-color: red;
          }

          > div.description {
            border-color: red;
          }
        }

        > .value {
          .configuration-error {
            box-shadow: inset 0 0 0.125rem 0.0625rem red;
          }
        }
      }

      &.configuration-item-disabled {
        > .label {
          > label {
            opacity: 0.5;
          }
        }
      }
    }

    // When filtering items, hide them until they are explicitly revealed
    &.filtering {
      li.configuration-item {
        display: none;

        &.filtered {
          display: flex;
        }
      }
    }

    // The configuration editor,
    // The description for the configuration item
    div.configuration-item-editor,
    div.description {
      color: #444;
      border: 0.0625rem solid #ddd;
      padding: 0;
      border-left-width: 0.25rem;
      margin-left: 0.125rem;
      margin-bottom: 0.75rem;
      background-color: transparent;
      white-space: normal;

      button.configuration-item-delete {
        margin: 0.25rem;
      }

      &.configuration-item-editor {
        text-align: right;
        max-width: 60rem;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        left: 0.125rem;
      }

      &.description {
        padding: 0.25rem 0.5rem;
        margin-right: 1rem;
        background: none;

        p {
          // TODO: add to theme
          font-size: 0.75rem;
          color: #333;

          + h1,
          + h2,
          + h3,
          + h4,
          + h5,
          + h6,
          + p {
            margin-top: 0.5rem;
          }
        }

        // Reset header styling
        h1, h2, h3, h4, h5, h6 {
          background: none;
          border: none;
          color: #333;
          position: relative;
          overflow: hidden;
          display: block;
          padding: 0;
          margin: 0;
          min-height: initial;
          font: 0.9rem "Open Sans", sans-serif;
          font-weight: bold;
          font-size: 1rem;
        }

        h2, h3, h4, h5, h6 {
          font-size: 0.75rem;
        }

        .description-content + .no-description {
          display: none;
        }

        // The 'No description' pane
        .description-content:empty + .no-description {
          display: block;

          p {
            font-style: italic;
            color: #888;
          }
        }
      }

      // For the description preview in the editor view
      .description {
        text-align: left;
        border: none;
      }

      .markdown-editor {
        border-bottom: 0.0625rem solid #ddd;
      }

      .editing-table {
        border-bottom: 0.0625rem solid #ddd;
        text-align: left;
      }

      .CodeMirror.cm-s-paper.CodeMirror-wrap {
        border-left: none;
        border-right: none;
        border-radius: 0;
      }

      ul.configuration-validations {
        background: none;

        &:not(:empty) {
          margin-top: 1rem;
        }

        li.configuration-validation {
          background: none;
          display: flex;
          flex-direction: row;

          svg {
            flex: 0 0 1rem;
            display: inline-block;
            width: 0.75rem;
            height: 0.75rem;
            margin-right: 0.5rem;
            margin-top: auto;
            margin-bottom: auto;
            background: none;
            fill: #4bab42;
          }

          svg.error {
            display: none;
            background: none;
            fill: red;
          }

          .configuration-validation-message {
            flex: 1 0 auto;
            width: 100%;

            p {
              color: #4bab42;
            }
          }

          &.error {
            svg {
              display: none;
            }

            svg.error {
              display: inline;
            }

            .configuration-validation-message {
              p {
                color: red;
              }
            }
          }
        }
      }
    }
  }

  /* Navigation bar */
  nav {
    background: #eee;
    box-shadow: 0 0 0.125rem 0 #333;
    position: relative;
    flex: 0 0 2rem;
    z-index: 9;
    line-height: 2rem;
    padding: 0 0.25rem;

    svg {
      width: 1rem;
      height: 1rem;
      vertical-align: middle;
      display: inline-block;
      fill: #cca6d7;
    }

    input {
      width: 8rem;
      height: 1.25rem;
      vertical-align: baseline;
      display: inline-block;
      border: 0.0625rem solid #ccc;
      font-family: "Open Sans", sans-serif;
      font-size: 0.75rem;
      box-sizing: border-box;
      padding-right: 1rem;

      &:placeholder-shown + button.configuration-search-clear {
        display: none;
      }
    }

    button.configuration-search-clear {
      width: 1rem;
      height: 1rem;
      border: none;
      background: none;
      padding: 0;
      margin: 0;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      margin-left: -1.25rem;

      svg {
        vertical-align: top;
      }
    }

    button.button {
      display: inline-block;
      height: 1.25rem;
      width: 1.25rem;
      position: relative;
      padding: 0;
      margin: 0;

      svg {
        vertical-align: top;
        top: 0.125rem;
        position: relative;
      }
    }
  }
}
