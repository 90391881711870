/* Font table for the site */

@charset "UTF-8";

$object-description-tags-font: 0.9rem "Alegreya Sans SC", serif;
$search-facets-label: 300 0.8rem "Open Sans", sans-serif;
$configuration-nav-font: 400 0.8rem "Open Sans", sans-serif;
$configuration-error-font: 700 0.7rem "Open Sans", sans-serif;
$configuration-input-font: 400 0.9rem "Open Sans", sans-serif;

/* Navigation */
$nav-link-font: 0.8rem "Open Sans", sans-serif;

/* Headers */
$header-font: 300 1.5rem "Lato", sans-serif;
$header-version-font: 300 0.7rem "Lato", sans-serif;
$header-font-embed: 300 0.9rem "Lato", sans-serif;
$header-font-mobile: 300 1rem "Lato", sans-serif;
$header-type-font: 600 0.9rem "Lato", serif;
$header-type-font-embed: 400 0.8rem "Marcellus SC", serif;
$header-type-font-mobile: 400 0.8rem "Marcellus SC", serif;
$sub-header-font: 300 1.2rem "Lato", serif;
$sub-sub-header-font: 300 1rem "Lato", serif;
$header-nav-font: 300 0.9rem "Lato", sans-serif;
$saving-flash-font: 300 0.9rem "Open Sans", sans-serif;
$error-flash-font: 300 0.9rem "Open Sans", sans-serif;

/* Social */
$social-comment-font: normal 0.9rem "Open Sans", sans-serif;
$social-name-font: bold 0.9rem "Lato", sans-serif;
$social-verb-font: 300 0.9rem "Lato", sans-serif;
$social-time-font: 300 0.9rem "Lato", sans-serif;
$social-actions-font: 300 0.7rem "Lato", sans-serif;

/* Footers */
$footer-font: 0.8rem "Open Sans", sans-serif;

/* Normal Content */
$paragraph-font: 0.9rem "Open Sans", sans-serif;

/* Objects */
$object-description-font: 300 0.9rem "Lato", sans-serif;
$object-sub-header-font: 400 0.9rem "Lato", serif;
$object-sub-header-font-mobile: 400 0.7rem "Lato", serif;
$object-type-font: 600 1rem "Lato", serif;

/* Form */
$form-input-font: 400 0.8rem "Open Sans", sans-serif;
$form-label-font: normal 1.1rem "Alegreya Sans SC", sans-serif;
$form-checkbox-font: 400 0.8rem "Open Sans", sans-serif;
$form-button-font: 300 0.8rem "Open Sans", sans-serif;

/* History view */
$history-message-font: 300 0.8rem "Lato", serif;
$history-author-font: bold 0.8rem "Lato", serif;
$history-date-font: 300 0.9rem "Lato", serif;

/* Main Tabs */
$tab-font: normal 0.78rem "Lato", sans-serif;
$tab-active-font: 1000 0.78rem "Lato", sans-serif;
$sub-tab-font: 300 1rem "Open Sans", sans-serif;

/* Terminals/Code Spans */
$code-font: 1rem "inconsolata", monospace;

/* Dashboard */
$workset-name-font: 300 1.4rem "Lato", sans-serif;
$workset-description-font: 300 1rem "Lato", sans-serif;

/* Workflow */
$workflow-label-name-font: 300 14px Lato, sans-serif;
$workflow-label-type-font: bold 12px Lato, sans-serif;
$workflow-input-name-font: 300 12px Lato, sans-serif;
$workflow-input-type-font: bold 10px Lato, sans-serif;
$workflow-context-menu-font: 300 0.9rem "Lato", sans-serif;

/* Help Bubbles */
$help-font: 400 0.9rem "Lato", sans-serif;

/* Results Data */
$key-font: 400 1rem "Lato", sans-serif;
$value-font: 400 1rem "Open Sans", sans-serif;
$supplement-font: 300 1rem "Open Sans", sans-serif;

/* Side list */
$side-list-font: $tab-font;
$side-list-active-font: $tab-active-font;

/* Directory View */
$directory-group-font: 600 0.8rem "Open Sans", sans-serif;
$directory-normal-font: 400 0.8rem "Open Sans", sans-serif;
$directory-size-font: 400 0.8rem "Open Sans", sans-serif;
$directory-caption-font: bold 0.8rem "Open Sans", sans-serif;
$directory-revision-font: 400 0.8rem "Open Sans", sans-serif;

/* Object Selection */
$object-selector-font: normal 1rem "Lato", sans-serif;

/* Test Viewer */
$test-viewer-header-font: 300 1rem "Open Sans", sans-serif;
$test-viewer-header-type-font: 600 0.8rem "Open Sans", sans-serif;
$test-viewer-method-font: normal 0.8rem "Open Sans", sans-serif;
$test-viewer-type-font: 600 0.8rem "Open Sans", sans-serif;
$test-viewer-story-font: 300 1rem "Open Sans", sans-serif;

/* File List */
$viewer-filetabs-activeTypeFont: bold 0.7rem "Lato", sans-serif;
$viewer-filetabs-activeNameFont: 300 0.9rem "Lato", sans-serif;
$viewer-filetabs-inactiveTypeFont: bold 0.7rem "Lato", sans-serif;
$viewer-filetabs-inactiveNameFont: 300 0.9rem "Lato", sans-serif;
$viewer-filetabs-hoverTypeFont: bold 0.7rem "Lato", sans-serif;
$viewer-filetabs-hoverNameFont: 300 0.9rem "Lato", sans-serif;

/* Admin Page */
$admin-option-font: 300 1rem "Lato", sans-serif;

/* Tooltips */
$tooltip-font: 600 0.8rem "Lato", sans-serif;

/* Object Tree */
$object-tree-font: 400 0.8rem "Open Sans", sans-serif;

/* Local fonts below */

@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local('Alegreya Sans SC'),
    local('AlegreyaSansSC-Regular'),
    url ('/fonts/alegreya/alegreya.woff2') format('woff2'),
    url ('/fonts/alegreya/alegreya.woff') format('woff');
}

@font-face {
  font-family: 'Cinzel Decorative';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local('Cinzel Decorative Bold'),
    local('CinzelDecorative-Bold'),
    url ('/fonts/cinzel/cinzel.woff2') format('woff2'),
    url ('/fonts/cinzel/cinzel.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src:
    //local('Lato Light'),
    //local('Lato-Light'),
    url ('/fonts/lato/Lato-Light.woff2') format('woff2'),
    url ('/fonts/lato/Lato-Light.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src:
    //local('Lato Regular'),
    //local('Lato-Regular'),
    url ('/fonts/lato/Lato-Regular.woff2') format('woff2'),
    url ('/fonts/lato/Lato-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src:
    //local('Lato Heavy'),
    //local('Lato-Heavy'),
    url ('/fonts/lato/Lato-Heavy.woff2') format('woff2'),
    url ('/fonts/lato/Lato-Heavy.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 1000;
  font-display: swap;
  src:
    //local('Lato Heavy'),
    //local('Lato-Heavy'),
    url ('/fonts/lato/Lato-Black.woff2') format('woff2'),
    url ('/fonts/lato/Lato-Black.woff') format('woff');
}

@font-face {
  font-family: 'Marcellus SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local('Marcellus SC'),
    local('MarcellusSC-Regular'),
    url ('/fonts/marcellus/marcellus.woff2') format('woff2'),
    url ('/fonts/marcellus/marcellus.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src:
    local('Open Sans Light'),
    local('OpenSans-Light'),
    url ('/fonts/opensans/open_sans_300.woff2') format('woff2'),
    url ('/fonts/opensans/open_sans_300.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local('Open Sans'),
    local('OpenSans'),
    url ('/fonts/opensans/open_sans_400.woff2') format('woff2'),
    url ('/fonts/opensans/open_sans_400.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src:
    local('Open Sans Semibold'),
    local('OpenSans-Semibold'),
    url ('/fonts/opensans/open_sans_600.woff2') format('woff2'),
    url ('/fonts/opensans/open_sans_600.woff') format('woff');
}

@font-face {
  font-family: 'Oxygen';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local('Oxygen'),
    local('Oxygen-Regular'),
    url ('/fonts/oxygen/oxygen.woff2') format('woff2'),
    url ('/fonts/oxygen/oxygen.woff') format('woff');
}

@font-face {
  font-family: 'inconsolata';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local('inconsolata'),
    url ('/fonts/inconsolata/inconsolata.woff2') format('woff2'),
    url ('/fonts/inconsolata/inconsolata.woff') format('woff');
}
