/**
 * Styling for the help "bubbles" and such.
 */

.card button.help-bubble,
.card a.help-bubble {
  font-size: 1rem;
  display: inline-block;
  vertical-align: middle;
  width: 1.125rem;
  height: 1.125rem;
  margin-left: 0.5rem;
  text-indent: -9999px;
  padding: 0 !important;
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  overflow: hidden;
  position: absolute;
  bottom: 0.45rem;

  svg,
  img {
    display: block;
    width: 1.125rem;
    height: 1.125rem;
  }

  img.hover {
    display: none;
  }

  &:hover {
    img {
      display: none;
    }

    img.hover {
      display: block;
    }
  }
}

.file-listing-container > .card > h2 > .help-bubble-container button.help-bubble,
.file-listing-container > .card > h2 > .help-bubble-container a.help-bubble,
.sidebar > .card > h2 > .help-bubble-container button.help-bubble,
.sidebar > .card > h2 > .help-bubble-container a.help-bubble,
.card h2.offscreen + .help-bubble-container button.help-bubble,
.card h2.offscreen + .help-bubble-container a.help-bubble {
  position: absolute;
  right: 0.5rem;
  top: 0.7rem;
  z-index: 9999999;
}

.card h3 button.help-bubble,
.card h3 a.help-bubble {
  bottom: 0.375rem;
}

.help {
  border: 1px solid red;
  border-top: 3px solid rgb(174, 133, 219);
  border-right: none;
  border-left: none;
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 0 1rem;
  position: relative;
  font: $help-font;
  min-height: 5rem;
  text-align: left;
  opacity: 1;
  -webkit-transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out, border-width 0.3s ease-in-out, margin-bottom 0.3s ease-in-out;
  -moz-transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out, border-width 0.3s ease-in-out;
  -ms-transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out, border-width 0.3s ease-in-out;
  -o-transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out, border-width 0.3s ease-in-out;
  transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out, border-width 0.3s ease-in-out;
  overflow: hidden;
  z-index: 99999;
  white-space: normal;
  margin-bottom: 0.25rem;

  @media #{$media-fit} {
    margin-left: 0;
    margin-right: 0;
  }

  > p.help-header {
    position: relative;
    height: 0;
    padding: 0;
    margin-top: 1rem;

    svg.icon,
    img.icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  &.closed {
    border-width: 0;
    margin-bottom: 0;
  }

  &:first-child {
    width: 100%;
    max-width: 100%;
    margin: 0;
    border-left: none;
    border-top: none;
    border-right: none;
    overflow: auto;
    flex: 0 0 auto;
  }

  > *:first-child {
    padding-top: 0.5rem;
  }

  > *:last-child {
    padding-bottom: 0.5rem;
  }

  ul > li,
  p {
    font: $help-font;
    width: 50rem;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;

    strong {
      font-weight: bold;
    }

    em {
      font-style: italic;
    }
  }

  > li,
  > p li,
  > p {
    font: $help-font;
    padding-left: 3rem;
    padding-right: 1rem;
    text-align: justify;
  }

  > p li,
  > ul li {
    list-style: disc;
    list-style-position: inside;
  }

  ul {
    width: 50rem;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    padding-left: 3.5rem;
    padding-right: 1rem;
  }

  > ul,
  > p ul {
    margin-top: 1rem;
  }

  * + p {
    margin-top: 1rem;
  }

  .delete {
    background-image: url ('/images/dynamic/hex/aaaaaa/icons/ui/remove.svg');
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 0 0;
    display: block;
    position: absolute;
    right: 2px;
    top: 0;
    height: 14px;
    width: 14px;
    color: white;
    text-align: center;
    padding: 0;
    line-height: 1rem;
    cursor: pointer;
    vertical-align: top;
    text-indent: -9999px;
    flex: 0 0 auto;

    &:hover {
      filter: brightness(50%);
    }
  }
}

.card .help-bubble-container {
  position: absolute;
  bottom: 0;

  &::before,
  &::after {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
  }

  &::before {
    content: '';
    position: absolute;
    top: -0.375rem;
    right: -1.625rem;
    width: 0;
    height: 0;
    background: transparent;
    border: none;
    z-index: 999999999;
    border-bottom: 0.5625rem solid #f5eafb;
    border-right: 0.5625rem solid transparent;
    border-left: 0.5625rem solid transparent;
  }

  &::after {
    content: '';
    position: absolute;
    top: -0.25rem;
    right: -1.5rem;
    width: 0.75rem;
    height: 0.75rem;
    transform: rotate(45deg);
    background: transparent;
    border: 0.0625rem solid #ae85db;
    z-index: 999999999;
    border-right: none;
    border-bottom: none;
  }

  &.open {
    &::before ,
    &::after {
      opacity: 1;
    }
  }
}

.file-listing-container > .card > h2 > .help-bubble-container,
.sidebar > .card > h2 > .help-bubble-container,
.card .offscreen + .help-bubble-container {
  position: absolute;
  top: 0;
  right: 0;

  &.with-sidebar {
    right: 3rem;
  }

  &::before {
    top: 2rem;
    right: 0.5rem;
  }

  &::after {
    top: 2.125rem;
    right: 0.625rem;
  }
}

// Do not show a top border when this is an 'offscreen' help bubble.
// Typically these are off of a tab strip and not next to a header.
.file-listing-container > .card > h2 + .help,
.sidebar > .card > h2 + .help,
.card .offscreen + .help-bubble-container + .help {
  margin: 0;
  width: 100%;
  max-width: 100%;
  border-left: none;
  border-right: none;
  overflow: auto;
  flex: 0 0 auto;
  z-index: 9999999;
  position: absolute;
  height: 100%;
  top: 2.5rem;
  border-top-width: 0.0625rem;
}

// Keep the top border when it is appearing underneath a tab-bar within a secondary tab panel.

ul.tab-bar + ul.tab-panels > li.tab-panel > .card > .offscreen + button.help-bubble + .help,
ul.tab-bar + ul.tab-panels > li.tab-panel > .card > .offscreen + a.help-button + .help {
  border-top: 3px solid rgb(174, 133, 219);
}

// This is for the help bubbles that highlight buttons or elements on tab-bars
ul.tab-bar {
  .help {
    &::after {
      content: '';
      position: absolute;
      transform: rotate(45deg);
      top: 0.6875rem;
      border-left: 0.0625rem solid red;
      border-bottom: 0.0625rem solid red;
      z-index: 99;
      width: 0.5rem;
      height: 0.5rem;
    }
  }

  li[hidden] + .help {
    display: none;
  }

  li:not([hidden]) + .help {
    position: absolute;
    width: auto;
    height: 2rem;
    min-height: auto;
    top: 0.25rem;
    border-width: 0.0625rem;
    border-style: solid;
    border-radius: 0.25rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    overflow: visible;

    p {
      width: auto;
      height: auto;
      padding-left: 0;
      padding-right: 0;

      svg {
        position: relative;
        width: 1rem;
        height: 1rem;
        margin-right: 0.125rem;
        top: -0.0625rem;
        left: -0.25rem;
        vertical-align: top;
        display: inline-block;
      }
    }

    &.left {
      &::after {
        left: -0.3125rem;
      }
    }

    &.right {
      &::after {
        right: 0;
      }
    }
  }
}
