.task-container {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  overflow: auto;
}

.task-pane {
  margin: auto;
  width: 100%;
  max-width: 50rem;
  z-index: 9;
}

.run-form {
  .loading {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
}

.task-form {
  width: 100%;
  display: table;

  .task-form-row {
    display: table-row;

    .task-form-header.label {
      width: 1px;
      display: table-cell;
      padding: 0.5rem;
      padding-left: 1rem;
      vertical-align: middle;
      text-align: right;

      h3 {
        text-align: right;
        white-space: nowrap;
        margin: 0;
        padding: 0;
        font: normal 1.5rem "Alegreya Sans SC", sans-serif;
        background: transparent;
        color: #fff;

        &.break {
          white-space: normal;
        }

        .info {
          display: inline-block;
          text-align: right;

          svg,
          img {
            position: relative;
            vertical-align: bottom;
            bottom: 0.125rem;
            width: 1rem;
            height: 1rem;
            margin-left: 0.5rem;
            z-index: 9;
          }

          span.environment {
            display: block;
            font: normal 1.1rem "Alegreya Sans SC", sans-serif;
          }

          span.architecture {
            display: block;
            font: normal 1.1rem "Alegreya Sans SC", sans-serif;
          }
        }
      }
    }

    // The settings block
    .task-form-cell.settings {
      padding: 0.5rem;
      text-align: right;

      label.slider-checkbox {
        color: white;
        font: 300 0.9rem "Lato", sans-serif;
        line-height: 1.25rem;
      }
    }

    .task-form-cell.task-form-item {
      position: relative;
      padding: 0.5rem;
      cursor: pointer;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none;   /* Safari */
      -khtml-user-select: none;    /* Konqueror HTML */
      -moz-user-select: none;      /* Firefox */
      -ms-user-select: none;       /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                            supported by Chrome and Opera */

      .task-form-dispatch,
      .task-form-object {
        position: relative;
        border: 2px solid magenta;
        padding: 1rem 0.5rem;
        min-height: 6rem;
        box-sizing: border-box;

        > .info > svg.icon,
        > .info > img.icon,
        > svg.icon,
        > img.icon {
          position: relative;
          width: 3.25rem;
          height: 3.25rem;
          vertical-align: middle;
          padding-right: 1rem;
          z-index: 9;
          padding-top: 0.325rem;
          padding-bottom: 0.325rem;
        }

        span.name {
          font: $header-font;
          color: #eee;
          position: relative;
          z-index: 9;
          vertical-align: middle;
          border-bottom: 1px dashed #eee;
        }

        .info {
          vertical-align: middle;
          text-align: left;
          display: inline-block;

          span.type {
            display: block;
            font: $object-type-font;
            color: #eee;
            position: relative;
            z-index: 9;
          }

          span.name {
            display: block;
            font: $header-font;
            color: #eee;
            position: relative;
            z-index: 9;
            border-bottom: 1px dashed #eee;
          }

          span.type + span.name {
            border-bottom: none;
          }
        }

        .info.path-info {
          margin-left: 1rem;

          img.arrow {
            height: 3rem;
            margin-right: 1rem;
            vertical-align: middle;
          }

          span.path {
            padding: 0.5rem;
            border-radius: 0.5rem;
            color: #333;
            font: 300 1.1rem "Lato", sans-serif;
            background-color: magenta;
          }
        }

        .task-form-query {
          cursor: default;
          overflow: hidden;
          height: auto;
          transition: height 0.3s;
          position: relative;
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          background-color: blue;

          .selector {
            margin-top: 1rem;
          }

          ul.input-list {
            display: table;
            width: 100%;

            > li.input {
              display: table-row;
              position: relative;

              > .object-header {
                border-right: 0.0625px dashed #ccc;
                display: table-cell;
                max-width: 10rem;
                padding-bottom: 0.5rem;
                padding-top: 0.5rem;
                padding-right: 1rem;
                vertical-align: middle;
                width: 1%;

                svg {
                  fill: #846585;
                  height: 4rem;
                  margin-bottom: 0.5rem;
                  width: 4rem;
                }

                > p {
                  overflow: hidden;
                  text-overflow: ellipsis;

                  &.type {
                    font: 600 0.75rem "Lato", serif;
                  }

                  &.name {
                    font: 300 1rem "Lato", sans-serif;
                  }
                }
              }

              > .objects-container {
                display: table-cell;
                padding-bottom: 0.5rem;
                padding-top: 0.5rem;
                padding-left: 1rem;
                position: relative;
                vertical-align: middle;

                > ul.objects {
                  justify-content: center;

                  > li.object {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }

          .objects-container {
            position: absolute;
            left: 0.0625rem;
            right: 0.0625rem;
            top: 0;
            padding-top: 1rem;
            margin: 0;
            border: none;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;

            &:first-child {
              margin: 0;
            }

            ul.objects {
              border-bottom: none;

              li.object:last-child {
                margin-right: 0;
              }
            }
          }

          h4 + .objects-container {
            top: 2.4rem;
          }

          h4 {
            font: 300 1.2rem "Lato", sans-serif;
          }

          &.reveal {
            height: 0 !important;
          }

          > *:first-child {
            margin-top: 1.5rem;
          }

          > *:last-child {
            margin-bottom: 0.5rem;
          }

          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0.5rem;
            width: 100%;
            border: 1px solid magenta;
            box-sizing: border-box;
            transition: background-color 0.3s;
          }

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            border-bottom: 1px solid magenta;
            height: 0.5rem;
          }

          button.collapse {
            display: inline-block;
            overflow: hidden;
            text-indent: -9999px;
            width: 1.9375rem;
            height: 1.9375rem;
            margin-top: 0.5rem;
            border-radius: 1rem;
            cursor: pointer;
            border: none;
            background-color: #fff;
            border: 0.0625rem solid #ddd;
            position: relative;

            svg,
            img {
              position: absolute;
              width: 1rem;
              height: 1rem;
              top: 0.4375rem;
              left: 0.4375rem;
            }

            &:first-child {
              margin-top: 1rem;
            }
          }
        }

        .objects-container + button.collapse {
          margin-top: 12rem;
        }

        .objects-container ~ p {
          margin: 0 0.5rem;
          margin-top: 12rem;
          border: 0.0625rem solid #ddd;
          border-radius: 0.25rem;
          text-align: left;
          color: black;
          padding: 0.25rem 0.5rem;
        }

        .objects-container ~ p.none {
          display: none;
        }

        .objects-container ~ p:empty {
          display: none;

          & ~ p.none {
            display: block;
          }
        }

        &.loading {
          background-image: url ('/images/dynamic/hex/eeeeee/indicators/object.svg');
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: 10%;

          svg.icon,
          img.icon {
            display: none;
          }

          .info {
            span.type,
            span.name,
            p {
              display: none;
            }
          }

          .task-form-query {
            display: none;
          }
        }
      }

      .task-form-inner-cell {
        border-top: none;
        padding-top: 0.5rem;
        min-height: 5rem;

        .info::before {
          content: "";
          position: absolute;
          left: calc(50% - 1.125rem + 2px);
          top: calc(-0.375rem + 1px);
          width: 0.8125rem;
          height: 0.75rem;
          background-color: #848484;
          border-top: 2px solid magenta;
          transform: rotate(45deg);
        }

        .info::after {
          content: "";
          position: absolute;
          right: calc(50% - 1.125rem + 3px);
          top: calc(-0.375rem + 2px);
          width: 0.75rem;
          height: 0.75rem;
          background-color: blue;
          border-left: 2px solid magenta;
          transform: rotate(45deg);
        }
      }

      .task-form-object {
        &::before {
          content: "";
          width: 0.5rem;
          height: 0.5rem;
          left: calc(50% - 0.25rem - 2px);
          bottom: calc(-0.25rem - 3px);
          position: absolute;
          transform: rotate(45deg);
          background-color: blue;
          border: 2px solid magenta;
          border-top: none;
          border-left: none;
        }

        &::after {
          content: "";
          width: 0;
          height: 0;
          left: calc(50% - 0.1875rem - 1px);
          bottom: -0.1875rem;
          position: absolute;
          border-top: 0.1875rem solid magenta;
          border-left: 0.1875rem solid transparent;
          border-right: 0.1875rem solid transparent;
        }
      }

      &::before {
        content: "";
        background-color: blue;
        border: 2px solid magenta;
        border-bottom: none;
        border-left: none;
        right: 0.5rem;
        top: 0;
        width: calc(50% - 1.25rem + 2px);
        height: 1rem;
        position: absolute;
        display: block;
      }

      &::after {
        content: "";
        background-color: blue;
        border: 2px solid magenta;
        border-bottom: none;
        border-right: none;
        left: 0.5rem;
        top: 0;
        width: calc(50% - 1.25rem);
        height: 1rem;
        position: absolute;
        display: block;
      }

      // I hate this, too
      &.active {
        &::before,
        &::after {
          transition: background-color 0.3s;
        }

        .task-form-object,
        .task-form-dispatch {
          transition: background-color 0.3s;

          &::before {
            transition: background-color 0.3s;
          }

          &::after {
            transition: background-color 0.3s;
          }

          .task-form-query {
            &::after {
              transition: background-color 0.3s;
            }
          }

          .info::before,
          .info::after {
            transition: background-color 0.3s;
          }
        }
      }
    }

    .task-form-cell.viewing-object + .task-form-header.label.running-object,
    .task-form-header.label.supplemental-object {
      padding-bottom: 1rem;
    }

    // The last item in the task list (should be the submit button/actions)
    &:last-child {
      // The last item in the task cell should be the submit cell
      .task-form-cell:last-child {
        padding: 0.5rem;
        padding-top: 0;

        .submit {
          border: 2px solid magenta;
          padding: 0.5rem;
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;

          input.button {
            margin: 0;
            width: 10rem;
          }
        }
      }
    }

    // The first block after the settings block
    &.settings {
      + .task-form-row {
        .task-form-cell {
          padding-top: 0;

          &::before,
          &::after {
            display: none;
          }

          .task-form-inner-cell {
            padding-top: 1rem;
            border-top: 2px solid magenta;
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;

            .info {
              &::before,
              &::after {
                display: none;
              }
            }
          }
        }
      }
    }

    // Hovering over a task item
    .task-form-cell.task-form-item:hover:not(.active) {
      &::before,
      &::after {
        transition: background-color 0.3s;
      }

      .task-form-object,
      .task-form-dispatch {
        transition: background-color 0.3s;

        &::before {
          transition: background-color 0.3s;
        }

        &::after {
          transition: background-color 0.3s;
        }

        .task-form-query {
          &::after {
            transition: background-color 0.3s;
          }
        }

        .info::before,
        .info::after {
          transition: background-color 0.3s;
        }
      }
    }
  }

  // Add margin to the top of the form
  .task-form-row.viewing-object,
  .task-form-row.running-object {
    .task-form-cell.task-form-item {
      margin-top: 1rem;
    }
  }

  .task-form-row.viewing-object {
    + .task-form-row.running-object {
      .task-form-cell.task-form-item {
        margin-top: 0;
      }
    }
  }

  @media #{$media-phone} {
    display: block;

    .task-form-row {
      display: block;

      .task-form-header,
      .task-form-header.label {
        display: block;
        width: 100%;
        box-sizing: border-box;
        text-align: left;
        padding-top: 0;
        padding-bottom: 0;

        h3 {
          text-align: left;
          position: relative;
          padding-left: 0.5rem;
          font: normal 1.1rem "Alegreya Sans SC", sans-serif;

          .info {
            position: absolute;
            right: 0;
            top: 0;

            span.environment,
            span.architecture {
              display: inline-block;
            }

            span + span {
              padding-left: 0.5rem;
            }
          }
        }
      }

      .task-form-cell:not(.task-form-header),
      .task-form-cell:not(.task-form-header).task-form-item {
        display: block;
        padding-bottom: 0;
        padding-top: 0;

        &::before,
        &::after {
          content: none;
        }

        .task-form-inner-cell {
          border-top: 2px solid magenta;
          padding-top: 1rem;

          .info {
            &::before,
            &::after {
              content: none;
            }
          }
        }
      }

      &.supplemental-object,
      &.viewing-object + .running-object {
        .task-form-header.label {
          padding-bottom: 0;
        }
      }

      &.submit {
        padding-top: 0.5rem;
      }
    }
  }
}
