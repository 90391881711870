/**
 * Styling for the social sidebar.
 */

// Comment styling
@import "social/comment.scss";

.social.sidebar {
  text-align: left;

  > .card {
    width: 100%;
  }

  /* Prompts a non-logged in person to log in to post comments. */
  p.login-prompt {
    margin: 0.5rem;
    padding: 0.5rem !important;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
  }

  /* The no-comments description is not visible by default. */
  p.no-comments {
    display: none;
  }

  form.edit-field,
  form.edit-box,
  form.comment-field,
  form.reply-box {
    margin-top: 10px;
    line-height: 0;

    .button {
      margin-top: 0;
    }

    label {
      font: $social-comment-font;
      margin-left: 0.5rem;
    }

    textarea {
      /* Keep the textarea from being resized horizontally */
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      padding: 0.5rem;
      box-sizing: border-box;
      border: 1px solid #eaeaea;

      /* The starting height */
      min-height: 5rem;
      height: 0;
    }
  }

  .card {
    text-align: left;

    > h2 {
      padding-top: 1rem;
      margin-bottom: 0.5rem;
    }

    > form {
      margin-top: 0;

      & > textarea {
        border: none;
        border-bottom: 1px solid #eaeaea;
        border-top: 1px solid #eaeaea;
        padding: 0.5rem;
      }

      > .dropdown-menu {
        display: inline;
      }
    }
  }

  // Markdown styling.
  .comment .content {
    background: white;
    font: $social-comment-font;

    p {
      font: $social-comment-font;

      &.comment-note {
        font: inherit;
        font-style: italic;
        color: #ddd;
      }
    }

    h1 {
      font: inherit;
      margin-top: 10px;
      margin-bottom: 10px;
      min-height: 0;
      background-color: inherit;
      background-image: none;
      color: inherit;
      font-size: 1.4em;
      font-weight: 1000;
    }

    h2 {
      margin-top: 10px;
      margin-bottom: 10px;
      font: inherit;
      color: inherit;
      font-size: 1em;
      font-weight: 1000;
    }

    h3 {
      font: inherit;
      color: inherit;
      font-size: 1em;
      text-decoration: underline;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    ul {
      font: inherit;
      list-style-type: disc;
    }

    ol {
      font: inherit;
      list-style-type: decimal;
    }
  }

  ul.comments {
    margin-top: 20px;
    padding-bottom: 20px;
    padding-left: 7px;
    padding-right: 7px;

    &:empty {
      padding: 0;
      margin: 0;
    }

    form.edit-box {
      display: block;

      textarea {
        min-height: 0;
        height: 0;
        padding: 0.5rem;
      }

      &.revealed {
        textarea {
          @keyframes reply-expand {
            0% {
              height: 0;
            }

            100% {
              height: 5rem;
            }
          }

          animation-duration: 0.5s;
          animation-name: reply-expand;
          height: 5rem;
        }
      }
    }

    span.replies-link {
      margin-top: 10px;
      padding-left: 15px;
      padding-bottom: 0;
      border-left: 5px solid #eee;
    }

    /* Inline threading */
    ul.comments {
      margin-top: 10px;
      padding-left: 15px;
      padding-right: 0;
      padding-bottom: 0;
      border-left: 5px solid #eee;

      &.loading {
        min-height: 40px;
      }

      /* Do not let an empty list of replies take up space */
      &:empty:not(.loading) {
        margin: 0;
      }
    }

    + p.no-comments {
      display: none;
    }

    &:empty + p.no-comments {
      display: block;
    }
  }
}
