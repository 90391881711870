/**
 * Styling to add individual file tabs to the tab bar.
 *
 * This will override styling for the typical 'tab-bar' which is the bar above
 * many of the individual tabs/pages.
 */

.card > ul.tabs.tab-bar.file-tabs {
  line-height: 1rem;
  height: 2.5rem;
  padding: 0;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Safari */
  -khtml-user-select: none;    /* Konqueror HTML */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                        supported by Chrome and Opera */

  li.file-tab.tab {
    display: inline-block;
    position: relative;
    border-right: 1px solid magenta;
    overflow: hidden;
    height: 2.5rem;
    margin: 0;
    padding: 0;

    button {
      text-indent: -9999px;
      overflow: hidden;
      width: 1rem;
      height: 1rem;
      display: inline-block;
      position: relative;
      vertical-align: top;
      top: 0.75rem;
      margin-left: 0.25rem;
      margin-right: -1.5rem;
      border: none;
      outline: none;
      cursor: pointer;
      background: none;
      background-size: 100% 100%;
      background-position: 0 0;
      background-repeat: no-repeat;

      &.delete {
        background-image: url ('/images/dynamic/hex/ff8080/icons/ui/remove.svg');
      }

      svg.nav,
      img.nav {
        display: none;
      }
    }

    > a,
    > button {
      display: inline-block;
      height: 2.5rem;
      line-height: 2.5rem;
      padding-right: 0.5rem;
      transition: padding-right 0.5s;
      max-width: 10rem;

      svg,
      img {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        position: absolute;
        top: calc(50% - 1rem);
        left: 0.4rem;

        &.nav {
          display: none;
        }

        &.hover {
          display: none;
        }

        &.active {
          display: none;
        }
      }

      .label {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: -0.1rem;
      }

      span {
        display: block;
        padding-left: 2.7rem;
        max-width: 7rem;
        text-overflow: ellipsis;
        overflow: hidden;

        &.type {
          padding-top: 0.25rem;
          font: $viewer-filetabs-inactiveTypeFont;
        }

        &.name {
          font: $viewer-filetabs-inactiveNameFont;
        }
      }

      &:hover {
        img {
          display: none;

          &.active {
            display: none;
          }

          &.hover {
            display: inline-block;
          }

          &.nav.hover {
            display: none;
          }
        }
      }
    }

    &.active {
      > a,
      > button {
        padding-right: 1.5rem;

        img {
          display: none;

          &.hover {
            display: none;
          }

          &.active {
            display: inline-block;
          }
        }

        span.type {
          font: $viewer-filetabs-activeTypeFont;
        }

        span.name {
          font: $viewer-filetabs-activeNameFont;
        }
      }
    }
  }

  li.sidebar {
    z-index: 999;
  }

  li.sidebar + li.file-tab.tab {
    margin-left: 1.75rem;
    padding-left: 1.25rem;
  }

  li.dropdown-menu {
    position: absolute;
    right: calc(2.5rem - 1px);
    border-right: 1px solid magenta;
    left: auto;
    width: 1rem;
    overflow: visible;

    button.dropdown {
      width: 1rem;
      margin: 0;
      top: 0;
      height: 2.5rem;
    }

    ul.dropdown-menu-options {
      z-index: 99999;

      li.file-tab.tab {
        border: none;
        display: block;
        height: auto;

        button.delete {
          display: none;
        }

        svg,
        img {
          display: none;
        }

        svg.nav,
        img.nav {
          display: block;
        }

        img.nav.hover {
          display: none;
        }

        &:hover img.nav {
          display: none;
        }

        &:hover img.nav.hover {
          display: block;
        }
      }
    }
  }

  li.tab:not(.file-tab):not(.sidebar) + li.file-tab.tab {
    margin-left: 0.5rem;
    border-left: 1px solid magenta;
  }
}
