/**
 * Object Details Description Area.
 */

.details,
.top-details {
  .description {
    text-align: left;
    padding: 1rem;
    font: $object-description-font;
    min-height: 100%;
    max-height: 100%;
    flex: 1 0 auto;
    position: relative;
    overflow: auto;
    width: 100%;
    box-sizing: border-box;

    pre {
      background-color: #222;
    }

    > blockquote {
      text-align: left;
      margin-left: 0.25rem;
      padding-left: 0.75rem;
      border-left: 0.25rem solid #6aa3ae;
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    // There is an image gallery
    &:not(:last-child) {
      width: 50%;
      margin-right: 2px;

      @media #{$media-tablet} {
        width: 100%;
        margin-right: 0;
      }
    }

    *:not(.tag) > a {
      font: $object-description-font;
      text-decoration: underline;
    }

    p {
      font: inherit;
    }

    p strong {
      font-weight: bold;
    }

    p em {
      font-style: italic;
    }

    p.edit.edit-metadata {
      padding-top: 0.5rem;
    }

    p.edit.edit-description {
      border-top: 1px solid #5a5a5a;
      padding-top: 1rem;
    }

    > h1,
    > h2 {
      background: none;
      font: 400 1.5rem "Lato", serif;
      height: auto;
      padding: 0;
      margin: 0;
      min-height: 0;
      padding-top: 1.25rem;
      position: relative;
      padding-bottom: 1px;
      border-bottom: 1px solid #5a5a5a;
      margin-bottom: 0.5rem;

      &::after {
        width: 95%;
        height: 2px;
        display: none;
        content: '';
        position: relative;
        bottom: 0;
        background-image: linear-gradient(to right, #8c5e8d, rgb(165, 135, 166) 30%, transparent);
      }
    }

    > h2 {
      font: 400 1rem "Lato", serif;
      margin-bottom: 7px;
      margin-top: 1rem;
    }

    > h3 {
      font: 400 1rem "Lato", serif;
      margin-bottom: 7px;
      margin-top: 1.25rem;
    }

    > h4 {
      font: 400 0.9rem "Lato", serif;
      margin-bottom: 7px;
      margin-top: 1.25rem;
    }

    img {
      max-width: 100%;
    }

    ul,
    ol {
      padding-left: 2rem;
      margin-top: 0.5rem;

      li {
        list-style-type: disc;
      }

      ul,
      ol {
        margin-bottom: 0.5rem;
      }
    }

    ol {
      li {
        list-style-type: decimal;
      }
    }

    ol + pre,
    ul + pre,
    pre + pre,
    pre + p,
    p + pre,
    p + p,
    ol + p,
    ul + p {
      margin-top: 0.5rem;
    }

    * + ul.tags {
      margin-top: 1rem;
      padding-left: 0;
    }

    ul.tags li.tag {
      position: relative;
      display: inline-block;
      line-height: 1em;
      margin-left: 0;

      & + li.tag {
        margin-left: 0.4rem;
      }
    }

    ul.tags li a {
      font: $object-description-tags-font;
      text-decoration: none;
      border: 1px solid white;
      border-radius: 4px;
      padding: 0 5px;

      &:hover,
      &:focus {
        border: 1px solid white;
        text-decoration: none;
      }
    }

    .information {
      padding: 0;
      display: table;
      text-align: left;

      > * {
        display: table-row;

        > * {
          display: table-cell;
          padding-bottom: 0;
          vertical-align: baseline;

          &:not(h2) {
            width: 100%;
            padding-left: 1rem;
          }

          font: 300 0.9rem "Lato", sans-serif;
        }
      }

      ul {
        list-style: none;

        li {
          display: inline-block;
          margin: 0;
          padding: 0;

          img.avatar {
            background-color: white;
            border: 0.0625rem solid blue;
            border-radius: 25%;
            margin-right: 0.25rem;
            vertical-align: middle;
            width: 1rem;
          }

          &:not(:last-of-type) {
            &::after {
              content: ", ";
              margin-right: 0.25em;
            }
          }

          &.organization {
            font-style: italic;
          }
        }

        // Edit button to append to list
        a.edit {
          display: inline-block;
          margin-left: 0.5rem;
          vertical-align: bottom;
          width: 1rem;
          height: 1rem;

          svg {
            fill: white;
          }
        }
      }

      h2 {
        font: 400 0.9rem "Lato", sans-serif;
        vertical-align: baseline;
        padding-top: 3px;
        text-align: left;
        white-space: nowrap;

        svg,
        img {
          width: 1rem;
          height: 1rem;
          margin-right: 0.5rem;
          vertical-align: top;
        }

        span {
          padding-right: 0.5rem;
        }
      }

      .environment {
        svg,
        img {
          width: 0.9rem;
          height: 0.9rem;
          margin-left: 0.2rem;
          margin-right: 0.5rem;
          vertical-align: middle;
        }
      }

      width: 100%;

      &:not(:empty) + *:not(.ps__rail-x):not(.edit-metadata) {
        margin-top: 0.5rem;
        padding-top: 0.5rem;
        border-top: 1px solid #5a5a5a;
      }

      &:not(:empty) + h1:not(.ps__rail-x):not(.edit-metadata),
      &:not(:empty) + h2:not(.ps__rail-x):not(.edit-metadata) {
        padding-top: 1.25rem;
      }

      &:not(:empty) + pre:not(.ps__rail-x):not(.edit-metadata) {
        background-color: transparent;
      }
    }

    p + .information {
      margin-top: 0.5rem;
    }

    .information:empty {
      display: none;
    }
  }
}
