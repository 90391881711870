/**
 * Selector (dropdown selection widget) Styling.
 */

// Selector
//
// This is a replacement for the `<select>` element and presents a dropdown menu.
//
// Unlike other dropdowns, this menu selects a value for a form or other
// component.
//
// If you place a `<select>` on your page and give it a selector class, it will
// generate new markup to present a button and a dropdown with buttons for
// items.
//
// Markup
// <button class="selector"></button>
//
// Styleguide Components.selector
button.selector,
select.selector {
  display: inline-block;
  border: 1px solid #c3c3c3;
  padding: 0 2rem;
  padding-left: 2.5rem;
  width: 16rem;
  height: 2rem;
  margin: 0 0.5rem;
  cursor: pointer;
  box-sizing: border-box;
  font: $object-description-font;
  line-height: 0;
  position: relative;
  vertical-align: middle;
  text-align: left;

  .loading {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-size: 37px;
    background-position: 50% 0;
  }

  > h2 {
    font: $workflow-label-type-font;
    white-space: nowrap;
    font-size: 0.65rem;
    margin-top: 0.2rem;
  }

  > p {
    display: block;
    margin-top: -0.1rem;
    padding: 0;
    font: $workflow-label-name-font;
    font-size: 0.88rem;
  }

  > img {
    display: block;
    position: absolute;
    left: 0.5rem;
    top: calc(50% - 0.75rem);
    height: 1.5rem;
    width: 1.5rem;

    &.hover {
      display: none;
    }

    &.disabled {
      display: none;
    }
  }
}

button.selector:hover,
select.selector:hover {
  > img {
    display: none;

    &.hover {
      display: block;
    }
  }
}

button.selector[readonly],
select.selector[readonly],
button.selector[disabled],
select.selector[disabled] {
  > img {
    display: none;

    &.disabled {
      display: block;
    }

    &.hover {
      display: none;
    }
  }
}

div.dropdown {
  z-index: 9999999999999999;
  width: 264px;
  overflow: auto;
  background-color: white;
  border: 1px solid #aaa;
  overflow-x: hidden;
  position: fixed;
  display: none;
  max-height: 120px;

  > .dropdown-item {
    cursor: pointer;
    width: 100%;
    padding: 5px 0;
    height: 28px;
    margin: 0;
    background: none;
    border: none;
    box-sizing: content-box;
    text-align: left;
    position: relative;

    > h2 {
      display: block;
      background-position: 10px 50%;
      background-repeat: no-repeat;
      background-size: 20px auto;
      height: 26px;
      padding: 0;
      padding-left: 2.5rem;
      font: $workflow-label-type-font;
      white-space: nowrap;
    }

    > p {
      display: block;
      margin-top: -13px;
      padding: 0;
      padding-left: 2.5rem;
      font: $workflow-label-name-font;
      white-space: nowrap;
    }

    > img {
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      left: 10px;
      top: 10px;

      &.hover {
        display: none;
      }

      &.disabled {
        display: none;
      }
    }

    &.focus,
    &:hover,
    &:focus {
      background-color: #eee;
      padding: 4px 0;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;

      > img {
        top: 9px;
        display: none;

        &.hover {
          display: block;
        }
      }
    }

    &[disabled] {
      > img {
        display: none;

        &.disabled {
          display: block;
        }

        &.hover {
          display: none;
        }
      }
    }
  }
}

// Stylelint gets mad about this being placed anywhere but the bottom.
button.selector > h2:last-child,
div.dropdown > .dropdown-item > h2:last-child {
  font: $workflow-label-name-font;
  font-size: 1rem;
  line-height: 1.75rem;
  height: 1.75rem;
  margin-top: 0;
}

// When there are no icons in the list items.
button.selector.no-icons,
select.selector.no-icons {
  padding-left: 0.5rem;

  > .dropdown-item {
    > h2 {
      padding-left: 0.5rem;
    }

    > p {
      padding-left: 0.5rem;
    }
  }
}
