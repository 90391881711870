// Original from https://codepen.io/Qvcool/pen/bdzVYW by Qvcool (see Acknowledgements)

$checked-color: #e987f7;
$unchecked-color: #888;

$width: 2.5rem;
$height: 1.25rem;

.slider-checkbox {
  position: relative;
  display: inline-block;
  height: $height;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Safari */
  -khtml-user-select: none;    /* Konqueror HTML */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */

  .label,
  label {
    display: inline-block;
    position: relative;
    padding-left: calc(#{$width} + 6px);
    vertical-align: top;
    height: $height;
    line-height: $height;

    &::before,
    &::after {
      position: absolute;
      -webkit-border-radius: calc(#{$height} / 2);
      -moz-border-radius: calc(#{$height} / 2);
      border-radius: calc(#{$height} / 2);
      transition: background-color 0.3s, left 0.3s;
    }

    &::before {
      content: "";
      color: #fff;
      box-sizing: border-box;
      font-family: 'Lato', sans-serif;
      padding-left: calc(#{$height} + 3px);
      font-size: 12px;
      line-height: $height;
      background-color: $unchecked-color;
      left: 0;
      top: 0;
      height: $height;
      width: $width;
      -webkit-border-radius: calc(#{$height} / 2);
      -moz-border-radius: calc(#{$height} / 2);
      border-radius: calc(#{$height} / 2);
      background-image: url ('/images/icons/ui/remove.svg');
      background-size: 1rem;
      background-repeat: no-repeat;
      background-position: $height 50%;
    }

    &::after {
      content: "";
      letter-spacing: $height;
      background: #fff;
      left: 1px;
      top: 1px;
      height: calc(#{$height} - 2px);
      width: calc(#{$height} - 2px);
    }
  }

  input {
    margin: 0;
    margin-top: 1px;
    cursor: pointer;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    -khtml-opacity: 0;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background: red;
    width: $width;
    height: $height;
    
    &:checked + .label,
    &:checked + label {
      &::before {
        background-color: $checked-color;
        content: "";
        padding-left: 6px;
        background-image: url ('/images/dynamic/hex/ffffff/icons/ui/done.svg');
        background-size: 1.3rem;
        background-repeat: no-repeat;
        background-position: 0 50%;
      }

      &::after {
        left: calc(#{$height} + 1px);
      }
    }
  }
}
