/**
 * Styling for Paul Navasard's Calendar widget.
 *
 * Some original styling is found here https://codepen.io/peanav/pen/CfFjl
 * on codepen.
 */

.calendar {
  &,
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;

  .header {
    height: 3rem;
    width: 100%;
    background: white;
    text-align: center;
    position: relative;
    z-index: 100;

    h3 {
      background: none;
      max-height: none;
      height: auto;
      margin: 0;
      padding: 0;
      letter-spacing: 1px;
      font: 400 1.6rem "Lato", sans-serif;
      line-height: 3rem;
      color: #404040;
    }
  }

  .left,
  .right {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    top: 50%;
    margin-top: -7.5px;
    cursor: pointer;
  }

  .left {
    border-width: 7.5px 10px 7.5px 0;
    border-color: transparent rgba(160, 159, 160, 1) transparent transparent;
    left: 20px;
  }

  .right {
    border-width: 7.5px 0 7.5px 10px;
    border-color: transparent transparent transparent rgba(160, 159, 160, 1);
    right: 20px;
  }

  .events {
    height: 75px;
    padding: 7px 0;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .events.in {
    animation: fadeIn 0.3s ease both;
    animation-delay: 0.3s;
  }

  .events.out {
    animation: fadeOut 0.3s ease both;
  }

  .event {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.5px;
    padding: 2px 16px;
    vertical-align: top;
  }

  .event.empty {
    color: #eee;
  }

  .event-category {
    height: 10px;
    width: 10px;
    display: inline-block;
    margin: 6px 0 0;
    vertical-align: top;
  }

  .event span {
    display: inline-block;
    padding: 0 0 0 7px;
  }

  .month {
    opacity: 0;
  }

  .month.new {
    animation: fadeIn 1s ease-out;
    opacity: 1;
  }

  .month.in.next {
    animation: moveFromTopFadeMonth 0.4s ease-out;
    opacity: 1;
  }

  .month.out.next {
    animation: moveToTopFadeMonth 0.4s ease-in;
    opacity: 1;
  }

  .month.in.prev {
    animation: moveFromBottomFadeMonth 0.4s ease-out;
    opacity: 1;
  }

  .month.out.prev {
    animation: moveToBottomFadeMonth 0.4s ease-in;
    opacity: 1;
  }

  .week {
    background: white;
  }

  .day {
    display: inline-block;
    width: 6rem;
    padding: 0.5rem;
    text-align: center;
    vertical-align: top;
    cursor: pointer;
    background: white;
    position: relative;
    z-index: 100;

    @media #{$media-tablet} {
      width: 4rem;
    }

    @media #{$media-phone} {
      width: 3rem;
    }
  }

  .day-name {
    text-transform: uppercase;
    margin-bottom: 0.25rem;
    color: #5999a6;
    letter-spacing: 0.7px;
    font: 400 0.6rem "Lato", sans-serif;
  }

  .day-number {
    font: 400 1.6rem "Lato", sans-serif;
    color: #404040;
  }

  .day.other {
    .day-name,
    .day-number {
      opacity: 0.5;
    }
  }

  .day.today {
    color: rgba(156, 202, 235, 1);
  }

  .day .day-events {
    list-style: none;
    margin-top: 0.25rem;
    text-align: center;
    height: 0.75rem;
    line-height: 0.25rem;
    overflow: hidden;
  }

  .day .day-events span {
    vertical-align: top;
    display: inline-block;
    padding: 0;
    width: 5px;
    height: 5px;
    line-height: 5px;
    margin: 0 1px;
  }

  .day:hover {
    background-color: #ba99bb;
    color: white;

    .day-number,
    .day-name {
      opacity: 1;
      color: white;
    }
  }

  .details {
    position: relative;
    width: 42rem;
    height: 8rem;
    background: #6aa3ae;
    margin-top: 0.25rem;
    border-radius: 0.25rem;

    @media #{$media-tablet} {
      width: 30rem;
    }
  }

  .details.in {
    animation: moveFromTopFade 0.5s ease both;
  }

  .details.out {
    animation: moveToTopFade 0.5s ease both;
  }

  .arrow {
    position: absolute;
    top: -5px;
    left: 50%;
    margin-left: -2px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #6aa3ae transparent;
    transition: all 0.7s ease;
  }

  .details.out .events {
    animation: fadeOutShrink 0.4s ease both;
  }

  .legend {
    width: calc(100% + 2px);
    height: 2rem;
    left: -1px;
    background: #b192b2;
    line-height: 2rem;
    position: relative;
    z-index: 9;
  }

  .entry {
    position: relative;
    padding: 0 0 0 25px;
    font-size: 13px;
    display: inline-block;
    line-height: 30px;
    background: transparent;
  }

  .entry::after {
    position: absolute;
    content: '';
    height: 5px;
    width: 5px;
    top: 12px;
    left: 14px;
  }

  @keyframes moveFromTopFade {
    from {
      height: 0;
      margin-top: 0;
      transform: translateY(-100%);
    }
  }

  @keyframes moveToTopFade {
    to {
      height: 0;
      transform: translateY(-100%);
    }
  }

  @keyframes moveToTopFadeMonth {
    to {
      opacity: 0;
      transform: translateY(-30%);
    }
  }

  @keyframes moveFromTopFadeMonth {
    from {
      opacity: 0;
      transform: translateY(30%);
    }
  }

  @keyframes moveToBottomFadeMonth {
    to {
      opacity: 0;
      transform: translateY(30%);
    }
  }

  @keyframes moveFromBottomFadeMonth {
    from {
      opacity: 0;
      transform: translateY(-30%);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    to {
      opacity: 0;
    }
  }

  @keyframes fadeOutShrink {
    to {
      opacity: 0;
      padding: 0;
      height: 0;
    }
  }
}
