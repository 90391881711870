/**
 * Access page styling.
 */

ul.review-links,
ul.permissions {
  display: table;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 200;
  margin: 0 auto;

  input {
    background-color: transparent;
    border: none;
    min-width: auto;
    margin: 0;
  }

  & ~ form input.link {
    font-family: "Lato", sans-serif;
    font-size: 14px;
    font-weight: 200;
  }

  li {
    display: table-row;

    span {
      vertical-align: middle;
      height: 20px;
      line-height: 20px;
      display: table-cell;
      padding: 5px 10px;

      & a {
        vertical-align: middle;
      }

      &.icon {
        img {
          display: block;
          border-radius: 5px;
          width: 32px;
          height: 32px;
          box-shadow: 0 0 2px 0 black;
        }
      }

      &.remove {
        input[type=submit] {
          margin: 0;
        }
      }

      &.key {
        input[name=submit] {
          min-width: auto;
          padding-left: 26px;
          background-image: url ('/images/ui/public_unlocked_dot.png');
          background-repeat: no-repeat;
          background-position: 0 50%;
          background-size: 16px;
          color: #888;
        }

        &[data-value=on] {
          input {
            background-image: url ('/images/ui/public_unlocked_green.png');
            color: #333;
            font-weight: 400;
          }
        }

        &[data-value=off] {
          input {
            background-image: url ('/images/ui/public_locked.png');
            color: #333;
          }
        }

        .dropdown-menu-options {
          li {
            a {
              padding-left: 40px;
              width: calc(100% - 48px);
              position: relative;

              &::after {
                content: '';
                background-image: url ('/images/ui/public_locked.png');
                background-position: 0% 50%;
                background-repeat: no-repeat;
                background-size: 20px;
                position: absolute;
                left: 10px;
                top: 0;
                width: 20px;
                height: 100%;
              }

              &:hover::after {
                filter: brightness(200%);
              }
            }

            &:first-child a {
              &::after {
                background-image: url ('/images/ui/public_unlocked_green.png');
              }
            }

            &:last-child a {
              &::after {
                background-image: url ('/images/ui/public_unlocked_dot.png');
              }
            }
          }
        }
      }
    }

    &:first-child span.icon img {
      width: 20px;
      height: 20px;
      padding: 6px;
      box-shadow: none;
    }

    &.reveal {
      animation: reveal-animation 0.3s;
      opacity: 1;
    }
  }

  & + .add {
    padding-bottom: 20px;

    .loading {
      margin: 0 auto;
    }

    .warning {
      border: 1px solid red;
      text-align: center;
      position: relative;
      margin: 0 auto;

      .delete {
        min-width: auto;
        background-image: url ('/images/icons/ui/remove.svg');
        background-repeat: no-repeat;
        background-size: 16px;
        background-position: 0 0;
        display: block;
        position: absolute;
        right: 2px;
        top: -1px;
        height: 14px;
        width: 14px;
        color: white;
        text-align: center;
        padding: 0;
        line-height: 10px;
        cursor: pointer;
        vertical-align: top;
        text-indent: -9999px;
        filter: brightness(70%);
      }
    }

    form {
      margin-top: 10px;
    }

    .add-person {
      font-family: "Lato", sans-serif;
      font-size: 14px;
      font-weight: 200;
      margin-left: 13px;
    }
  }
}
