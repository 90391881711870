// Button List
//
// This provides a dropdown list submit button.
//
// Markup
// <div class="button-selector"><button class="button-selector-item">Item</button></div>
//
// Styleguide Components.button-selector
.button-selector {
  .button.button-selector-show {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    max-width: 2rem;
    position: relative;
    text-indent: -9999px;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url ('/images/ui/dropdown_arrow.png');
      background-repeat: no-repeat;
      background-position: 40% center;
      background-size: 55%;
      display: block;
    }
  }

  // When the JavaScript widget is loaded, we hide the normal buttons and show
  // the normal widget buttons.
  &.button-selector-bound {
    .button-selector-item {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.dropdown.button-selector-dropdown {
  .dropdown-item.button-selector-dropdown-item {
    text-align: center;
  }
}
