/**
 * Styles the role indicator on a profile header.
 */

h1.person {
  ul.roles {
    position: absolute;
    right: 0;

    li.role {
      font: 300 0.75rem Lato, sans-serif;
      padding: 0.25rem 0.5rem;
      padding-right: 2rem;
      margin-top: 0.5rem;
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;

      svg,
      img {
        margin-right: 0.5rem;
        vertical-align: middle;
        width: 1rem;
        height: 1rem;
      }
    }
  }
}
