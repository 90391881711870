/**
 * Styling for the history page.
 */

.card.object-history {
  .sidebar.date-picker.reveal {
    .calendar {
      // Hide the arrows on the calendar widget when the sidebar is closing.
      // They look bad.
      .left,
      .right {
        display: none;
      }
    }
  }

  // Publish link
  a.publish {
    padding: 0;
    height: 2.5rem;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 0.4rem;
    background-color: #96cfdb;
    background-repeat: no-repeat;
    background-position: calc(50% + 0.25rem - 1px) 50%;
    margin-left: 1rem;
    margin-right: 1rem;
    width: calc(100% - 2rem);
    margin-bottom: 0;
    margin-top: 1rem;
    position: relative;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    svg.icon {
      width: 2rem;
      height: 2rem;
    }

    span {
      color: white;
      padding-left: 0.5rem;
    }
  }
}

// Commit list
ul.history {
  text-align: left;
  padding: 1rem 2rem;
  margin: 0 auto;
  max-width: 60rem;

  li.commit {
    border-top: 1px solid #ddd;
    margin: 0;
    padding: 10px 20px;

    &:last-child {
      border-bottom: 1px solid #ddd;
    }

    .date {
      display: inline-block;
      float: right;
      margin: 0;
      font: $history-date-font;
      position: relative;
      top: -19px;
      padding-left: 10px;
      padding-right: 10px;
    }

    .name,
    .author {
      display: inline-block;
      margin: 0;
      font: $history-author-font;
    }

    .name {
      font-weight: 300;
    }

    .message {
      display: block;
      margin: 0;
      margin-top: 0.5rem;
      font: $history-message-font;
    }
  }
}
