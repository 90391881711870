/**
 * Object Inputs/Outputs Area.
 */

// The preview of the workflow node
.card.metadata-io-preview {
  position: relative;

  .workflow-widget-zone {
    height: 15rem;

    .connections.dummy {
      position: absolute;
    }
  }
}

.metadata-io {
  .card.metadata-io-inputs,
  .card.metadata-io-outputs {
    max-width: 50%;
    position: relative;

    @media #{$media-tablet} {
      max-width: none;
    }

    // The inputs and outputs listing
    ol.object-list {
      padding: 1rem;
      padding-top: 0;
      max-width: 35rem;
      margin: 0 auto;

      &:not(:last-child) {
        padding-bottom: 4rem;
      }
    }

    // The "add" input/output button
    a.add {
      padding: 0;
      height: 2.5rem;
      box-sizing: border-box;
      cursor: pointer;
      border-radius: 0.4rem;
      text-indent: -9999px;
      margin-left: 1rem;
      margin-right: 1rem;
      width: calc(100% - 2rem);
      margin-bottom: 1rem;
      position: absolute;
      bottom: 0;
      left: 0;

      svg.icon,
      img.icon {
        width: 2rem;
        height: 2rem;
        position: absolute;
        top: calc(50% - 1rem);
        left: calc(50% - 0.75rem);
      }
    }
  }

  &.card.horizontal {
    @media #{$media-fit} {
      flex-direction: column;

      > .card.horizontal {
        padding-left: 0;
      }

      .card.metadata-io-preview {
        max-width: none;
      }
    }
  }
}
