/* This styles the test viewer widget which shows the tests for an object
 * including the structure and documentation for each section.
 */

.content .card .test-viewer-overview {
  overflow: auto;

  ul.overviews {
    flex-direction: row;
    display: flex;

    li.overview {
      flex: 1 0 auto;
    }

    li.overview:not(:last-child) {
      border-right: 1px solid #ddd;
    }
  }
}

.content .card .test-viewer {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;

  figure.donut {
    margin: 0 auto;

    figcaption {
      ul.donut-keys {
        span:not(.circle) {
          font: 400 0.9rem Open Sans, sans-serif;
        }
      }
    }
  }

  ul.test-groups {
    text-align: left;

    h2,
    h3,
    h4,
    h5,
    h6 {
      font: $test-viewer-header-font;

      span.type {
        margin-left: 0.5rem;
        font: $test-viewer-header-type-font;
        color: #aaa;
      }
    }

    ul.test-groups {
      margin-left: 15px;
    }

    li.test-group {
      margin-top: 10px;

      ul.tests {
        li.test {
          font: $test-viewer-method-font;
          position: relative;
          padding-left: 2rem;

          &.failed {
            color: red;
          }

          img {
            width: 1.5rem;
            height: 1.5rem;
            vertical-align: middle;
            position: absolute;
            top: 0;
            left: 0;
          }

          & > span {
            vertical-align: middle;
          }

          span.type {
            font: $test-viewer-type-font;
          }

          span.result {
            display: none;
          }

          span.time {
            color: #aaa;
            margin-left: 0.5rem;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.test-metadata-container {
  width: 100%;
  overflow-x: auto;
  border: 1px solid #aaa;
  max-width: 28rem;
  margin: 0 auto;

  table.test-metadata {
    width: 100%;

    tbody {
      tr {
        td {
          padding: 0.25rem;
          text-align: left;
        }

        td:first-child {
          border-right: 1px solid #aaa;
        }
      }

      tr:not(:last-child) {
        td {
          border-bottom: 1px solid #aaa;
        }
      }
    }
  }
}
