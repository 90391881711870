/**
 * Styling specific to the qrcode generation.
 */

.card {
  img.qrcode {
    width: 16rem;
    height: 16rem;
    padding: 1rem;
  }
}
