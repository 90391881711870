/**
 * Generic styling common to several sidebars.
 */

.sidebar {
  .object-selected {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 15px;
    box-sizing: border-box;
    overflow: visible;
    height: 10rem;

    .selector {
      margin: 0;
      margin-top: 20px;
    }

    ul.object-list {
      margin-left: 15px;
      width: 276px;
    }

    h2 {
      padding-left: 0;
      text-align: center;
      white-space: nowrap;

      &:not(:first-child) {
        margin-top: 20px;
      }

      &:first-child {
        padding-bottom: 10px;
      }
    }

    &.disabled {
      pointer-events: none;
    }

    ul.connections {
      width: 100%;

      li.connection {
        white-space: normal;
        position: relative;
        left: calc(50% - 53px);
        top: 12.5px;

        li.input,
        li.output {
          max-width: 95px;
          min-width: 95px;

          .label {
            display: block !important;
          }

          &::after,
          &::before {
            display: none;
          }
        }
      }
    }
  }

  .help {
    overflow-x: hidden;

    &.mock::before {
      content: none;
    }

    occam-workflow.mock {
      position: relative;
      margin: 10px;
      height: 50%;
      width: auto;
      border: 1px solid white;
      pointer-events: none;

      ul.connections {
        // Center the fake node
        left: calc(50% - 50px) !important;
        top: calc(50% - 50px) !important;
      }

      &.run {
        ul.connections {
          .view-jobs-button {
            @keyframes pulseHighlight {
              0% {
                background-color: white;
              }

              100% {
                background-color: blue;
              }
            }

            animation: 0.8s infinite alternate pulseHighlight;

            &::before {
              filter: brightness(5);
            }
          }
        }
      }
    }
  }
}
