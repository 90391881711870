@charset "UTF-8";

$workflow-line-color: #e8b0e0;
$workflow-port-line-color: #a3d495;
$workflow-wire-highlight-color: #ff4be5;
$workflow-wire-hover-color: #ff4be5;
$workflow-port-wire-hover-color: darken($workflow-port-line-color, 20);
$workflow-selection-color: rgba(90, 181, 181, 0.33);
$workflow-selection-border-color: rgba(70, 161, 161, 0.33);
$workflow-wire-selected-node-highlight-color: #ff7eff;
$workflow-label-name-font: 300 14px Lato, sans-serif;
$workflow-label-type-font: bold 12px Lato, sans-serif;

$workflow-input-name-font: 300 12px Lato, sans-serif;
$workflow-input-type-font: bold 10px Lato, sans-serif;

$workflow-context-menu-font: 300 0.9rem "Lato", sans-serif;
$workflow-node-selected-border-color: $workflow-wire-hover-color;
$workflow-label-type-color: rgba(57, 130, 130, 1);
$workflow-label-name-color: rgba(30, 30, 30, 1);

$workflow-wire-selected-color: #ff7eff;
$workflow-accent-color: #f1d7ed;

$workflow-node-selected-border-color: $workflow-wire-hover-color;

$workflow-wire-node-color: #beeac4;
$workflow-wire-node-border-color: #9ed4a5;
$workflow-wire-node-accent-color: #add89d;

$workflow-wire-selected-node-color: #cefad4;
$workflow-wire-selected-node-border-color: #aee4b5;
$workflow-wire-selected-node-accent-color: #bde8ad;

$workflow-selection-color: rgba(90, 181, 181, 0.33);
$workflow-selection-border-color: rgba(70, 161, 161, 0.33);
$workflow-wire-selected-node-highlight-color: #ff7eff;

.workflow-widget-zone {
  width: 100%;
  height: 100%;

  ul.connections {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
}

.workflow-widget-zone,
.workflow-widget-js {
  padding: 0;
  display: block;
  overflow: hidden;
  position: relative;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Safari */
  -khtml-user-select: none;    /* Konqueror HTML */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome and Opera */

  // Hidden elements need to stay hidden
  .hidden,
  [hidden] {
    display: none !important;
  }

  // SVG paths for wires
  svg {
    path {
      fill: $workflow-line-color;
      stroke: transparent;
      stroke-width: 0.625rem;
      cursor: pointer;

      &:hover:not(.dummy) {
        fill: $workflow-wire-hover-color;
      }

      &.port {
        fill: $workflow-port-line-color;

        &:hover:not(.dummy) {
          fill: $workflow-port-wire-hover-color;
        }
      }

      &.selected {
        fill: hsla(0, 100%, 71%, 1);
        stroke: hsla(0, 100%, 81%, 1);
        stroke-width: 0.0625rem;
      }

      &.highlight {
        fill: $workflow-wire-highlight-color;
        stroke: $workflow-wire-highlight-color;
        stroke-width: 0.0625rem;
      }
    }
  }

  // Set the cursor while selecting nodes (left-click and drang)
  &.selecting {
    cursor: crosshair;
  }

  // Set the cursor while moving the workflow (right-click and drang)
  &.moving {
    cursor: move;
  }

  // Debug styles to highlight relevant elements
  > .selection,
  > .debug {
    display: block;
    position: absolute;
    background-color: $workflow-selection-color;
    z-index: 9999;
    border-radius: 0.3125rem;
    border: 0.0625rem solid $workflow-selection-border-color;
    cursor: crosshair;

    &.debug {
      background-color: rgba(256, 0, 0, 0.33);

      &.quadtree {
        background-color: rgba(256, 0, 256, 0.33);
      }
    }
  }

  // Contains the nodes themselves (not the wires)
  ul.connections {
    display: block;
    position: relative;
    list-style-type: none;

    @mixin button-style {
      display: none;
      border-radius: 0.625rem;
      left: -1.5625rem;
      bottom: 0;
      width: 1rem;
      height: 1rem;
      position: absolute;
      border: 0.0625rem solid #ddd;
      z-index: 9999;
      background-color: #fff;
    }

    @mixin outer-button-style {
      @include button-style;

      box-shadow: 0 0 0 0.25rem white;

      &:hover {
        border: 0.0625rem solid #bbb;
        background-color: opacify($workflow-selection-color, 1);

        > img:not(:last-of-type) {
          display: none;
        }

        > img.hover {
          display: block;
        }
      }
    }

    @mixin plus-button-style {
      &::before {
        content: "";
        width: 0.5rem;
        height: 0.125rem;
        left: 0.25rem;
        top: 0.4375rem;
        position: absolute;
        background-color: #bbb;
      }

      &::after {
        content: "";
        width: 0.125rem;
        height: 0.5rem;
        left: 0.4375rem;
        top: 0.25rem;
        position: absolute;
        background-color: #bbb;
      }

      &:hover {
        &::before {
          background-color: white;
        }

        &::after {
          background-color: white;
        }
      }
    }

    .port-add-button {
      @include outer-button-style;
      @include plus-button-style;

      bottom: initial;
      top: -1.5625rem;
    }

    .input-add-button {
      @include outer-button-style;
      @include plus-button-style;
    }

    .output-add-button {
      @include outer-button-style;
      @include plus-button-style;

      left: initial;
      right: -1.5625rem;
    }

    .delete-button {
      @include outer-button-style;
      @include plus-button-style;

      transform: rotate(45deg);
      left: calc(50% - 0.625rem);
      top: -1.5rem;

      &::after,
      &::before {
        background-color: hsla(0, 100%, 71%, 1);
      }

      &:hover {
        background-color: #fcc;
        box-shadow: 0 0 0.0625rem 0.0625rem white inset;
      }
    }

    .bottom-button {
      @include outer-button-style;

      top: calc(100% + 0.625rem);

      &.one {
        &.of-one {
          left: calc(50% - 0.625rem);
        }

        &.of-two {
          left: calc(35% - 0.625rem);
        }

        &.of-three {
          left: calc(25% - 0.625rem);
        }

        &.of-four {
          left: calc(14% - 0.625rem);
        }
      }

      &.two {
        &.of-two {
          left: calc(65% - 0.625rem);
        }

        &.of-three {
          left: calc(50% - 0.625rem);
        }

        &.of-four {
          left: calc(38% - 0.625rem);
        }
      }

      &.three {
        &.of-three {
          left: calc(75% - 0.625rem);
        }

        &.of-four {
          left: calc(62% - 0.625rem);
        }
      }

      &.four {
        &.of-four {
          left: calc(86% - 0.625rem);
        }
      }

      svg,
      img {
        padding: 0.125rem;
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        &.hover {
          display: none;
        }
      }
    }

    .hide-wire-button {
      @include button-style;

      left: -0.625rem;
      bottom: -0.5625rem;

      &:hover {
        border: 0.0625rem solid #bbb;
        background-color: opacify($workflow-selection-color, 1);
      }

      &::before {
        content: '';
        display: block;
        width: 0.4375rem;
        height: 0.4375rem;
        border: solid 0.0625rem #bbb;
        border-radius: 75% 15%;
        transform: rotate(45deg);
        left: 0.25rem;
        top: 0.25rem;
        position: relative;
      }

      &::after {
        content: '';
        display: block;
        width: 0.125rem;
        height: 0.6875rem;
        background: #bbb;
        border-radius: 25%;
        transform: rotate(45deg);
        left: calc(50% - 0.0625rem);
        top: calc(50% - 0.34375rem);
        position: absolute;
        box-shadow: 0 0 0 0.0625rem white;
      }

      &:hover {
        &::before {
          border-color: white;
        }

        &::after {
          background: white;
          box-shadow: 0 0 0 0.0625rem opacify($workflow-selection-color, 1);
        }
      }
    }

    .attach-button {
      @include button-style;

      left: -0.625rem;
      bottom: -0.5625rem;
    }

    > li.connection {
      position: absolute;
      box-sizing: content-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      line-height: 1em;
      width: 5.625rem;
      height: 5.125rem;
      border: 0.0625rem solid #ddd;
      border-left-width: 0.1875rem;
      border-left-color: #bbb;
      border-right-width: 0.1875rem;
      border-right-color: #bbb;
      border-radius: 0.3125rem;
      z-index: 999;
      background-color: white;
      cursor: pointer;
      padding: 0.5rem 0.3125rem;

      > svg,
      > .icon {
        width: 3.125rem;
        background-size: 2.5rem;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        border: 0.0625rem solid transparent;
        height: 3.125rem;
        display: inline-block;
        text-align: center;
        margin: 0 auto;
        line-height: 1em;
        position: absolute;
        top: 0.5rem;
        left: calc(50% - 1.625rem);
        box-sizing: border-box;

        + .type {
          margin-top: 3.125rem;
        }
      }

      > .icon {
        padding: 0.1875rem;

        &:not([src]) {
          display: none;

          + .type {
            margin-top: 0;
          }
        }
      }

      > span.name {
        display: inline-block;
        font: $workflow-label-name-font;
        width: 5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1em;
        margin: 0 auto;

        &:empty {
          display: none;
        }
      }

      > span.type {
        display: inline-block;
        font: $workflow-label-type-font;
        line-height: 1em;
        width: 5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 auto;

        &:empty {
          display: none;
        }
      }

      > .target {
        @include button-style;

        font: $workflow-label-type-font;
        color: #777;
        display: block;
        position: absolute;
        top: -0.5rem;
        height: 1rem;
        bottom: -0.5rem;
        background: white;
        border-radius: 0.25rem;
        font-size: 0.625rem;
        line-height: 0.875rem;
        top: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        outline: 0.0625rem solid #fff;
        box-shadow: 0 0 0.0625rem 0.0625rem #333;
        border: 0.125rem solid #888;

        &.one {
          &.of-one {
            left: 1rem;
            width: 4.25rem;
          }

          &.of-two {
            left: 0.25rem;
            width: 2.75rem;
          }

          &.of-three {
            left: 0.1875rem;
            width: 1.875rem;
          }

          &.of-four {
            left: 0.125rem;
            width: 1.375rem;
          }
        }

        &.two {
          &.of-two {
            left: 3.25rem;
            width: 2.75rem;
          }

          &.of-three {
            left: 2.1875rem;
            width: 1.875rem;
          }

          &.of-four {
            left: 1.625rem;
            width: 1.375rem;
          }
        }

        &.three {
          &.of-three {
            left: 4.1875rem;
            width: 1.875rem;
          }

          &.of-four {
            left: 3.125rem;
            width: 1.375rem;
          }
        }

        &.four {
          &.of-four {
            left: 4.625rem;
            width: 1.375rem;
          }
        }
      }

      &.moving {
        z-index: 99999 !important;
        box-shadow: 0 0 0.625rem 0.125rem #ddd;
      }

      &.highlighted {
        box-shadow: 0 0 0.625rem 0.125rem $workflow-node-selected-border-color;
      }

      &.selected {
        border-color: $workflow-node-selected-border-color;
        box-shadow: 0 0 0.625rem 0.125rem #eee;
        z-index: 99999;
      }

      // "Hidden" nodes are displayed differently when they HAVE to be shown.
      // For instance, a hidden known may be shown when the wire is not hidden.
      // So we show just a smaller icon reflecting that the node is there... just
      // put aside a bit.
      &[data-visibility="hidden"] {
        width: 1.375rem;
        height: 1.5625rem;
        border-radius: 3.125rem;
        padding: 0;

        > svg,
        > .icon {
          width: 1.25rem;
          height: 1.25rem;
          top: 0.1875rem;
          left: 0.0625rem;
          position: absolute;
        }

        > span.name,
        > span.type {
          left: calc(50% - 2.5rem);
          top: 1.875rem;
          position: absolute;
          margin: 0;
        }

        > span.name {
          top: 2.9375rem;
        }

        > .bottom-button {
          display: none;
        }

        ol.ports li.port.contains-selected:not(.disconnected) {
          .attach-button {
            display: none;
          }

          ol.connections li.connection.selected .delete-button {
            display: none;
          }
        }
      }

      // A node that is currently displaying job status.
      &.tracking-jobs:not(.ghost) {
        > .icon {
          padding: 0.75rem;
        }

        > .icon:not([src]) {
          display: inline-block;
          opacity: 0;

          + .type {
            margin-top: 3.125rem;
          }
        }

        svg {
          border-radius: 50%;
          border: 0.0625rem solid #d4d4d4;

          path {
            fill: #bbb;
          }

          @keyframes running-pulse {
            0% {
              fill: #c0c0ff;
            }

            100% {
              fill: #88f;
            }
          }

          path[data-job-status="finished"] {
            fill: #82d082;
          }

          path[data-job-status="started"] {
            fill: #88f;
            animation: 1s infinite alternate running-pulse;
          }

          path[data-job-status="failed"] {
            fill: #f88;
          }
        }
      }

      // The wire dropdown list
      > .wire-dropdown {
        background-color: white;
        border: 0.0625rem solid $workflow-selection-border-color;
        display: block;
        outline: none;
        padding: 0 0;
        text-align: center;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 999999;
        max-height: 6.9375rem;
        overflow-y: auto;

        li {
          cursor: pointer;
          padding: 0.3125rem 0.625rem;
          height: 1.6875rem;
          color: #333;

          &:hover {
            background-color: $workflow-selection-color;
            color: #333;
          }

          span {
            &.type {
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font: $workflow-input-type-font;
            }

            &.name {
              display: block;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              font: $workflow-input-name-font;
            }
          }
        }

        &:active {
          outline: none;
        }
      }

      // Inputs and outputs lists common properties
      > ol.inputs,
      > ol.outputs {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li.port > .label {
          > span.name {
            color: $workflow-label-name-color;
          }

          > span.type {
            color: $workflow-label-type-color;
          }

          &.revealed {
            display: inline-block !important;
          }
        }
      }

      // Setting common to all ports
      @mixin port-style {
        padding: 0;
        margin: 0;
        display: block;
        position: absolute;
        width: 6.25rem;
        top: 0;

        > .label {
          pointer-events: none;
          display: inline-block;
          position: absolute;
          white-space: nowrap;
          z-index: 999;
          bottom: 0.1875rem;
        }

        > .label > .name {
          display: block;
          font: $workflow-input-name-font;
        }

        > .label > .type {
          display: block;
          font: $workflow-input-type-font;
        }

        > .label.truncated > .type {
          display: none;
        }

        &:not(.disconnected) {
          .hide-wire-button {
            display: none;
          }

          .attach-button {
            display: inline-block;
          }

          &.contains-only-partially-visible.contains-selected {
            .hide-wire-button {
              display: inline-block;
            }

            ol.connections li.connection.selected .delete-button {
              display: none;
            }
          }
        }

        &.disconnected:not(.drawing) {
          // The disconnected line!
          border-bottom: 0.0625rem solid $workflow-line-color;

          // The small circle used in the port peg
          &::before {
            content: "";
            width: 0.375rem;
            height: 0.375rem;
            border-radius: 0.6875rem;
            background-color: $workflow-wire-node-color;
            border: 0.0625rem solid $workflow-wire-node-border-color;
            position: absolute;
            bottom: -0.3125rem;
          }

          // The large circle used in the port peg
          &::after {
            content: "";
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 0.6975rem;
            border: 0.0625rem solid $workflow-wire-node-accent-color;
            background-color: transparent;
            position: absolute;
            bottom: -0.5rem;
          }

          // The glow on the port peg when it is selected
          &.selected {
            .hide-wire-button {
              display: inline-block;
            }

            &::before {
              background-color: $workflow-wire-selected-node-color;
              border: 0.0625rem solid $workflow-wire-selected-node-border-color;
            }

            &::after {
              border: 0.0625rem solid $workflow-wire-selected-node-accent-color;
              box-shadow: 0 0 0.375rem 0 $workflow-wire-selected-node-highlight-color;
            }
          }
        }
      }

      // Input (left-side) port settings
      ol.ports li.port.left {
        @include port-style;

        // Shift the port to the left of the node
        right: 6.4375rem;

        .hide-wire-button,
        .attach-button {
          right: -0.625rem;
          left: auto;
        }

        > .label {
          text-align: right;
          right: 0;

          > .name,
          > .type {
            padding-right: 0.625rem;
          }
        }

        &.disconnected {
          // The position of the small peg
          &::before {
            left: -0.875rem;
          }

          // The position of the large peg
          &::after {
            left: -1.0625rem;
          }
        }
      }

      // Output (right-side) port settings
      ol.ports li.port.right {
        @include port-style;

        // Shift the port to the right of the node
        left: 6.4375rem;

        > .label {
          text-align: left;
          left: 0;

          > .name,
          > .type {
            padding-left: 0.625rem;
          }
        }

        &.disconnected {
          // The position of the small peg
          &::before {
            right: -0.875rem;
          }

          // The position of the large peg
          &::after {
            right: -1.0625rem;
          }
        }
      }

      // Top-side port settings
      ol.ports li.port.top {
        @include port-style;

        // Rotate the label

        // Shift the port to the top of the node
        top: auto;
        bottom: 6.3125rem;
        width: 0;
        height: 6.25rem;

        > .label {
          text-align: left;
          left: -0.3125rem;
          right: auto;
          bottom: 0.4375rem;
          transform: rotate(-90deg);
          transform-origin: 0 100%;

          > .name,
          > .type {
            padding: 0;
          }
        }

        &.disconnected {
          // The disconnected line!
          border-right: 0.0625rem solid $workflow-line-color;
          border-bottom: none;

          // The position of the small peg
          &::before {
            top: -0.8125rem;
            left: -0.125rem;
          }

          // The position of the large peg
          &::after {
            top: -1rem;
            left: -0.4375rem;
          }
        }
      }

      // Bottom-side port settings
      ol.ports li.port.bottom {
        @include port-style;

        // Rotate the label

        // Shift the port to the top of the node
        top: 6.3125rem;
        bottom: auto;
        width: 0;
        height: 6.25rem;

        .hide-wire-button,
        .attach-button {
          left: -0.5625rem;
          bottom: auto;
          top: -0.625rem;
        }

        > .label {
          text-align: right;
          left: auto;
          right: 0.3125rem;
          top: -1.125rem;
          bottom: auto;
          transform: rotate(-90deg);
          transform-origin: 100% 100%;

          > .name,
          > .type {
            padding: 0;
          }
        }

        &.disconnected {
          // The disconnected line!
          border-right: 0.0625rem solid $workflow-line-color;
          border-bottom: none;

          // The position of the small peg
          &::before {
            bottom: -0.8125rem;
            left: -0.125rem;
          }

          // The position of the large peg
          &::after {
            bottom: -1rem;
            left: -0.4375rem;
          }
        }
      }

      ol.ports li.port li.connection {
        padding: 0;
        margin: 0;
        display: block;
        position: absolute;
        width: 0.0625rem;
        height: 0.0625rem;
        background-color: transparent;
        left: auto;
        top: auto;
        bottom: auto;
        right: auto;
        border: none;

        .delete-button {
          top: -0.5675rem;
          display: none;
          box-shadow: none;
        }

        &.selected {
          // Show the delete button for the wire when the port is selected
          .delete-button {
            display: inline-block;
          }
        }
      }

      ol.ports li.port ol.connections {
        padding: 0;
        margin: 0;
      }

      ol.ports li.port.left ol.connections li.connection {
        right: 0;

        .delete-button {
          left: auto;
          right: -0.625rem;
        }
      }

      ol.ports li.port.right ol.connections li.connection {
        left: 0;
      }

      ol.ports li.port.top ol.connections li.connection {
        bottom: 0;

        .delete-button {
          top: -0.5rem;
        }
      }

      ol.ports li.port.bottom ol.connections li.connection {
        top: 0;

        .delete-button {
          top: -0.625rem;
          left: calc(50% - 0.5625rem);
        }
      }

      ol.ports:not(.inputs):not(.outputs) li.port.disconnected {
        border-color: $workflow-port-line-color;
      }

      &[data-visibility="hidden"] ol.ports {
        li.port > .label {
          display: none;
        }

        li.port.left {
          right: 1.5625rem;
        }

        li.port.right {
          left: 1.5625rem;
        }
      }

      // A "ghost" node that is used when dragging things.
      &.ghost {
        width: 0.0625rem;
        height: 0.0625rem;
        position: absolute;
        padding: 0;
        margin: 0;
        border: none;
        background: none;
        outline: none;
        overflow: hidden;
        pointer-events: none;
      }

      &.dummy ol.ports li.port .attach-button {
        display: none !important;
      }

      &.viewing {
        z-index: 99999 !important;

        > div.port-add-button,
        > div.input-add-button,
        > div.output-add-button,
        > div.delete-button,
        > div.bottom-button {
          display: inline-block;
        }

        ol.ports {
          li.port {
            &:not(.disconnected) {
              .attach-button {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  // The "draggable" plane is the surface in which elements are selected and moved.
  ul.connections.draggable {
    margin: 0;
  }
}
