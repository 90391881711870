/**
 * Styling specific to the new object modal.
 */

form.new-object-data {
  input[name="query"][type="text"]:not(.auto-complete):not(.tagify) {
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    max-width: none;
    width: 100%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin: 0;
    box-shadow: 0 0 0.25rem #a3a3a3;
    position: sticky;
    z-index: 9;
  }

  .card.submitting {
    min-height: 13rem;
  }

  li#new-object-select-template {
    > .card {
      overflow: hidden;

      .objects-container {
        width: 100%;
        min-height: 13rem;

        ul.objects {
          padding-top: 1rem;
          width: 100%;
          min-height: 13rem;
        }
      }

      li.tab-panel {
        min-height: 15rem;
      }
    }
  }
}
