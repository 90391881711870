/**
 * Styling for the dropdown menus on the site.
 */

@mixin dropdownOptions() {
  position: relative;
  outline: none;
  display: inline-block;

  &:focus {
    outline: none;
  }

  & > ul.dropdown-menu-options {
    z-index: 99999999;
    outline: none;
    display: none;
    position: absolute;
    right: 0;
    margin-top: -2px;
    padding-right: 1rem;
    background-color: white;
    border-radius: 0.3rem;
    box-shadow: 0 0 0.1875rem 0 #111;

    &:focus {
      outline: none;
    }

    &::before {
      outline: none;
      content: '';
      display: block;
      position: absolute;
      width: 12px;
      height: 12px;
      right: 0.55rem;
      top: -5px;
      background-color: white;
      transform: rotateZ(45deg);
      box-shadow: 0 0 0.1875rem 0 #111;
    }

    &::after {
      outline: none;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: white;
      height: 0.5rem;
      border-radius: 0.3rem;
    }

    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    li {
      width: calc(100% + 1rem);
      position: relative;
      background-color: white;
      z-index: 99999;
      display: block;
      padding: 0;
      text-align: left;
      border: none;

      form {
        display: inline-block;
        width: 100%;
        position: relative;
      }

      svg,
      img {
        position: absolute;
        left: 0.375rem;
        top: 0.375rem;
        width: 1.25rem;
        height: 1.25rem;

        &.hover {
          display: none;
        }
      }

      &.separator {
        margin-top: 5px;
        margin-bottom: 5px;
        background-color: white;
        height: 1px;
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
      }

      a,
      form input {
        margin: 0;
        display: inline-block;
        padding: 0.25rem 0.5rem;
        width: calc(100% - 1rem);
        min-width: auto;
        vertical-align: middle;
        text-decoration: none;
        text-align: left;
        white-space: nowrap;
        position: relative;
        background: none;
        border: none;
        color: #222;
        cursor: pointer;
        font: $nav-link-font;
        height: 2em;
        line-height: 2em;
      }

      &:hover {
        a,
        form input {
          text-decoration: none;
        }

        img {
          display: none;
        }

        img.hover {
          display: block;
        }
      }

      a.icon,
      form input.icon {
        padding-left: 2rem;
        width: calc(100% - 2.5rem);
        height: auto;
        box-sizing: content-box;

        input {
          padding-left: 2rem;
        }
      }
    }
  }

  &:focus-within:not([disabled]) > ul.dropdown-menu-options,
  &:focus:not([disabled]) > ul.dropdown-menu-options {
    display: block;
  }

  & > button:focus:not([disabled]) + ul.dropdown-menu-options,
  & > button:active:not([disabled]) + ul.dropdown-menu-options,
  & > a:focus:not([disabled]) + ul.dropdown-menu-options,
  & > a:active:not([disabled]) + ul.dropdown-menu-options {
    display: block;
  }
}

.dropdown-menu:not(.media-tablet) {
  @include dropdownOptions;
}

@media #{$media-tablet} {
  .dropdown-menu.media-tablet {
    @include dropdownOptions;
  }
}
