/**
 * Styling for markdown generated pages.
 */

$markdown-width: 60rem;

.card.markdown {
  > img {
    display: block;
    margin: 0 auto;
    max-width: 80%;
  }

  > p {
    text-align: left;
    width: $markdown-width;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.1rem;
    font-weight: 300;

    + p {
      margin-top: 0.5rem;
    }
  }

  > ol {
    li {
      list-style-type: decimal;
    }
  }

  code {
    font: $code-font;
  }

  > pre {
    text-align: center;

    code {
      display: block;
      text-align: left;
      box-sizing: border-box;
      width: $markdown-width;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding: 0.5rem 1rem;
    }
  }

  > p + pre {
    margin-top: 1rem;
  }

  > ol:not(.tabs):not(.tab-panels):not(.card),
  > ul:not(.tabs):not(.tab-panels):not(.card) {
    padding-left: 2rem;
    margin-top: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    width: $markdown-width;
    max-width: 100%;
    text-align: left;

    li {
      list-style-type: disc;
      list-style-position: inside;

      &:not(:first-child) {
        margin-top: 0.25rem;
      }
    }

    ul,
    ol {
      margin-bottom: 0.5rem;
    }
  }

  a:not(.button) {
    color: hsl(190, 80%, 60%);
  }

  h2 {
    clear: both;
    margin-top: 0;
    padding-top: 1rem;

    + p {
      margin-top: 0.5rem;
    }
  }

  img,
  .documentation {
    vertical-align: text-bottom;
  }

  .image {
    display: block;
    margin: 0 auto;
    width: $markdown-width;
    max-width: 90%;
    box-sizing: border-box;

    img {
      max-width: 100%;
      box-sizing: border-box;
    }

    &.fullwidth {
      width: 100%;
    }

    &.border {
      img {
        border: 1px solid hsl(190, 30%, 35%);
        padding: 0.25rem;
      }
    }

    &.right {
      text-align: right;
      float: right;
      clear: both;
      margin-right: 0;
      margin-left: 10px;
    }

    &.left {
      text-align: left;
      clear: both;
      float: left;
      margin-right: 10px;
      margin-left: 0;
    }

    .caption p {
      font-size: 12px;
      color: hsl(190, 30%, 65%);
      font-family: "Alegreya Sans SC", serif;
    }
  }

  .documentation span.tab,
  .documentation a,
  .documentation {
    pointer-events: none;
    cursor: default;
  }

  button.documentation,
  input.button.documentation {
    padding: 0.1rem 0.4rem;
  }
}

.modal .markdown-editor {
  .tab-panels {
    li.tab-panel:not(:first-child) {
      .markdown-preview {
        box-sizing: border-box;
        overflow: auto;
        text-align: left;
      }
    }
  }
}
