/**
 * Styling for the modal window.
 */

// The minimum width for a non-wide modal
$modal-min-width: 700px;

.modal-window {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999999999;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-image: url ('/images/static/noise_g2.png');
    opacity: 0.8;
  }

  > .loading:not(.error-500):not(.error-400) {
    border-radius: 50%;
    position: fixed;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
    width: 100px;
    height: 100px;
    background-size: 50%;
    background-position-y: 55%;
  }

  > .close {
    background-color: hsl(191, 61%, 16%);
    background-image: url ("/images/dynamic/hex/ffffff/icons/ui/remove.svg");
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 70%;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    right: calc(30% + 2px);
    top: calc(30% + 1px);
    width: 20px;
    height: 20px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 5px;
    z-index: 9999999;

    @media (max-width: #{$modal-min-width / 40 * 100}) {
      right: calc((100vw - #{$modal-min-width}) / 2 + 2px);
    }

    @media (max-width: #{$modal-min-width}) {
      right: 2px;
    }

    @keyframes hover-animation {
      0% {
        background-color: hsl(191, 61%, 16%);
      }

      100% {
        background-color: hsla(191, 61%, 36%, 1);
      }
    }

    &:hover {
      background-color: hsla(191, 61%, 36%, 1);
      animation: 0.2s hover-animation;
    }
  }

  .content {
    background-color: black;
    border: 1px solid #0a323c;
    box-shadow: 0 0 5px -1px black;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 5px;
    border-top-right-radius: 12px;
    position: absolute;
    padding: 0;
    margin: 0;
    width: 40%;
    min-width: $modal-min-width;
    max-height: 50%;
    left: 30%;
    top: 30%;
    flex-direction: column;

    @media (max-width: #{$modal-min-width / 40 * 100}) {
      left: calc((100vw - #{$modal-min-width}) / 2);
    }

    @media (max-width: #{$modal-min-width}) {
      width: 100%;
      max-width: 100%;
      min-width: 0;
      left: 0;
    }

    & > form,
    & > .card {
      overflow: hidden;
      flex: 0 0 auto;
    }

    & > .card.filled {
      overflow: auto;
      flex: 1 1 auto;

      > .card.filled {
        flex: 1 1 auto;

        > ul.tab-panels {
          overflow: hidden;

          > li.tab-panel .card {
            overflow: auto;

            &.submit {
              overflow: hidden;
              flex: 0 0 auto;
            }
          }
        }
      }
    }
  }

  &.full {
    .content {
      height: 50%;
    }
  }

  &.tall {
    .content {
      max-height: 90%;
      height: 90%;
      top: 5%;
    }
  }

  &.large {
    .close {
      right: calc(5% + 2px);
    }

    .content {
      width: 90%;
      max-height: 90%;
      height: 90%;
      left: 5%;
      top: 5%;
    }
  }
}
