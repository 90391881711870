/**
 * Styling for errors list (for form input.)
 */

.card.errors {
  text-align: center;
}

.content .card.errors h2 {
  margin: 0;
}

.card.errors ul {
  list-style-type: square;
  list-style-position: inside;
}

.card.errors ul li {
  margin-bottom: 7px;
  opacity: 0;
  transition: opacity 0.3s;
}

.card.errors ul li.shown {
  opacity: 1;
}

.card.errors ul li:last-child {
  margin-bottom: 0;
}
