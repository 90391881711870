/**
 * Tab-bar styling.
 *
 * These are secondary tabs.
 */

.card > ul.tabs.tab-bar {
  display: block;
  overflow: visible;
  flex: 0 0 auto;
  height: 2.5rem;
  background-color: white;
  position: relative;
  z-index: 999999;
  box-sizing: border-box;
  text-align: left;
  line-height: 0;
  counter-reset: stepCounter;

  &.overlay-open {
      z-index: 99999999;
  }

  > span {
    display: inline-block;
    height: 2.5rem;
    vertical-align: top;
    font: $sub-tab-font;
    line-height: 2.5rem;
    padding-right: 0.75rem;
  }

  span.separator {
    display: inline-block;
    width: 0;
    height: calc(100% - 0.5rem);
    border-left: 1px solid #f1f1f1;
    padding-right: 0.75rem;
    margin-top: 0.25rem;
  }

  .button + span.separator {
    margin-left: 0.25rem;
  }

  li.tab + span.separator {
    margin-left: 0.5rem;
  }

  // Images that highlight sections on the tab bar
  > svg,
  > img {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    vertical-align: top;

    &:first-child {
      margin-left: 0.75rem;
    }
  }

  > :first-child[hidden] + svg,
  > :first-child[hidden] + img {
    margin-left: 0.75rem;
  }

  // Selectors (dropdown selections) on the tab bar
  > .selector {
    margin: 0;
    margin-top: 0.25rem;
    margin-right: 0.75rem;
  }

  // Buttons on the tab bar
  > input,
  > .button {
    height: 2rem;
    line-height: 0;
    box-sizing: border-box;
    vertical-align: top;
    margin-top: 0.25rem;
    margin-right: 0.75rem;
  }

  // Text boxes and such on the tab bar
  > input:not(.button) {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    width: 16rem;
    margin-right: 0.5rem;
  }

  // Bigger buttons
  > button.button,
  > a.button {
    margin-left: 0;
    padding: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    line-height: 1.75rem;
    width: 16rem;

    &:first-child {
      margin-left: 0.5rem;
    }
  }

  // With icons
  > button.button.icon,
  > a.button.icon {
    text-align: center;
    padding: 0.3rem;
    width: 2rem;
    margin-right: 0.5rem;
    position: relative;
    box-sizing: border-box;

    svg,
    img {
      width: 1.25rem;
      height: 1.25rem;
      position: absolute;
      left: calc(0.375rem - 1px);
      top: calc(0.375rem - 1px);

      &.hover {
        display: none;
      }

      &.disabled {
        display: none;
      }
    }
  }

  > h2 {
    font: $sub-tab-font;
    height: 2.5rem;
    padding: 0 0.5rem;
    margin: 0;
    line-height: 2.5rem;
  }

  // Close button on the tab bar
  > li.close {
    background-image: url ("/images/dynamic/hex/999999/icons/ui/remove.svg");
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 70%;
    border-radius: 50%;
    border: 1px solid #999;
    cursor: pointer;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    z-index: 9999999;
    width: 1.5rem;
    height: 1.5rem;
    overflow: hidden;
    text-indent: -9999px;
  }

  // Tab buttons
  > li.tab {
    margin-top: 0.25rem;
    padding-left: 0.25rem;
    display: inline-block;
    color: #aaa;
    font: $sub-tab-font;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    cursor: pointer;
    vertical-align: top;

    > a {
      cursor: pointer;
      margin-top: 0;
      padding: 0 0.5rem;
      height: 1.8rem;
      line-height: 2rem;
      border-bottom: 0.2rem solid transparent;
      text-decoration: none;
      display: inline-block;
      position: relative;

      &.icon {
        padding-left: 2rem;
        background-repeat: no-repeat;
        background-size: 1.5rem 1.5rem;
        background-position: 0.25rem calc(50% + 0.1rem);

        svg,
        img {
          position: absolute;
          left: 0.225rem;
          top: 0.375rem;
          width: 1.25rem;
          height: 1.25rem;

          &.hover {
            display: none;
          }

          &.active {
            display: none;
          }
        }
      }
    }

    &:hover {
      > a {
        text-decoration: none;
      }
    }

    &:first-child {
      margin-left: 0;
    }

    &:not(:last-child) {
      padding-right: 0.25rem;
    }

    &:not(.sidebar):first-of-type {
      padding-left: 0.5rem;
    }

    &.active > a {
      color: #777;
      font-weight: bold;
      border-bottom: 0.2rem solid #d7b6d8;
    }

    &[disabled] {
      cursor: default;
      opacity: 0.75;

      a {
        cursor: default;

        svg {
          opacity: 0.5;
        }
      }
    }

    &.sidebar {
      border: none;
      margin-top: 0;
      position: absolute;
      top: 0;
      right: 0;
      width: 2.5rem;
      height: 100%;
      border-left: 1px solid magenta;
      overflow: visible;

      &:not([hidden]) + * {
        margin-left: 3.75rem;
      }

      &:not([hidden]) + li.tab {
        padding-left: 0;
      }

      > a,
      > button {
        border: none;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        text-indent: -9999px;
        background-repeat: no-repeat;
        background-position: 0.8rem 50%;
        background-size: 1.4rem;

        svg,
        img {
          height: 1.5rem;
          width: 1.5rem;
          top: 0.5rem;
          left: 0.5rem;
          position: absolute;

          &.active {
            display: block;
          }
        }

        .arrow {
          width: 1rem;
          height: 1rem;
          transform: rotate(45deg);
          position: absolute;
          left: -0.5rem;
          top: calc(50% - 0.5rem);
          background: transparent;
          box-sizing: border-box;
          border: 2px solid magenta;
          border-right-color: transparent;
          border-top-color: transparent;

          & + .arrow {
            background: #ba99bb;
            border: none;
            left: calc(-0.5rem + 2px);
            overflow: hidden;

            &::before,
            &::after {
              content: '';
              position: absolute;
              background: blue;
              top: 2px;
              left: calc(-1rem + 2px);
              width: calc(1rem - 2px);
              height: calc(1rem - 2px);
            }

            &::after {
              top: 1rem;
              left: 0;
            }
          }
        }
      }

      &:first-of-type {
        left: 0;
        right: auto;
        border: none;
        border-right: 1px solid magenta;

        a {
          .arrow {
            left: auto;
            right: -0.5rem;
            border: 2px solid magenta;
            border-left-color: transparent;
            border-bottom-color: transparent;

            &::before,
            &::after {
              left: auto;
              top: 0;
              right: calc(-1rem + 2px);
            }

            &::after {
              right: 0;
              top: calc(-1rem - -0.125rem);
            }

            & + .arrow {
              border: none;
              left: auto;
              right: calc(-0.5rem + 2px);
            }
          }
        }
      }

      &.reveal {
        img {
          display: block;

          &.active {
            display: none;
          }
        }
      }
    }

    &.step {
      position: relative;
      counter-increment: stepCounter;

      // Add the counter beforehand
      > a {
        padding-left: 1.5rem;

        &.icon {
          padding-left: 3rem;
        }

        &::before {
          content: counter(stepCounter);
          position: absolute;
          border-radius: 50%;
          text-align: center;
          left: 0;
          width: 1rem;
          height: 1rem;
          line-height: 2rem;
          vertical-align: middle;
          border: 0.0625rem solid #ddd;
          display: inline;
          top: 0.5rem;
          font-size: 0.75rem;
          padding: 0;
          margin: 0;
          line-height: calc(1rem - 0.0625rem);
        }

        svg {
          padding-left: 1.225rem;
        }
      }

      &.active > a::before {
        border: none;
        width: 1.125rem;
        height: 1.125rem;
        line-height: 1.125rem;
        background: #c292c4;
        color: white;
      }

      // Add the 'arrow' divider between tabs
      & + li.tab.step {
        margin-left: 1.5rem;
        padding-left: 1rem;

        &::before {
          content: '';
          width: 1.875rem;
          height: 1.875rem;
          transform: rotate(225deg);
          position: absolute;
          left: -2rem;
          top: calc(50% - 1rem + 0.03125rem);
          background: transparent;
          box-sizing: border-box;
          border: 0.0625rem solid #d4d1d1;
          border-right-color: transparent;
          border-top-color: transparent;
        }
      }
    }
  }

  > button.button.icon[disabled],
  > a.button.icon[disabled] {
    img {
      display: none;
    }

    img.hover {
      display: none;
    }

    img.disabled {
      display: block;
    }
  }

  li.tab.active a.icon img {
    display: none;

    &.active {
      display: block;
    }
  }

  li.tab:hover:not(.active) a.icon img {
    display: none;

    &.active {
      display: none;
    }

    &.hover {
      display: block;
    }
  }

  > button.button.icon:hover:not([disabled]),
  > a.button.icon:hover:not([disabled]) {
    img {
      display: none;
    }

    img.disabled {
      display: none;
    }

    img.hover {
      display: block;
    }
  }
}

// Second tab-bar
.card .card > ul.tabs.tab-bar {
  z-index: 99999;

  &.overlay-open {
      z-index: 99999999;
  }

  + ul.tab-panels {
    // Third tab-bar
    ul.tabs.tab-bar {
      z-index: 9999;

      &.overlay-open {
          z-index: 99999999;
      }
    }
  }
}
