.jobs.sidebar {
  padding-bottom: 0;

  // The output terminal
  ~ .terminal {
    position: absolute;
    top: 0;
    left: 20rem;
    bottom: 0;
    right: 0;
    z-index: 99;

    &.fullscreen {
      left: 0;
    }

    @media #{$media-tablet} {
      min-width: 100%;
    }
  }

  // The job selection graphic
  > .object-selected {
    flex: 0 0 auto;
    padding-bottom: 1.25rem;
    margin-bottom: 1.25rem;
    border-bottom: 1px solid #ddd;
    position: relative;

    ul.connections {
      width: 100%;
    }

    .before,
    .after {
      width: 1.4rem;
      height: 1.4rem;
      display: block;
      position: absolute;
      left: calc(50% - 0.7rem);
      bottom: -0.7rem;
      transform: rotate(45deg);
    }

    .before {
      background-color: #ddd;
      bottom: calc(-0.7rem - 1px);
    }

    .after {
      background-color: white;
    }
  }

  // The job listing
  > nav.jobs {
    flex: 1 1 auto;
    position: relative;
    border-top: 1px solid #ddd;

    > ul.jobs {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      & + .help {
        display: none;
      }

      &:not([data-run-list-index]) + .help {
        display: block;
        width: 20rem;
        max-width: 20rem;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
      }
    }
  }
}
