/**
 * Styling for the object selection sidebar of the workflow editor.
 *
 * Some styling is also within the _sidebars.scss when it pertains to multiple
 * workflow sidebars.
 */

.object-select.sidebar {
  position: relative;
}
