/**
 * Details the edit description modal styling.
 */

// The text area takes the entire card
form > .card.markdown-editor > ul > li > textarea {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
