.task-panel {
  display: flex;
  flex-direction: column;

  > .card.build {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .description {
      width: 100%;
      margin: 0;
      flex: 0 0 auto;
      min-height: auto;
    }

    ul.tabs {
      flex: 0;
    }

    ul.tab-panels {
      flex: 1 1 auto;
      overflow: auto;
      position: relative;

      li.tab-panel {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .terminal {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}

.card.task {
  ol.task-running {
    margin-left: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    text-align: left;

    ol.task-running {
      margin-left: 1.75rem;
      margin-bottom: 0;
      border-left: 0.25rem solid #eaeaea;
      padding-left: 1rem;
    }

    .object-header {
      margin: 0;

      svg.icon,
      img.icon {
        position: relative;
        width: 2rem;
        height: 2rem;
        vertical-align: middle;
        padding-right: 1rem;
        z-index: 9;
      }

      .info {
        text-align: left;
        display: inline-block;
        vertical-align: middle;

        .name {
          display: block;
          font: $header-font;
          font-size: 1rem;
          color: #333;
          position: relative;
          z-index: 9;

          span.version {
            margin-left: 0.5rem;
            font: $header-version-font;
            padding: 0.1em 0.3em;
            border-radius: 0.3em;
            text-align: center;
            border: 1px solid #eaeaea;
          }
        }

        .type {
          display: block;
          font: $object-type-font;
          font-size: 0.7rem;
          // TODO: theme this
          color: #a654a9;
          position: relative;
          z-index: 9;
        }
      }
    }

    li.task-item.object {
      display: block;
      margin: 0;
      padding: 0;
      margin-top: 0.75rem;

      a {
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 0.5rem;
        padding: 0.25rem 1rem;
        text-decoration: none;

        p.summary {
          font: 0.8rem "Open Sans", sans-serif;
          color: #555;
          margin-top: 0.25rem;
        }
      }
    }
  }
}
