/**
 * Styling for the Run tab.
 */

@import "objects/run/form.scss";
@import "objects/run/task.scss";
@import "objects/run/build.scss";
@import "objects/run/widget.scss";
@import "objects/run/terminal.scss";

.run-viewer {
  .run-form {
    display: flex;
    align-items: center;
    text-align: center;
    position: relative;
    overflow: auto;

    > .image {
      display: block;
      filter: grayscale(1) brightness(0.5) contrast(0.4) blur(3px);
      opacity: 0.3;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      img {
        display: block;
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    > .card {
      margin: auto;
      padding: 1rem;
      border: 1px solid white;
      outline: 1px solid red;
      z-index: 9;
      width: 100%;
      max-width: 50rem;
      box-sizing: border-box;

      form.modal {
        > .card {
          min-width: 16rem;
          padding: 0.5rem;
        }

        .card {
          text-align: left;
          background: transparent;

          .card.section {
            border-left: 5px solid #aaa;
            padding: 0.5rem;
          }

          span.expand {
            &::before {
              content: '\25b8';
            }

            &.shown::before {
              content: '\25be';
            }
          }

          table {
            td:not(:first-child) {
              padding-left: 1rem;
            }
          }

          > h2,
          > h3,
          > label,
          *:not(.slider-checkbox) > label {
            cursor: default;
            margin: 0;
            padding: 0;
            padding-bottom: 0.25rem;
            padding-top: 1rem;
            font: $form-label-font;
            background: transparent;
          }

          .slider-checkbox {
            &:not(:first-child) {
              margin-top: 1rem;
            }

            input {
              width: auto;
              max-width: none;
              display: inline-block;
            }

            label {
              display: inline-block;
              margin-left: 0;

              &::before {
                background-color: #333;
              }
            }

            input:checked + label::before {
              background-color: #e987f7;
            }
          }

          > h2:first-child,
          > h3:first-child,
          > label:first-child,
          *:not(.slider-checkbox) > label:first-child {
            padding-top: 0;
          }

          .outputs {
            img {
              height: 2rem;
              max-width: calc(100% - 2rem);
            }
          }

          .selector,
          select,
          input:not(.button) {
            width: 100%;
            max-width: 100%;
            margin: 0;
          }

          .buttons {
            display: inline-block;
            margin: auto;

            .button {
              margin: auto;
              margin-top: 0.5rem;
            }
          }
        }
      }
    }
  }
}
