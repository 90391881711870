/**
 * Styles a notice banner above the header for certain statements.
 *
 * One such statement is the "this is a snapshot" banner.
 */

// General styling
.notice {
  text-align: center;
  padding: 0.25rem;
  background: repeating-linear-gradient(-45deg, #674c79, #734a90 1rem, #513366 1rem, #513366 2rem);
  box-shadow: 0 0 2px 1px black;
  position: relative;
  z-index: 999999999;

  p {
    color: white;
    font: 800 0.9rem Lato, sans-serif;
    text-shadow: 0 0 5px #513366;
    box-shadow: 0 0 2px 2px #4e355f;
    background: #6a4a7f;
    border-radius: 0.9rem;
    padding: 0.1rem 0.9rem;
    margin: 0;
    display: inline-block;

    a {
      color: #e296e4;
      font: 800 0.9rem Lato, sans-serif;
      text-shadow: 0 0 5px #000;
    }
  }
}

// Override some traditional 'card' behavior
h1 + .bar + .card .card.notice {
  text-align: center;
  z-index: 999;
  box-shadow: 0 0 2px 0 black;
  padding: 0.25rem;

  p {
    padding: 0.1rem 0.9rem;
    margin: 0;
  }
}

// Override behavior when inside a flexing modal panel
.modal-window .content > .card > .card > ul.tab-panels > li.tab-panel .card.notice {
  overflow: visible;
}
