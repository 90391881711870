/**
 * This maintains the structure of the site.
 *
 * For the most part, this specifies the flex/grid contraints that direct the
 * flow of the site.
 */

html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  height: 100%;

  @media #{$media-4k} {
    font-size: 150%;
  }

  // Flex-box everything. Sorry, not sorry.
  > body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;

    // Topbar (fixed)
    > .topbar {
      flex: 0 0 auto;
    }

    // Actual rendered content (filled)
    .content.flex {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      // Header (fixed)
      > h1 {
        flex: 0 0 auto;
      }

      // Spacing/progress-bar (fixed)
      > .bar {
        flex: 0 0 auto;
      }

      // Tabs (fixed)
      > ul.tabs.card-tabs {
        flex: 0 0 auto;
        z-index: 9999999;
      }

      // The outer sidebar-allowed container (filled)
      > .container {
        flex: 1 1 auto;
        flex-direction: row;
        overflow: hidden;
        display: flex;

        // Actual contents, whatever they are (filled)
        > * {
          flex: 1 1 auto;
          width: 100%;
          -webkit-transition: width 0.3s;
          transition: width 0.3s;
        }

        // Specifically: tab panels.
        // Each panel will fill the container
        > ul.tab-panels {
          display: flex;
          flex-direction: column;
          overflow: hidden;

          > li.tab-panel {
            flex: 1 1 auto;
            overflow-x: hidden;
            overflow-y: auto;

            &.filled {
              overflow: hidden;
            }

            > .card:last-child {
              flex: 1 1 auto;
            }
          }
        }

        // Left-hand or Right-hand sidebar (dynamic width)
        > .sidebar {
          width: 20rem;
          max-width: 100%;
          flex: 0 0 auto;
          box-shadow: 0 0 2px 1px #696969;
          z-index: 999999;
          position: relative;
          left: 0;

          &.right {
            left: auto;
            right: 0;
          }

          &.wide {
            width: 30rem;
          }

          &.very-wide {
            width: 37rem;
          }

          // When sidebar is closed
          &.reveal {
            width: 0;
            left: -5px;
            margin-right: -1px;

            &.right {
              left: auto;
              right: -5px;
              margin-left: -1px;
            }
          }
        }
      }

      > .card.cards {
        // A simple card stack
        overflow-x: hidden;
        overflow-y: auto;
        flex: 1 1 auto;
      }

      // An inner sidebar-allowed container
      .card.container {
        flex: 1 1 auto;
        position: relative;

        &.flex {
          display: flex;
          flex-direction: row;

          &.horizontal {
            flex-direction: column;
          }

          // Actual contents
          > * {
            flex: 1 1 auto;
            width: 100%;
          }

          // Headers don't flex at all, thanks
          > h2 {
            flex: 0 0 auto;
            overflow: hidden;
          }
        }

        // Left-hand or Right-hand sidebar (dynamic width)
        > .sidebar {
          -webkit-transition: width 0.3s;
          transition: width 0.3s;
          flex: 0 0 auto;
          overflow-x: hidden;
          overflow-y: auto;
          white-space: nowrap;
          box-shadow: 0 0 2px 1px #696969;
          z-index: 99999;
          position: relative;
          width: 20rem;
          left: 0;
          max-width: 100%;

          &.middle {
            z-index: 9999;
          }

          &.filled {
            overflow: hidden;
          }

          &.fixed {
            position: absolute;
            top: 0;
            bottom: 0;
          }

          &.wide {
            width: 30rem;
          }

          &.very-wide {
            width: 37rem;
          }

          &.right {
            left: auto;
            right: 0;
          }

          &.reveal {
            width: 0;
            left: -5px;
            margin-right: -1px;

            &.right {
              left: auto;
              right: -5px;
              margin-left: -1px;
            }
          }

          + * .sidebar {
            z-index: 999;

            &.right {
              z-index: 9999;
            }
          }
        }
      }
    }
  }
}

.lightboxOverlay,
.lightbox {
  z-index: 999999999 !important;
}
