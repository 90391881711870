
.select-object {
  .file-listing-container {
    .file-listing-table {
      table.file-listing {
        tr {
          td.actions {
            display: none;
          }
        }
      }
    }
  }
}
