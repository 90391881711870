.globe {
  position: relative;
  margin: 0 auto;
  display: inline-block;
  border-radius: 50%;
  border: 5px solid white;
  box-shadow: 0 0 10px 1px #b56fb7;
  max-width: 100%;

  @keyframes globe-pulse {
    0% {
      box-shadow: 0 0 10px 1px #b56fb7;
    }

    100% {
      box-shadow: 0 0 10px 4px #b56fb7;
    }
  }

  animation: 2s infinite alternate globe-pulse;

  circle.marker {
    stroke-width: 1px;
    fill: var(--theme-base-general-accent);
  }

  circle.marker-aura {
    @keyframes waypoint-glow {
      0% {
        r: 3;
        opacity: 1;
      }

      100% {
        r: 6;
        opacity: 0;
      }
    }

    fill: white;
    animation: 2s infinite waypoint-glow;
  }

  path:not(.graticule) {
    fill: var(--theme-base-general-accent);
    stroke: var(--theme-base-general-accent-darkened);
  }

  path.graticule {
    stroke: var(--theme-base-general-accent-lightened);
  }
}
