/**
 * Styling for the footer of the site.
 *
 * Typically, this is only seen when you are on the front page or
 * otherwise not logged in.
 */

body footer {
  font: $footer-font;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  margin-top: 2px;
  background: #482e48;
  box-sizing: border-box;

  ul {
    line-height: 1.8em;
    text-align: center;

    li {
      font: $footer-font;
      display: inline-block;
      padding: 0 10px;
      border-right: 1px solid red;
      border-color: #694c69;
      padding-bottom: 2px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
        border: none;
      }
    }
  }

  a {
    text-decoration: none;
    color: #bb96bb;

    &:hover {
      color: #fff;
    }
  }
}
