/**
 * Objects container section.
 */

// Objects Container
//
// This presents a horizontal list of objects. This type of component is useful
// for presenting related objects.
//
// Styleguide Components.objects-container
.objects-container {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  max-width: 100%;
  overflow: auto;

  &:first-child:not(.card) {
    margin-top: 1.5rem;
  }

  &.single {
    height: 10.5rem;
    overflow: auto;
  }

  &.centered {
    max-width: 1200px;

    @media #{$media-tablet} {
      max-width: 100%;
    }
  }

  &.filled {
    width: 100%;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .ps__rail-x,
  .ps__rail-y {
    // Make sure the styled scrollbar appears over the list items
    z-index: 99999;
  }

  ul.objects {
    position: relative;
    overflow: auto;
    padding: 0;
    white-space: nowrap;
    display: flex;
    flex-direction: row;

    li.add,
    li.share,
    li.object {
      flex: 0 0 auto;
      box-sizing: border-box;
      display: inline-block;
      overflow: hidden;
      position: relative;
      grid-row: 1;
      padding: 0;
      margin: 0.5rem;
      margin-top: 0;

      // While a pending delete action happens
      &.removing {
        opacity: 0.5;
      }

      // When a button sticks to the right-hand side.
      &.stuck {
        position: sticky;
        right: 0;
        z-index: 999;
        overflow: visible;
      }

      > .object-header,
      button:not(.dropdown),
      > div > a,
      > a {
        display: inline-block;
        position: relative;
        padding: 1rem 1.5rem;
        margin: 0;
        border: 1px solid #ddd;
        border-radius: 0.4rem;
        min-width: 10rem;
        max-width: 16rem;
        text-align: center;
        text-decoration: none;
        color: black;
        height: 100%;
        box-sizing: border-box;

        &:not([href]) {
          cursor: auto;
        }

        svg,
        img {
          width: 5rem;
          height: 5rem;
          object-fit: contain;

          // Inner icon (for environment/architecture)
          & ~ svg:not(.fingerprint),
          & ~ img:not(.fingerprint) {
            width: 1rem;
            height: 1rem;
            padding: 0.25rem;
            position: absolute;
            top: 4.5rem;
            left: 7rem;
            border: 0.0625rem solid #ddd;
            border-radius: 0.3125rem;

            & ~ p {
              display: none;
            }
          }
        }

        p {
          margin: 0;
          padding: 0;
          font: bold 0.8rem Lato, sans-serif;

          // Truncate the text
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &.filename,
          &.relation {
            margin-bottom: 0.625rem;

            span.size {
              font-weight: normal;
              border: 0.0625rem solid #ddd;
              border-radius: 0.25rem;
              padding: 0.125rem;
              margin-left: 0.5rem;
              font-size: 85%;
              vertical-align: middle;
            }
          }

          &.filename {
            padding: 0.125rem 0;
          }

          &.type {
            font: bold 0.7rem Lato, sans-serif;
          }

          &.name {
            font: 400 0.8rem Lato, sans-serif;
          }

          &.version {
            font: 400 0.7rem Lato, sans-serif;
            padding-top: 0.5em;
          }
        }

        &[href]:hover:not([disabled]):not(.object-header) {
          box-shadow: 0 0 5px 1px #2da0b7 inset;
          border-color: #79a9b3;
        }

        &:first-child:not(:last-child) {
          margin-right: 3.5rem;
          z-index: 99;
        }

        &.icon,
        &.star,
        &.remove,
        &.add,
        &.download,
        &.copy {
          padding: 0;
          cursor: pointer;
          border-color: #c7c7c7;
          text-indent: -9999px;
          opacity: 1;
          min-width: 0;
          width: 4rem;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;

          svg.icon,
          img.icon {
            width: 2rem;
            height: 2rem;
            position: absolute;
            top: calc(50% - 1rem);
            left: calc(50% - 0.75rem);
          }
        }
      }

      .half-button + .half-button,
      .half-button:not(:last-child) {
        a:last-child,
        button:last-child {
          height: 50%;
        }
      }

      .half-button:not(:last-child) {
        a:last-child,
        button:last-child {
          border-bottom: none;
          border-bottom-right-radius: 0;
        }
      }

      .half-button + .half-button:last-child {
        a:last-child,
        button:last-child {
          top: auto;
          bottom: 0;
          border-top-color: white;
          border-top-right-radius: 0;
        }
      }

      &.version {
        p.version {
          padding: 0;
          font-size: 1rem;
        }
      }

      &.empty {
        &:not(:first-of-type) {
          a:first-child {
            padding: 0;
            width: 0;
            max-width: 0;
            min-width: 0;
            overflow: hidden;
            border: none;
            margin-left: 0.5rem;
          }

          a:last-child {
            svg.icon,
            img.icon {
              left: calc(50% - 1rem);
            }
          }
        }

        p.type {
          color: #ddd;
        }

        p.name {
          color: #ddd;
        }
      }
    }

    li.object.person {
      img {
        border-radius: 0.5rem;
        margin-bottom: 0.5rem;
      }

      img.fingerprint {
        position: absolute;
        background: white;
        right: 2rem;
        top: 5.75rem;
        width: 2rem;
        height: 2rem;
        border: 1px solid #b1b1b1;
        box-shadow: 0 0 1px 2px white;
      }
    }

    &.filled {
      li.object {
        margin-bottom: 1rem;
      }
    }

    // A vertical list with extra detail.
    &.detailed.list {
      flex-direction: column;
      padding: 0;

      li.object.detailed {
        > .checkbox {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        > div > a,
        > a {
          padding-left: 4rem;
          padding-right: 0.5rem;
          min-width: 17.2rem;

          svg,
          img {
            width: 2.5rem;
            height: 2.5rem;
            position: absolute;
            left: 0.5rem;
            top: calc(50% - 1.25rem);
            padding-right: 0.5rem;
            border-right: 1px solid #ddd;
          }

          &.vertical {
            min-width: 10rem;
            width: 10rem;
            padding: 0.5rem;

            > svg.icon,
            > img.icon {
              position: static;
              display: block;
              border: none;
              padding: 0.5rem;
              padding-bottom: 1.5rem;
            }

            > p {
              white-space: normal;
            }
          }
        }

        > div {
          > a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }

        .actions {
          margin-left: 0.5rem;

          a {
            min-width: 0;
            padding-left: 0.5rem;

            p {
              font: 400 0.8rem Lato, sans-serif;
            }
          }
        }
      }
    }

    // Creates a vertical listing of objects.
    // Useful for 'collections' and 'contains' views.
    &.list:not(.small) {
      flex-direction: column;
      border-bottom: 1px solid #eee;
      margin-top: 0;

      li.object {
        width: 100%;
        display: flex;
        flex-direction: row;
        overflow: visible;
        grid-row: auto;
        grid-column: 1;
        padding: 10px;
        margin: 0;

        &:not(:first-child) {
          border-top: 1px solid #eee;
        }

        button:not(.dropdown):first-child:not(:last-child),
        > a:first-child:not(:last-child) {
          margin-right: 0;
          flex: 0 0 auto;
        }

        > a:nth-child(2) {
          right: 0.6250rem;
          z-index: 99;
          height: calc(100% - 1.25rem);
          top: 0.625rem;
          width: 4rem;
        }

        .description {
          padding: 0;
          flex: 1 1 auto;
          white-space: normal;
          overflow: visible;
          margin-left: 1rem;
          text-align: justify;
          font: $object-description-font;
          line-height: 1.25rem;
          max-height: 9rem;
          overflow: hidden;
        }

        .statistics {
          flex: 0 0 auto;
          color: #888;

          > .dropdown-menu button {
            width: 1rem;
            height: 1rem;

            svg {
              position: absolute;
              top: 0.125rem;
              left: 0.125rem;
              width: 0.75rem;
              height: 0.75rem;
            }

            ~ .dropdown-menu-options {
              right: -0.4125rem;
              top: 2rem;
            }
          }
        }
      }

      &.filled {
        padding: 0;

        li.object > div:first-child > a,
        li.object > a:first-child {
          width: 100%;

          &:not(:last-child) {
            width: calc(100% - 3.5rem);
            z-index: 999;
          }

          max-width: none;
        }
      }
    }

    // A 'smaller' block for the list.
    &.small:not(.list) {
      li.object.small:not(.detailed) {
        position: relative;

        svg,
        img {
          width: 2.5rem;
          height: 2.5rem;
          position: absolute;
          top: 0.5rem;
          left: 0.5rem;
          display: block;
        }

        .half-button {
          a,
          button {
            width: 2.5rem;

            svg,
            img {
              top: calc(50% - 0.75rem);
              left: 0.625rem;
              width: 1.5rem;
              height: 1.5rem;

              &.smaller {
                width: 1rem;
                height: 1rem;
                top: calc(50% - 0.5rem);
                left: 0.9375rem;
              }
            }
          }
        }

        button:first-child,
        a:first-child {
          padding-left: 3.5rem;
          margin-right: 2rem;
        }

        .half-button + .half-button,
        .half-button:not(:last-child) {
          a:last-child,
          button:last-child {
            height: 50%;
          }
        }

        .half-button:not(:last-child) {
          a:last-child,
          button:last-child {
            border-bottom: none;
            border-bottom-right-radius: 0;
          }
        }

        .half-button + .half-button:last-child {
          a:last-child,
          button:last-child {
            top: auto;
            bottom: 0;
            border-top-color: white;
            border-top-right-radius: 0;
          }
        }

        &:not(:first-of-type) {
          margin-left: 1rem;
        }

        &:last-of-type {
          .half-button button:last-child:not(:first-child) {
            right: 0;
          }
        }
      }
    }
  }

  &.grid-view {
    ul.objects {
      // For a scrolling view
      white-space: normal;
      display: block;

      li.object {
        // For the grid view
        width: 24%;
        text-align: center;
        margin: 0;

        > a {
          max-width: 10rem;
        }
      }
    }
  }
}

table.objects {
  width: 100%;

  thead {
    tr {
      th {
        padding: 0.5rem 1rem;
        border-bottom: 1px solid #ddd;
        font: bold 0.9rem 'Lato', sans-serif;
      }
    }
  }

  tbody {
    tr {
      td {
        &.objects {
          text-align: left;
          width: 100%;
        }

        > * {
          vertical-align: middle;
        }
        
        .objects-container.table-row {
          margin-top: 0;

          ul.objects.table-cell {
            padding: 0;
            margin: 0;

            li.object.table-cell-item {
              padding: 0;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
