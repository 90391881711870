/**
 * Loading graphic styling.
 */

.loading:not(.loading-content) {
  background-color: rgba(255, 255, 255, 0.7);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 10%;

  &.error-500,
  &.error-400 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
  }

  &.transparent {
    background-color: transparent;
  }

  > .card.loading.loading-content {
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50% 0.25rem;
    background-size: 1.5rem;
    flex: 0 0 auto;
    padding-top: 2rem;
  }

  &.failed {
    background-image: url ('/images/indicators/object-failed.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 10%;

    > .card.loading.loading-content {
      background-image: url ('/images/indicators/object-failed.svg');
    }
  }

  &.white {
    background-image: url ('/images/dynamic/hex/ffffff/indicators/object.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 10%;

    > .card.loading.loading-content {
      background-image: url ('/images/dynamic/hex/ffffff/indicators/object.svg');
    }
  }

  &:not(.error-500):not(.error-400) {
    > .card.loading.loading-content {
      background-image: url ('/images/indicators/object.svg');
    }
  }

  &:not(.error-500):not(.error-400):not([data-block-given]) {
    background-image: url ('/images/indicators/object.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 10%;
  }
}

input.loading {
  &:not(.error-500):not(.error-400) {
    background-size: 6%;
  }
}
